import { type EventLoggerRequest } from "@jfrog-ba/myjfrog-common";
import { eventLogger } from "@shared/services/eventLogger.ts";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { useAuthStore } from "@shared/stores/auth.ts";

export const useEventLogger = () => {
  const logEvent = ({
    eventType,
    eventData,
    eventEntity,
  }: Pick<EventLoggerRequest, "eventType" | "eventData" | "eventEntity">) => {
    eventLogger.createNewEvent({
      eventType,
      eventData,
      eventEntity,
      subscriptionNumber: useSubscriptionsStore().getCurrentSubscriptionNumber,
      userEmail: useAuthStore().getUser?.email || "",
    });
  };

  return {
    logEvent,
  };
};
