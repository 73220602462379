import { messagesService } from "@shared/services/messages.ts";
import { permissions } from "@shared/services/permissions.ts";
import { usersService } from "@shared/services/user.ts";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { useNotifications } from "@shared/module-transformers/useNotifications.ts";
import dateUtils from "@shared/utils/date-utils.ts";
import logger from "@shared/utils/logger.ts";
import { DateTime } from "luxon";
import { computed, ref } from "vue";
import { useEventLogger } from "@shared/use/useEventLogger.ts";
import { EventEntity, EventType } from "@shared/types/localTypes/events.ts";
import { useTokenGenerationStore } from "@shared/stores/tokenGeneration.ts";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";

export function useTokenGeneration() {
  const subscriptionsStore = useSubscriptionsStore();
  const confirmRegenerateVisible = ref(false);
  const loading = ref(false);
  const tokenGenerationStore = useTokenGenerationStore();

  const currentSubscription = computed(() => {
    return subscriptionsStore.getCurrentSubscription;
  });

  const onClickGenerateToken = () => {
    if (tokenGenerationStore.getApiKey.length && !isTokenExpired.value) {
      confirmRegenerateVisible.value = true;
      return;
    }

    generateToken();
  };

  const generateToken = async () => {
    if (!currentSubscription.value) {
      return;
    }

    confirmRegenerateVisible.value = false;
    loading.value = true;
    try {
      const response = await usersService.generateAdminApiToken({
        accountNumber: currentSubscription.value.accountNumber,
      });

      if (!response) {
        throw new Error("Invalid token");
      }

      tokenGenerationStore.setApiKey(response.token);
      tokenGenerationStore.setApiKeyExpiry(response.tokenExpiry);

      useEventLogger().logEvent({
        eventType: EventType.CREATE,
        eventEntity: EventEntity.SAAS_TOKEN,
        eventData: null,
      });

      useNotifications().success("API Token generated successfully.");
    } catch (e) {
      useNotifications().error(messagesService.token_generation_error, {
        duration: 4500,
      });
      logger.error(e);
    }
    loading.value = false;
  };

  const getApiToken = async (currentSubscription: SubscriptionDTO) => {
    tokenGenerationStore.setSsTokenLoading(true);
    const hasGetApiTokenPermissions = permissions.has(
      "Profile.ApiTokenVisualize",
    );
    if (!hasGetApiTokenPermissions) {
      return;
    }
    try {
      const response = await usersService.getAdminApiToken({
        accountNumber: currentSubscription.accountNumber,
      });

      if (!response) {
        throw new Error("Invalid token");
      }

      tokenGenerationStore.setApiKey(response.token);
      tokenGenerationStore.setApiKeyExpiry(response.tokenExpiry);
    } catch (e) {
      useNotifications().error(messagesService.token_generation_error, {
        duration: 4500,
      });
      logger.error(e);
    }
    tokenGenerationStore.setSsTokenLoading(false);
  };

  const generateTokenBtnText = computed(() => {
    return !tokenGenerationStore.getApiKey.length
      ? "Generate Token"
      : "Regenerate Token";
  });
  const isGenerateTokenDisabled = computed(() => {
    return !permissions.has("Profile.GenerateApiToken");
  });

  const canCopyToken = computed(() => {
    return permissions.has("Profile.CopyTokenEnabled");
  });

  const isTokenExpired = computed<boolean>(() => {
    if (!tokenGenerationStore.getApiKeyExpiry) {
      return true;
    }

    const expiryDate = DateTime.fromMillis(
      tokenGenerationStore.getApiKeyExpiry,
    ).toUTC();

    const currentDate = DateTime.now().toUTC();

    return currentDate > expiryDate;
  });

  const tokenExpiryFormatted = computed<string[]>(() => {
    if (!tokenGenerationStore.getApiKeyExpiry) {
      return [];
    }

    return [
      dateUtils.formatUTC(
        new Date(tokenGenerationStore.getApiKeyExpiry),
        "MM/dd/yyyy",
      ),
      dateUtils.formatUTC(
        new Date(tokenGenerationStore.getApiKeyExpiry),
        "HH:mm a",
      ),
    ];
  });

  const tokenAlertText = computed(() => {
    const text =
      "This API token gives you powerful capabilities to enhance your JFrog experience.<br/>It is crucial that you keep your API token secure.";
    const [dateFormatted, hourFormatted] = tokenExpiryFormatted.value;
    const expiredText = !isTokenExpired.value
      ? `The token will expire on: ${dateFormatted} at ${hourFormatted} (UTC).`
      : `The token has expired at: ${dateFormatted} at ${hourFormatted} (UTC).`;

    return `${text}<br/><strong>${expiredText}</strong>`;
  });

  return {
    getApiToken,
    onClickGenerateToken,
    generateToken,
    generateTokenBtnText,
    isTokenExpired,
    tokenAlertText,
    confirmRegenerateVisible,
    loading,
    isGenerateTokenDisabled,
    canCopyToken,
  };
}
