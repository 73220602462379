import { appService } from "@shared/services/app";
import apiProxy from "@shared/utils/api-proxy";
import { type UsersService } from "@shared/types/services/users";
import {
  type AdminApiTokenResponse,
  type GenerateTokenAdminApiProxyRequest,
  type UserDTO,
} from "@jfrog-ba/myjfrog-common";
import { useAuthStore } from "@shared/stores/auth";
import { useAlertMessagesStore } from "@shared/stores/alertMessages";
import { messagesService } from "@shared/services/messages";
import { useEngine } from "@shared/use/useEngine.ts";
import window from "@shared/utils/app-window";

export const usersService: UsersService = {
  login: async ({ userIdentifier, password }) => {
    return await apiProxy.post(
      `user/login?engine=${useEngine().engine}&mode=${window.OPERATION_MODE}`,
      {
        userIdentifier,
        password,
      },
    );
  },

  loginOtp: async (otpLoginRequest) =>
    await apiProxy.post(
      `user/loginOtp?engine=${useEngine().engine}`,
      otpLoginRequest,
    ),

  loginSso: async (ssoLoginRequest) =>
    await apiProxy.post(
      `user/loginSso?engine=${useEngine().engine}`,
      ssoLoginRequest,
    ),

  proxyLogout: async () => {
    await apiProxy.post("user/logout", {});
  },

  generateAdminApiToken: async (request: GenerateTokenAdminApiProxyRequest) => {
    const response: AdminApiTokenResponse | null = await apiProxy.post(
      "user/generateAdminApiToken",
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  getAdminApiToken: async (request: GenerateTokenAdminApiProxyRequest) => {
    const response: AdminApiTokenResponse | null = await apiProxy.post(
      "user/getAdminApiToken",
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  handleUnauthorized: async () => {
    const shouldNotifyExpiredSession = !appService.isFreshInstance();
    if (shouldNotifyExpiredSession) {
      await useAlertMessagesStore().setMessages({
        title: "",
        description: messagesService.app_session_expired,
      });
    }
    await appService.logOut();
  },
  forgotPassword: async (forgotPasswordRequest) =>
    await apiProxy.post(`user/forgotPassword`, forgotPasswordRequest),

  registerNow: async (email) =>
    await apiProxy.post(`user/registerNow/${email}`, {}),

  resetPassword: async (passwordRequest) =>
    await apiProxy.post(`user/resetPassword`, passwordRequest),
  validateEmail: async (email) =>
    await apiProxy.post(`user/validateEmail`, { email }),

  changePassword: async (changePassword) =>
    await apiProxy.post(`user/changePassword`, changePassword),

  hasUser() {
    return !!useAuthStore().getUser;
  },

  fetchUser: async () => await apiProxy.get("user"),

  updatePersonalInfo: async (userDetails) =>
    await apiProxy.put(`user`, userDetails),

  reloadUser: async () => {
    return useAuthStore().setUser(await usersService.fetchUser());
  },

  refreshVueXUser: async (user) => useAuthStore().setUser(user),

  checkRegistrationToken: async (token) =>
    await apiProxy.get(`user/checkRegistrationToken/${token}`),

  register: async (registerRequest) =>
    await apiProxy.post(`user/register`, registerRequest),

  registerAndLoginSsoUser: async (ssoRegistrationRequest) =>
    await apiProxy.post(
      `user/registerAndLoginSsoUser?engine=${useEngine().engine}`,
      ssoRegistrationRequest,
    ),

  logRequestHeaders: async (logPrefix) =>
    await apiProxy.post(`user/logRequestHeaders`, { logPrefix }),

  setUser: async (user: UserDTO) => {
    const authStore = useAuthStore();
    authStore.setUser(user);
  },
  getOrFetchUser: async () => {
    let user = useAuthStore().getUser;
    if (user) {
      return user;
    }
    try {
      user = await usersService.fetchUser();
    } catch (e) {
      return null;
    }

    // set user
    await useAuthStore().setUser(user);
    return user;
  },
};
