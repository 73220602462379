import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import login from "@/router/route/login";
import dashboard from "@/router/route/dashboard";
import errors from "@/router/route/errors";
import redirect from "@/router/route/redirect";
import type { MjfRouteMeta } from "@shared/types/localTypes/router";
import {
  AllSubscriptionsSelectLabel,
  useSubscriptionsStore,
} from "@shared/stores/subscriptions";
import { navigationService } from "@shared/services/navigation";
import { useResponsiveStore } from "@shared/stores/responsive";
import landing from "./route/landing";
import { useUpgradeStore } from "@shared/stores/upgrade";
import { useRoutingStore } from "@shared/stores/routing";
import { useBetaChecker } from "@/use/useBetaChecker";
import logger from "@shared/utils/logger.ts";
import { maintenanceStatus } from "@shared/services/maintenanceStatus.ts";
import { useSessionLogic } from "@shared/use/useSessionLogic.ts";
import { RouteNames } from "@shared/types/localTypes/router";
import { appService } from "@shared/services/app.ts";
import { useActionPagesStore } from "@shared/stores/actionPages.ts";
import { heapService } from "@shared/services/heap.ts";

const routes: Array<RouteRecordRaw> = [
  login,
  dashboard,
  errors,
  landing,
  ...redirect,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  linkActiveClass: "my-jfrog-active-link",
});

router.onError((error) => {
  if (
    error &&
    error.message &&
    (error.message.includes("Failed to fetch dynamically imported module") ||
      error.message.includes("Importing a module script failed"))
  ) {
    logger.error(`Force Browser Reload on: ${error.message}`);
    window.location.reload();
  }
});

let lastRouteName: string = "undefined";

router.beforeEach(async (to) => {
  const upgradeStore = useUpgradeStore();
  const routingStore = useRoutingStore();
  const actionPagesStore = useActionPagesStore();
  const targetRouteMetas: MjfRouteMeta = to.meta;

  maintenanceStatus.checkIsMyJfUnderMaintenance();

  lastRouteName = router.currentRoute.value.name?.toString() || "unknown";

  if (upgradeStore.upgradeViewIsVisible) {
    upgradeStore.dismissUpgradeView();
    routingStore.setCurrentRouteAsCancelled();
    return false;
  }

  if (
    actionPagesStore.currentActionPage &&
    !actionPagesStore.changingRouteIsAllowed
  ) {
    actionPagesStore.clearCurrentActionPage();
    routingStore.setCurrentRouteAsCancelled();
    return false;
  }

  const isCurrentSessionActive = useSessionLogic().isSessionActive();

  if (isCurrentSessionActive && targetRouteMetas.autoLogout) {
    await appService.logOut();
    return;
  }

  if (isCurrentSessionActive && targetRouteMetas.isGuest) {
    return { name: RouteNames.AllSubscriptions };
  }

  const allowDisplayInMobile = (to.meta as MjfRouteMeta).allowDisplayInMobile;
  useResponsiveStore().setSkipMobileMaskDisclaimer(
    allowDisplayInMobile || false,
  );
});

router.afterEach(async (to) => {
  const routingStore = useRoutingStore();

  if (routingStore.currentRouteIsCancelled) {
    routingStore.clearCurrentRouteCancelled();
    return false;
  }

  document.title = "MyJFrog - " + (to.name as string);

  const subscriptionsStore = useSubscriptionsStore();
  const currentSubscriptionNumber =
    subscriptionsStore.getCurrentSubscriptionNumber;
  const targetSubscriptionNumber = to.params.subscriptionId;

  navigationService.manageMenuItemsOnSubscriptionChanged(
    targetSubscriptionNumber as string,
  );

  if (currentSubscriptionNumber !== targetSubscriptionNumber) {
    const computedSubscriptionNumber =
      targetSubscriptionNumber || AllSubscriptionsSelectLabel;
    await subscriptionsStore.setCurrentSubscription(
      computedSubscriptionNumber as string,
    );

    await useBetaChecker().disableBetaIfNeeded();
  }

  heapService.track("PAGE_VIEW", {
    page_name: router.currentRoute.value.name?.toString() || "unknown",
    prev_page: lastRouteName,
  });
});

export default router;
