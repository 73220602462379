import { type SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { subscriptionsService } from "@shared/services/subscriptions";
import { buildSaasMetas } from "@shared/services/metas/saasMeta";
import { buildSelfhostedMetas } from "@shared/services/metas/selfhostedMeta";
import { entitlementsService } from "@shared/services/entitlements";
import { EntitlementName } from "@shared/types/localTypes/entitlements.ts";

export const addSubscriptionMeta = (subscriptions: SubscriptionDTO[]) => {
  return subscriptions.map((subscription) => ({
    ...subscription,
    meta: buildSubscriptionMetas(subscription),
  }));
};

export const buildSubscriptionMetas = (
  subscription: SubscriptionDTO,
): SubscriptionDTO["meta"] => {
  const metas = subscriptionsService.isSelfHosted(subscription)
    ? buildSelfhostedMetas(subscription)
    : buildSaasMetas(subscription);

  if (!metas) {
    throw new Error(
      `Unable to compute subscription metadata for PaymentType '${subscription.paymentType}' 
      and bom '${subscription.bom.type} - trial:${subscription.bom.trial}'`,
    );
  }

  const jasEntitlement = entitlementsService.getEntitlement(
    subscription,
    EntitlementName.BUSINESS_SECURITY_ADVANCED,
  );
  const curationEntitlement = entitlementsService.getEntitlement(
    subscription,
    EntitlementName.BUSINESS_SECURITY_CURATION,
  );

  return {
    ...metas,
    isActive: subscription.state === "ACTIVE",
    hasJas: !!jasEntitlement,
    hasJasTrial: jasEntitlement && jasEntitlement.trial,
    isJasExpired: entitlementsService.isExpired(jasEntitlement),
    hasCuration: !!curationEntitlement,
    hasCurationTrial: curationEntitlement && curationEntitlement.trial,
    isCurationExpired: entitlementsService.isExpired(curationEntitlement),
  };
};

export const renderDisplayName = (
  paymentTypeName: string,
  displayName: string,
): string => {
  if (
    import.meta.env.VUE_APP_DISPLAY_PAYMENT_TYPE_NAME &&
    import.meta.env.VUE_APP_DISPLAY_PAYMENT_TYPE_NAME === true
  ) {
    return `${paymentTypeName} - ${displayName}`;
  }
  return displayName;
};

export const trialSuffixStr = "Trial";
