import { defineStore } from "pinia";

interface MaintenanceStoreState {
  isMaintenanceStatusLoadedState: boolean;
  isMyJFUnderMaintenanceState: boolean;
}
export const useMaintenanceStore = defineStore({
  id: "maintenance",
  state: (): MaintenanceStoreState => ({
    isMaintenanceStatusLoadedState: false,
    isMyJFUnderMaintenanceState: false,
  }),
  getters: {
    isMyJFUnderMaintenance: (state) => state.isMyJFUnderMaintenanceState,
    isMaintenanceStatusLoaded: (state) => state.isMaintenanceStatusLoadedState,
  },
  actions: {
    setIsMyJFUnderMaintenance(
      isMyJFUnderMaintenance: MaintenanceStoreState["isMyJFUnderMaintenanceState"],
    ) {
      this.isMyJFUnderMaintenanceState = isMyJFUnderMaintenance;
    },

    setIsMaintenanceStatusLoaded(
      isMaintenanceStatusLoaded: MaintenanceStoreState["isMaintenanceStatusLoadedState"],
    ) {
      this.isMaintenanceStatusLoadedState = isMaintenanceStatusLoaded;
    },
  },
});
