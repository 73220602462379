import {
  type OfferingsResponse,
  type RatePlanSubscription,
  type SubscriptionDTO,
  type Tier,
} from "@jfrog-ba/myjfrog-common";
import apiProxy from "@shared/utils/api-proxy";
import { useOfferingsStore } from "@shared/stores/offerings";
import { type Offering } from "@shared/types/localTypes/offerings";

export const offeringsService = {
  fetchOfferingsData: async (
    accountNumber: SubscriptionDTO["accountNumber"],
  ): Promise<OfferingsResponse> => {
    const response: OfferingsResponse | null = await apiProxy.get(
      `subscriptions/${accountNumber}/types`,
    );
    if (!response) {
      throw new Error("Could not fetch offerings data");
    }
    return response;
  },
  fetchRatePlansTiersData: async (
    ratePlanIds: string[],
  ): Promise<RatePlanSubscription[]> => {
    const response: RatePlanSubscription[] | null = await apiProxy.post(
      `registrations/getRatePlanTiers`,
      { ratePlanIds },
    );
    if (!response) {
      throw new Error("Could not fetch ratePlan tiers data");
    }

    return response;
  },
  loadOfferings: async (
    accountNumber: SubscriptionDTO["accountNumber"],
  ): Promise<Offering[]> => {
    const offeringsStore = useOfferingsStore();
    const cachedOfferings = offeringsStore.offerings;

    // offerings already loaded in Pinia
    if (cachedOfferings.length) {
      return cachedOfferings;
    }
    const fetchedOfferingsData =
      await offeringsService.fetchOfferingsData(accountNumber);
    const fetchedTiersData = await offeringsService.fetchRatePlansTiersData(
      fetchedOfferingsData.subscriptionTypes.map(
        (ratePlanData) => ratePlanData.ratePlanId,
      ),
    );

    const fetchedOfferings: Offering[] =
      fetchedOfferingsData.subscriptionTypes.map((ratePlanData) => {
        const relevantRatePlanTiersData = fetchedTiersData.find(
          (tierData) => tierData.ratePlanId === ratePlanData.ratePlanId,
        );
        const tiers: Tier[] = relevantRatePlanTiersData
          ? relevantRatePlanTiersData.tiers["Consumption"]
          : [];
        return {
          ...ratePlanData,
          tiers,
        };
      });

    await offeringsStore.setOfferings(fetchedOfferings);
    await offeringsStore.setPromotions(fetchedOfferingsData.promotions);
    return fetchedOfferings;
  },
};
