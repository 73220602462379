import { defineStore } from "pinia";
import { type Offering } from "@shared/types/localTypes/offerings";
import { PromotionItem } from "@jfrog-ba/myjfrog-common";

export interface OfferingsStoreStates {
  _offerings: Offering[];
  _promotions: PromotionItem[];
}

export const useOfferingsStore = defineStore({
  id: "offerings",
  state: () => ({
    _offerings: [] as OfferingsStoreStates["_offerings"],
    _promotions: [] as OfferingsStoreStates["_promotions"],
  }),
  getters: {
    offerings: (state) => state._offerings,
    promotions: (state) => state._promotions,
  },
  actions: {
    async setOfferings(offerings: OfferingsStoreStates["_offerings"]) {
      this._offerings = offerings;
    },
    async setPromotions(promotions: OfferingsStoreStates["_promotions"]) {
      this._promotions = promotions;
    },
  },
});
