import { onMounted } from "vue";
import { LocalStorageKeys } from "@shared/types/localTypes/storage";
import useThemeSwitcher from "@shared/use/useThemeSwitcher";
import { type Theme } from "@shared/stores/theme";

export default () => {
  const themeSwitcher = useThemeSwitcher();

  onMounted(() => {
    const defaultTheme: Theme = "dark";

    const themeStored = window.localStorage.getItem(
      LocalStorageKeys.THEME,
    ) as Theme | null;

    let forcedTheme: Theme | null = null;
    const url = window.location.href;
    const hasQueryParameters = url.includes("?");

    if (hasQueryParameters) {
      const urlSearchParams = new URLSearchParams(url.split("?")[1]);
      if (urlSearchParams.has("beta")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        forcedTheme = urlSearchParams.get("beta") === "1" ? "dark" : "light";
      }
    }

    // NOTE: [28/11/2023] [kevinz] -> We want to keep the logic for next beta if there is, so we simply comment
    //  the forced theme for now and use the one stored by customer or the default one
    // themeSwitcher.switchTheme(forcedTheme || themeStored || defaultTheme);
    themeSwitcher.switchTheme(themeStored || defaultTheme);
  });
};
