import { defineStore } from "pinia";

interface DefaultMessages {
  title: string;
  description: string;
}

const defaultMessages: DefaultMessages = {
  title: "Log in to MyJFrog",
  description: "Your Customer Portal",
};

export const useLoginMessagesStore = defineStore({
  id: "loginMessages",
  state: () => ({
    title: defaultMessages.title,
    description: defaultMessages.description,
  }),
  getters: {
    getTitle: (state) => state.title,
    getDescription: (state) => state.description,
  },
  actions: {
    async setMessages({ title, description }: DefaultMessages) {
      this.title = title;
      this.description = description;
    },
    clearMessages() {
      this.title = defaultMessages.title;
      this.description = defaultMessages.description;
    },
  },
});
