import { defineStore } from "pinia";

interface NetworkStoreState {
  _connected: boolean;
}
export const useNetworkStore = defineStore({
  id: "network",
  state: (): NetworkStoreState => ({
    _connected: true,
  }),
  getters: {
    connected: (state) => state._connected,
  },
  actions: {
    setConnected(connected: NetworkStoreState["_connected"]) {
      this._connected = connected;
    },
  },
});
