import { defineStore } from "pinia";

export interface RoutingStoreStates {
  _currentRouteIsCancelled: boolean;
}

export const useRoutingStore = defineStore({
  id: "routing",
  state: () =>
    ({
      _currentRouteIsCancelled: false,
    }) as RoutingStoreStates,
  getters: {
    currentRouteIsCancelled: (state) => state._currentRouteIsCancelled,
  },
  actions: {
    setCurrentRouteAsCancelled() {
      this._currentRouteIsCancelled = true;
    },
    clearCurrentRouteCancelled() {
      this._currentRouteIsCancelled = false;
    },
  },
});
