export enum AddonEntitlementUsageType {
  NON_PAYING_ONLY = "non_paying_only",
  PAYING_ONLY = "paying_only",
  ALL = "all",
}

export type ServiceAggregatedTrafficListType = {
  serviceType: string;
  totalDataTransferBytes: number;
  numOfDownloads: number;
  totalDownloadBytes: number;
  numOfUploads: number;
  totalUploadBytes: number;
};
export enum Period {
  DAILY = "daily",
  MONTHLY = "monthly",
}
