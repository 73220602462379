<template>
  <div
    class="mobile-view-not-supported--wrapper w-full h-full flex flex-col items-center justify-center text-center py-5"
    :class="screenSize"
  >
    <img class="mt-16" :src="imgSrc" />
    <h3 class="font-light">We're sorry but your device isn't supported</h3>
    <h5 class="mt-8">
      You’ll need to access MyJFrog Cloud Portal on your computer
    </h5>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";

import mobileViewDisabledLightSrc from "@shared/assets/images/mobile-view-disabled-light.svg";
import mobileViewDisabledDarkSrc from "@shared/assets/images/mobile-view-disabled-dark.svg";
import { useGlobalLoaderStore } from "@shared/stores/globalLoader";
import { useScreenSize } from "@shared/use/useScreenSize";
import { useThemeStore } from "@shared/stores/theme";
const { screenSize } = useScreenSize();

const globalLoaderStore = useGlobalLoaderStore();
const themeStore = useThemeStore();

const imgSrc = computed(
  () =>
    (themeStore.isDark && mobileViewDisabledDarkSrc) ||
    mobileViewDisabledLightSrc,
);
onMounted(() => {
  globalLoaderStore.hide();
});
</script>

<style lang="scss" scoped>
@use "@shared/assets/styles/abstracts/functions" as f;

.mobile-view-not-supported--wrapper {
  background-color: f.color("layout-bg-color");

  &.smartphone {
    h3 {
      font-size: 26px;
      width: 80%;
    }

    h5 {
      font-size: 18px;
      width: 70%;
    }
  }

  img {
    order: 2;
    max-height: 40vh;
  }

  h3 {
    font-size: 40px;
    width: 60%;
    padding: 0 20px;
    color: f.color("text-color-light");
  }

  h5 {
    font-weight: 600;
    font-size: 24px;
    width: 60%;
    color: f.color("text-color-light");
  }

  @media (orientation: landscape) {
    @media screen and (max-height: 500px) {
      h3 {
        font-size: 28px;
      }

      h5 {
        font-size: 20px;
      }

      img {
        max-height: 30vh;
      }
    }
  }
}
</style>
