import { UsageExposerSelfHostedStatisticsV2Response } from "@shared/services/usageExposer/usageExposerSelfHostedStatistics.ts";
import DateUtils from "@shared/utils/date-utils.ts";

const json = import(
  "../../json/usageExposer/dashboard/usageExposerShStatistics.json"
);

const updateDate = (
  usageExposerStatisticsV2Response: UsageExposerSelfHostedStatisticsV2Response,
  callback: (index: number) => string,
) => {
  usageExposerStatisticsV2Response.jpdStatistics.map((server) => {
    server.statistics.map((statistic, index) => {
      statistic.date = callback(index + 1);
    });
  });
  return usageExposerStatisticsV2Response;
};

export default async () => {
  const parse = JSON.parse(JSON.stringify(await json));

  return updateDate(parse, DateUtils.returnDateFromToday);
};
