import { defineStore } from "pinia";
import {
  GetGeoLocationsResponse,
  GetRoutingPolicyBySubscriptionIdResponse,
  JpdsReadinessResponse,
  PolicyDetailsGeoModel,
  type RoutingPolicyModel,
} from "@jfrog-ba/myjfrog-common";
import {
  DnsRoutingGeoLocationSetupState,
  DnsRoutingJpdsTopologySetupState,
  DnsRoutingPolicy,
  DnsRoutingSingleRoutingUrlState,
  GeoLocationSetupItem,
} from "@shared/types/localtypes.ts";

interface DnsRoutingState {
  policiesResponse: GetRoutingPolicyBySubscriptionIdResponse;
  geoLocationListResponse: GetGeoLocationsResponse;
  jpdsReadinessResponse: JpdsReadinessResponse;
  isLoadingPolicies: boolean;
  isLoadingGeoLocations: boolean;
  isLoadingJpdsHealthStatus: boolean;
  singleRoutingUrlState: DnsRoutingSingleRoutingUrlState;
  jpdsTopologySetupState: DnsRoutingJpdsTopologySetupState;
  geoLocationSetup: DnsRoutingGeoLocationSetupState;
  activePolicyTab: DnsRoutingPolicy;
}

export const useDnsRoutingStore = defineStore("dnsRouting", {
  state: (): DnsRoutingState => ({
    policiesResponse: { policies: [] },
    geoLocationListResponse: { continents: [] },
    jpdsReadinessResponse: { status: [] },
    isLoadingPolicies: false,
    isLoadingGeoLocations: false,
    isLoadingJpdsHealthStatus: false,
    singleRoutingUrlState: {
      singleUrlValue: "",
      isReadyForSending: false,
    },
    jpdsTopologySetupState: {
      primaryJpdTechServerName: "",
      secondaryJpdTechServerName: "",
      isReadyForSending: false,
    },
    geoLocationSetup: {
      isReadyForSending: false,
      geoLocationSetup: [],
    },
    activePolicyTab: "manual-failover",
  }),
  getters: {
    getPoliciesResponse: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.policiesResponse;
    },
    getSingleRoutingUrlState: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.singleRoutingUrlState;
    },
    getJpdsTopologySetupState: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.jpdsTopologySetupState;
    },
    getGeoLocationSetup: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.geoLocationSetup;
    },
    getGeoLocationListResponse: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.geoLocationListResponse;
    },
    getJpdsReadinessResponse: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.jpdsReadinessResponse;
    },
    getIsLoadingPolicies: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.isLoadingPolicies;
    },
    getIsLoadingJpdsHealthStatus: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.isLoadingJpdsHealthStatus;
    },
    getIsLoadingGeoLocations: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.isLoadingGeoLocations;
    },
    getActivePolicyTab: (dnsRoutingState: DnsRoutingState) => {
      return dnsRoutingState.activePolicyTab;
    },
  },
  actions: {
    setRoutingPoliciesResponse(
      policiesResponse: DnsRoutingState["policiesResponse"],
    ) {
      this.policiesResponse = policiesResponse;
    },
    setSingleRoutingUrlState(
      singleRoutingUrlState: DnsRoutingState["singleRoutingUrlState"],
    ) {
      this.singleRoutingUrlState = singleRoutingUrlState;
    },
    setJpdsTopologySetupState(
      jpdsTopologySetupState: DnsRoutingState["jpdsTopologySetupState"],
    ) {
      this.jpdsTopologySetupState = jpdsTopologySetupState;
    },
    setGeoLocationSetup(geoLocationSetup: DnsRoutingState["geoLocationSetup"]) {
      this.geoLocationSetup = geoLocationSetup;
    },
    setGeoLocationSetupIsReady(isSetupReady: boolean) {
      this.geoLocationSetup.isReadyForSending = isSetupReady;
    },
    setGeoLocationSetupForSpecificJpd(
      policyDetailsGeoModel: PolicyDetailsGeoModel,
      index: number,
    ) {
      this.geoLocationSetup.geoLocationSetup[index] = {
        ...this.geoLocationSetup.geoLocationSetup[index],
        ...policyDetailsGeoModel,
      };
    },
    addJpdConfigToGeoLocationSetup(newGeoLocationConfig: GeoLocationSetupItem) {
      this.geoLocationSetup.geoLocationSetup.push(newGeoLocationConfig);
    },
    removeJpdGeoLocationSetup(indexOfSetupToRemove: number) {
      this.geoLocationSetup.geoLocationSetup.splice(indexOfSetupToRemove, 1);
    },
    setGeoLocationListResponse(
      geoLocationListResponse: DnsRoutingState["geoLocationListResponse"],
    ) {
      this.geoLocationListResponse = geoLocationListResponse;
    },
    setJpdsReadinessResponse(
      jpdsReadinessResponse: DnsRoutingState["jpdsReadinessResponse"],
    ) {
      this.jpdsReadinessResponse = jpdsReadinessResponse;
    },
    updateOrCreatePolicyInStore(newPolicy: RoutingPolicyModel) {
      const policyModelIndex = this.policiesResponse.policies.findIndex(
        (policy) => policy.routing_policy_id === newPolicy.routing_policy_id,
      );
      if (policyModelIndex === -1) {
        this.policiesResponse.policies.push(newPolicy);
        return;
      }
      this.policiesResponse.policies[policyModelIndex] = newPolicy;
    },
    makeExistingPolicyInProgress(routingPolicyId: string) {
      const policyIndex = this.policiesResponse.policies.findIndex(
        (policy) => policy.routing_policy_id === routingPolicyId,
      );
      if (policyIndex === -1) {
        return;
      }
      this.policiesResponse.policies[policyIndex].policy_status = "in_progress";
    },
    setIsLoadingPolicies(
      isLoadingPolicies: DnsRoutingState["isLoadingPolicies"],
    ) {
      this.isLoadingPolicies = isLoadingPolicies;
    },
    setIsLoadingJpdsHealthStatus(
      isLoadingJpdsHealthStatus: DnsRoutingState["isLoadingJpdsHealthStatus"],
    ) {
      this.isLoadingJpdsHealthStatus = isLoadingJpdsHealthStatus;
    },
    setIsLoadingGeoLocations(
      isLoadingGeoLocations: DnsRoutingState["isLoadingGeoLocations"],
    ) {
      this.isLoadingGeoLocations = isLoadingGeoLocations;
    },
    setActivePolicyTab(activePolicyTab: DnsRoutingState["activePolicyTab"]) {
      this.activePolicyTab = activePolicyTab;
    },
  },
});
