import { defineStore } from "pinia";
import { SupportRepresentative } from "@shared/types/localTypes/supportRepresentative.ts";

interface SupportRepresentativesState {
  _supportRepresentatives: SupportRepresentative[];
  _isLoading: boolean;
}
export const useSupportRepresentativesStore = defineStore({
  id: "supportRepresentatives",
  state: (): SupportRepresentativesState => ({
    _supportRepresentatives: [],
    _isLoading: true,
  }),
  getters: {
    supportRepresentatives: (state) => state._supportRepresentatives,
    isLoading: (state) => state._isLoading,
  },
  actions: {
    setSupportRepresentatives(
      supportRepresentatives: SupportRepresentativesState["_supportRepresentatives"],
    ) {
      this._supportRepresentatives = supportRepresentatives;
    },
    clearSupportRepresentatives() {
      this._supportRepresentatives = [];
      this._isLoading = true;
    },
    setIsLoading(isLoading: boolean) {
      this._isLoading = isLoading;
    },
  },
});
