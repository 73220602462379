import { type MaintenanceStatusResponse } from "@jfrog-ba/myjfrog-common";
import apiProxy from "@shared/utils/api-proxy";
import { useMaintenanceStore } from "@shared/stores/maintenance.ts";
import logger from "@shared/utils/logger.ts";

export const maintenanceStatus = {
  checkIsMyJfUnderMaintenance: async (): Promise<boolean> => {
    const maintenanceStore = useMaintenanceStore();
    if (maintenanceStore.isMaintenanceStatusLoadedState) {
      return maintenanceStore.isMyJFUnderMaintenanceState;
    }

    try {
      const response: MaintenanceStatusResponse | null =
        await apiProxy.get("maintenance-status");
      if (!response) {
        return false;
      }
      const isUnderMaintenance = response.isUnderMaintenance;
      maintenanceStore.setIsMyJFUnderMaintenance(isUnderMaintenance);
      maintenanceStore.setIsMaintenanceStatusLoaded(true);
      return isUnderMaintenance;
    } catch (e) {
      logger.error(e);
    }
    return false;
  },
};
