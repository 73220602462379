import { DateTime } from "luxon";

const json = import("../json/salesforceContacts.json");

type SalesforceContact = {
  First_Name__c: string;
  Last_Name__c: string;
  Email__c: string;
  Status__c: string;
  CreatedDate: string;
  Role_Type__c: string;
};

export default async () => {
  const awaitedJson = JSON.parse(JSON.stringify(await json));
  const awaitedJsonTyped = [...awaitedJson.default] as SalesforceContact[];

  awaitedJsonTyped.map((contact, index) => {
    contact.CreatedDate = DateTime.now()
      .minus({
        months: 5 + index,
      })
      .toString();
  });

  return awaitedJsonTyped;
};
