import {
  type BaseSupportContactRequest,
  type BaseSupportContactResponse,
  type DeleteSupportContactResponse,
  type GetQuotaResponse,
  isRestClientError,
  type SubscriptionDTO,
} from "@jfrog-ba/myjfrog-common";
import apiProxy from "@shared/utils/api-proxy";
import {
  SupportContactRequestUi,
  SupportContactResponseUi,
} from "@shared/types/localtypes.ts";
import { useEventLogger } from "@shared/use/useEventLogger.ts";
import { EventEntity, EventType } from "@shared/types/localTypes/events.ts";
import { GetSupportTicketsResponse } from "@shared/types/localTypes/supportTickets.ts";
import logger from "@shared/utils/logger.ts";
import { GetSupportRepresentativesResponse } from "@shared/types/localTypes/supportRepresentative.ts";

const mapSupportContactResponseSfTypeToUi = (
  baseSupportContactResponse: BaseSupportContactResponse,
): SupportContactResponseUi => {
  const {
    First_Name__c: firstName,
    Last_Name__c: lastName,
    Email__c: email,
    Status__c: status,
    Role_Type__c: role,
    CreatedDate: createdDate,
  } = baseSupportContactResponse;
  return {
    firstName,
    lastName,
    email,
    status,
    role,
    createdDate,
  };
};

const mapSupportContactFromUiToSfRequest = (
  supportContactRequestUi: SupportContactRequestUi,
): BaseSupportContactRequest => {
  const {
    firstName: first_name,
    lastName: last_name,
    email,
    role,
  } = supportContactRequestUi;

  return {
    first_name,
    last_name,
    email,
    role,
  };
};
export const salesforce = {
  getContacts: async (
    accountNumber: SubscriptionDTO["accountNumber"],
  ): Promise<SupportContactResponseUi[]> => {
    const response: BaseSupportContactResponse[] | null = await apiProxy.get(
      `salesforce/${accountNumber}/contacts`,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response.map((baseContact) =>
      mapSupportContactResponseSfTypeToUi(baseContact),
    );
  },
  getQuota: async (accountNumber: SubscriptionDTO["accountNumber"]) => {
    const response: GetQuotaResponse | null = await apiProxy.get(
      `salesforce/${accountNumber}/quota`,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  createNewContact: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: SupportContactRequestUi,
  ): Promise<SupportContactResponseUi> => {
    const response: BaseSupportContactResponse | null = await apiProxy.post(
      `salesforce/${accountNumber}/contact`,
      mapSupportContactFromUiToSfRequest(request),
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    useEventLogger().logEvent({
      eventType: EventType.CREATE,
      eventEntity: EventEntity.SUPPORT_CONTACTS,
      eventData: request,
    });
    return mapSupportContactResponseSfTypeToUi(response);
  },
  editExistingContact: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    request: SupportContactRequestUi,
    emailOfContactToEdit: string,
  ): Promise<SupportContactResponseUi> => {
    const response: BaseSupportContactResponse | null = await apiProxy.put(
      `salesforce/${accountNumber}/contact?email=${emailOfContactToEdit}`,
      mapSupportContactFromUiToSfRequest(request),
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return mapSupportContactResponseSfTypeToUi(response);
  },
  deleteContact: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    emailOfContactToDelete: string,
  ) => {
    const response: DeleteSupportContactResponse | null = await apiProxy.delete(
      `salesforce/${accountNumber}/contact?email=${emailOfContactToDelete}`,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    useEventLogger().logEvent({
      eventType: EventType.DELETE,
      eventEntity: EventEntity.SUPPORT_CONTACTS,
      eventData: { emailOfContactToDelete: emailOfContactToDelete },
    });
    return response;
  },
  getSupportTickets: async (
    accountNumber: SubscriptionDTO["accountNumber"],
  ) => {
    try {
      const response: GetSupportTicketsResponse | null = await apiProxy.get(
        `salesforce/${accountNumber}/support-tickets`,
      );
      if (!response) {
        logger.error(
          "Got no response from server while fetching support tickets",
        );
        return [];
      }
      return response.supportTickets;
    } catch (e) {
      if (isRestClientError(e) && e.httpBody?.errors?.length) {
        if (
          (e.httpBody?.errors[0] as unknown as { code: string }).code ===
          "NO_SUBSCRIPTION_FOUND"
        ) {
          return [];
        }
      }
      throw e;
    }
  },

  getSupportRepresentatives: async (
    accountNumber: SubscriptionDTO["accountNumber"],
  ) => {
    try {
      const response: GetSupportRepresentativesResponse | null =
        await apiProxy.get(
          `salesforce/${accountNumber}/support-representatives`,
        );
      if (!response) {
        logger.error(
          "Got no response from server while fetching support tickets",
        );
        return [];
      }
      return response.supportRepresentatives;
    } catch (e) {
      if (isRestClientError(e) && e.httpBody?.errors?.length) {
        if (
          (e.httpBody?.errors[0] as unknown as { code: string }).code ===
          "NO_SUBSCRIPTION_FOUND"
        ) {
          return [];
        }
      }
      throw e;
    }
  },
};
