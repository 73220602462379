import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { type ScreenSize } from "@shared/types/localtypes";
import { useRootCssVar } from "@shared/use/useRootCssVar.ts";

export const useScreenSize = () => {
  const rootCssVars = useRootCssVar();
  const screenSize = ref<ScreenSize>("tv");
  const isMobile = ref<boolean>(false);
  const isGreaterOrEqualsDesktop = ref<boolean>(false);
  const isGreaterOrEqualsLaptop = ref<boolean>(false);
  const isGreaterOrEqualsTablet = ref<boolean>(false);
  const isLowerThanDesktop = ref<boolean>(false);
  const isLowerThanLaptop = ref<boolean>(false);
  const isLowerThanTablet = ref<boolean>(false);
  const windowInnerWidth = ref<number>(0);
  const isBreakDashboardLayout = ref<boolean>(false);

  onBeforeMount(() => {
    calcScreenSize();
    window.addEventListener("resize", calcScreenSize);
  });

  onBeforeUnmount(() => window.removeEventListener("resize", calcScreenSize));

  watch(
    () => windowInnerWidth.value,
    (windowInnerWidth) => {
      isBreakDashboardLayout.value = windowInnerWidth < 1440;
    },
    {
      immediate: true,
    },
  );
  const calcScreenSize = () => {
    windowInnerWidth.value = window.innerWidth;

    if (windowInnerWidth.value > rootCssVars.breakpointsDesktopInt) {
      screenSize.value = "tv";
      isMobile.value = false;
      isGreaterOrEqualsDesktop.value = true;
      isGreaterOrEqualsLaptop.value = true;
      isGreaterOrEqualsTablet.value = true;
      isLowerThanDesktop.value = false;
      isLowerThanLaptop.value = false;
      isLowerThanTablet.value = false;
      return;
    }
    if (windowInnerWidth.value > rootCssVars.breakpointsLaptopInt) {
      screenSize.value = "desktop";
      isMobile.value = false;
      isGreaterOrEqualsDesktop.value = true;
      isGreaterOrEqualsLaptop.value = true;
      isGreaterOrEqualsTablet.value = true;
      isLowerThanDesktop.value = false;
      isLowerThanLaptop.value = false;
      isLowerThanTablet.value = false;
      return;
    }
    if (windowInnerWidth.value > rootCssVars.breakpointsTabletInt) {
      screenSize.value = "laptop";
      isMobile.value = false;
      isGreaterOrEqualsDesktop.value = false;
      isGreaterOrEqualsLaptop.value = true;
      isGreaterOrEqualsTablet.value = true;
      isLowerThanDesktop.value = true;
      isLowerThanLaptop.value = false;
      isLowerThanTablet.value = false;
      return;
    }
    if (windowInnerWidth.value > rootCssVars.breakpointsMobileInt) {
      screenSize.value = "tablet";
      isMobile.value = true;
      isGreaterOrEqualsDesktop.value = false;
      isGreaterOrEqualsLaptop.value = false;
      isGreaterOrEqualsTablet.value = true;
      isLowerThanDesktop.value = true;
      isLowerThanLaptop.value = true;
      isLowerThanTablet.value = false;
      return;
    }
    screenSize.value = "smartphone";
    isMobile.value = true;
    isGreaterOrEqualsDesktop.value = false;
    isGreaterOrEqualsLaptop.value = false;
    isGreaterOrEqualsTablet.value = false;
    isLowerThanDesktop.value = true;
    isLowerThanLaptop.value = true;
    isLowerThanTablet.value = true;
  };

  return {
    screenSize,
    isMobile,
    windowInnerWidth,
    isGreaterOrEqualsDesktop,
    isGreaterOrEqualsLaptop,
    isGreaterOrEqualsTablet,
    isLowerThanDesktop,
    isLowerThanLaptop,
    isLowerThanTablet,
    isBreakDashboardLayout,
  };
};
