import { defineStore } from "pinia";
import { type GlobalLoaderTheme } from "@shared/types/localTypes/globalLoader";

interface GlobalLoaderStoreStates {
  displayed: boolean;
  theme: GlobalLoaderTheme;
}
export const useGlobalLoaderStore = defineStore({
  id: "globalLoader",
  state: () => ({
    displayed: true as GlobalLoaderStoreStates["displayed"],
    theme: "platform" as GlobalLoaderStoreStates["theme"],
  }),
  getters: {
    isDisplayed: (state) => state.displayed,
    getTheme: (state) => state.theme,
  },
  actions: {
    setTheme(theme: GlobalLoaderStoreStates["theme"]) {
      this.theme = theme;
    },
    clearTheme() {
      this.setTheme("default");
    },
    show() {
      this.displayed = true;
    },
    hide() {
      this.displayed = false;
    },
  },
});
