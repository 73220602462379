export enum EventEntity {
  SUPPORT_CONTACTS = "SUPPORT_CONTACTS",
  USER = "USER",
  PLAN = "PLAN",
  BILLING = "BILLING",
  LOG_SHIPPING = "LOG_SHIPPING",
  USAGE_BREAKDOWN = "USAGE_BREAKDOWN",
  SAAS_TOKEN = "SAAS_TOKEN",
  LOG_STREAMER = "LOG_STREAMER",
  IP_ALLOW_LIST = "IP_ALLOW_LIST",
  DNS_ROUTING = "DNS_ROUTING",
}

export enum EventType {
  CREATE = "CREATE",
  DELETE = "DELETE",
  RESEND_INVITATION = "RESEND_INVITATION",
  NOTIFICATION_CREATED = "NOTIFICATION_CREATED",
  NOTIFICATION_UPDATED = "NOTIFICATION_UPDATED",
  NOTIFICATION_DELETED = "NOTIFICATION_DELETED",
  UPDATE = "UPDATE",
  QUICK_PURCHASE = "QUICK_PURCHASE",
  PURCHASE = "PURCHASE",
  QUICK_UPGRADE = "QUICK_UPGRADE",
  UPGRADE = "UPGRADE",
  QUICK_GET_QUOTE = "QUICK_GET_QUOTE",
  GET_QUOTE = "GET_QUOTE",
  REQUEST_TRIAL_JAS = "REQUEST_TRIAL_JAS",
  REQUEST_TRIAL_CURATION = "REQUEST_TRIAL_CURATION",
  REPORT_DOWNLOAD = "REPORT_DOWNLOAD",
  CREDIT_CARD_EDIT = "CREDIT_CARD_EDIT",
  DETAILS_EDIT = "DETAILS_EDIT",
  ENABLE = "ENABLE",
  EXPORT_CSV = "EXPORT_CSV",
  DISABLE = "DISABLE",
  DISABLE_TEMPORARILY = "DISABLE_TEMPORARILY",
  ENABLE_GEOLOCATION = "ENABLE_GEOLOCATION",
  ENABLE_MANUAL_FAILOVER = "ENABLE_MANUAL_FAILOVER",
  DISABLE_GEOLOCATION = "DISABLE_GEOLOCATION",
  DISABLE_MANUAL_FAILOVER = "DISABLE_MANUAL_FAILOVER",
  DELETE_GEOLOCATION = "DELETE_GEOLOCATION",
  DELETE_MANUAL_FAILOVER = "DELETE_MANUAL_FAILOVER",
  CREATE_GEOLOCATION = "CREATE_GEOLOCATION",
  CREATE_MANUAL_FAILOVER = "CREATE_MANUAL_FAILOVER",
  UPDATE_GEOLOCATION = "UPDATE_GEOLOCATION",
  UPDATE_MANUAL_FAILOVER = "UPDATE_MANUAL_FAILOVER",
  SWITCH = "SWITCH",
}
