import { type RouteLocationNormalized } from "vue-router";
import { MenuItemNames, useNavStore } from "@shared/stores/navigation";
import { menuItemsCalculator } from "@shared/services/navigation";
import { type MjfRouteMeta } from "@shared/types/localTypes/router";

export const userGrantedToReachRoute = (to: RouteLocationNormalized) => {
  const meta = to.meta as MjfRouteMeta;
  if (meta.isLandingView) {
    return true;
  }

  let routeMenuItemName: MenuItemNames | undefined =
    useNavStore().getMenuItems.find(
      (menuItem) => menuItem.routeName === to.name,
    )?.name;

  if (!routeMenuItemName && meta.parentRoute) {
    routeMenuItemName = useNavStore().getMenuItems.find(
      (menuItem) => menuItem.routeName === meta.parentRoute,
    )?.name;
  }

  const subscriptionId = to.params.subscriptionId as string;
  if (!routeMenuItemName || !subscriptionId) {
    return false;
  }

  return userGrantedToReachMenuForSubscription(
    routeMenuItemName,
    subscriptionId,
  );
};

export const userGrantedToReachMenuForSubscription = (
  menuItemName: MenuItemNames,
  subscriptionId: string,
) => {
  return (
    menuItemsCalculator[menuItemName].isVisible(subscriptionId) &&
    menuItemsCalculator[menuItemName].isEnabled(subscriptionId)
  );
};
