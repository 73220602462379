import { rolesService } from "@shared/services/roles";
import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { type NestedLeafs } from "@shared/types/localTypes/object-helpers";
import {
  type PermissionItem,
  type Permissions,
} from "@shared/types/localTypes/permissions";
import { getDeep } from "@shared/utils/object-tools";
import {
  MYJFROG_ADMIN,
  MYJFROG_FINANCE_MEMBER,
  MYJFROG_PLATFORM_ADMIN,
  MYJFROG_PRIMARY_ADMIN,
  MYJFROG_TECHNICAL_MEMBER,
  MYJFROG_ADMIN_READONLY,
} from "@shared/utils/const";

export const permissionsMap: Permissions = {
  Profile: {
    ApiTokenVisualize: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_TECHNICAL_MEMBER,
      MYJFROG_ADMIN_READONLY,
      MYJFROG_PLATFORM_ADMIN,
    ],
    CopyTokenEnabled: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_TECHNICAL_MEMBER,
    ],
    GenerateApiToken: [MYJFROG_PRIMARY_ADMIN],
    Tabs: {
      PersonalInfo: {
        WritePermissions: [
          MYJFROG_ADMIN,
          MYJFROG_FINANCE_MEMBER,
          MYJFROG_PLATFORM_ADMIN,
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
        Role: {
          SeeMoreInfoTooltip: [
            MYJFROG_FINANCE_MEMBER,
            MYJFROG_TECHNICAL_MEMBER,
          ],
        },
      },
    },
  },
  Subscription: {
    Upgrade: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
  },
  Usage: {
    Tabs: {
      UsageData: {
        Access: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
          MYJFROG_ADMIN_READONLY,
        ],
        WritePermissions: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
      DataTransferLogs: {
        Access: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
          MYJFROG_ADMIN_READONLY,
        ],
        WritePermissions: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
    },
  },
  Billing: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_FINANCE_MEMBER,
      MYJFROG_ADMIN_READONLY,
    ],
    WritePermissions: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_FINANCE_MEMBER,
    ],
    CanPurchase: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_PLATFORM_ADMIN,
      MYJFROG_FINANCE_MEMBER,
    ],
  },
  Topology: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_PLATFORM_ADMIN,
      MYJFROG_TECHNICAL_MEMBER,
      MYJFROG_ADMIN_READONLY,
    ],
    JpdConfiguration: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_TECHNICAL_MEMBER,
    ],
  },
  Security: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_PLATFORM_ADMIN,
      MYJFROG_TECHNICAL_MEMBER,
      MYJFROG_ADMIN_READONLY,
    ],
    Tabs: {
      IPAllowList: {
        WritePermissions: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
      PrivateConnections: {
        WritePermissions: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
      GeolocationRestrictions: {
        WritePermissions: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
    },
  },
  ManageUsers: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_ADMIN_READONLY,
    ],
    WritePermissions: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
    SmartNotification: {
      UpdateRegularUsers: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
    },
  },
  SupportContacts: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_TECHNICAL_MEMBER,
      MYJFROG_PLATFORM_ADMIN,
      MYJFROG_FINANCE_MEMBER,
      MYJFROG_ADMIN_READONLY,
    ],
    WritePermissions: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
    DeleteContact: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
  },
  Settings: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_TECHNICAL_MEMBER,
      MYJFROG_PLATFORM_ADMIN,
      MYJFROG_ADMIN_READONLY,
    ],
    Tabs: {
      General: {
        PublicRootCertificateWrite: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
        PlatformAccess: [MYJFROG_PRIMARY_ADMIN],
        CloudChangesWrite: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
        ArtifactoryConfigurationTransferWrite: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
      ModifySubscription: {
        Access: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN, MYJFROG_ADMIN_READONLY],
        WritePermissions: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
      },
      Cname: {
        WritePermission: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
      LogStreamer: {
        Access: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
          MYJFROG_PLATFORM_ADMIN,
          MYJFROG_ADMIN_READONLY,
        ],
        WritePermissions: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
      DnsRouting: {
        ModuleAccess: [
          MYJFROG_ADMIN,
          MYJFROG_PLATFORM_ADMIN,
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
          MYJFROG_ADMIN_READONLY,
        ],
        WritePermissions: [
          MYJFROG_PRIMARY_ADMIN,
          MYJFROG_ADMIN,
          MYJFROG_TECHNICAL_MEMBER,
        ],
      },
    },
  },
  NotificationCenter: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_PLATFORM_ADMIN,
      MYJFROG_ADMIN_READONLY,
      MYJFROG_TECHNICAL_MEMBER,
      MYJFROG_FINANCE_MEMBER,
    ],
    CreateNotification: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_TECHNICAL_MEMBER,
      MYJFROG_FINANCE_MEMBER,
    ],
    EditAllNotifications: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
    DeleteAllNotifications: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
  },
  JPD: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_PLATFORM_ADMIN,
      MYJFROG_ADMIN_READONLY,
    ],
    WritePermissions: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
  },
  SupportTickets: {
    ModuleAccess: [MYJFROG_PRIMARY_ADMIN],
  },
  SupportRepresentatives: {
    ModuleAccess: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
  },
  Licenses: {
    ModuleAccess: [
      MYJFROG_PRIMARY_ADMIN,
      MYJFROG_ADMIN,
      MYJFROG_PLATFORM_ADMIN,
      MYJFROG_ADMIN_READONLY,
    ],
    WritePermissions: [MYJFROG_PRIMARY_ADMIN, MYJFROG_ADMIN],
  },
};

export const permissions = {
  has: (
    permissionPath: NestedLeafs<Permissions>,
    subscriptionNumber?: string,
  ): boolean => {
    subscriptionNumber =
      subscriptionNumber ||
      useSubscriptionsStore().getCurrentSubscriptionNumber;
    const userRole =
      rolesService.extractUserRoleForSubscription(subscriptionNumber);
    if (!userRole) {
      return false;
    }
    const rolesAllowed = getDeep(
      permissionsMap,
      permissionPath,
    ) as unknown as PermissionItem;

    return rolesAllowed.includes(userRole);
  },
};
