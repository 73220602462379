import { defineStore } from "pinia";
import { AlertProps } from "@shared/types/localTypes/props.ts";

interface DefaultMessages {
  title: string;
  description: string;
  type?: AlertProps["type"];
}

const defaultMessages: DefaultMessages = {
  title: "",
  description: "",
  type: "error",
};

export const useAlertMessagesStore = defineStore({
  id: "alertMessages",
  state: () => ({
    title: defaultMessages.title,
    description: defaultMessages.description,
    type: defaultMessages.type,
  }),
  getters: {
    getTitle: (state) => state.title,
    getDescription: (state) => state.description,
    getType: (state) => state.type,
  },
  actions: {
    async setMessages({ title, description, type }: DefaultMessages) {
      this.title = title;
      this.description = description;
      this.type = type ?? defaultMessages.type;
    },
    clearMessages() {
      this.title = defaultMessages.title;
      this.description = defaultMessages.description;
      this.type = defaultMessages.type;
    },
  },
});
