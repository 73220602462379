import { LocalStorageKeys } from "@shared/types/localTypes/storage";
import window from "@shared/utils/app-window";
import { stringLocalStorage } from "@shared/module-transformers/localStorage.ts";

const expireInHours = 24;
const marketoCookieName = "_mkto_trk";

let marketoCookie: string;

export const cookiesService = {
  getExpiration: () => {
    const now = new Date();
    now.setTime(now.getTime() + expireInHours * 3600 * 1000);
    return now;
  },

  setFromCookie: async () => {
    const existingFrom = await stringLocalStorage.get(
      LocalStorageKeys.FROM_QUERY,
    );
    if (existingFrom) {
      return;
    }

    const pathname = window.location.pathname;
    const pathsToSkip = ["/login"];

    for (let i = 0; i < pathsToSkip.length; i++) {
      if (pathname.startsWith(pathsToSkip[i])) {
        return;
      }
    }

    await stringLocalStorage.set(
      LocalStorageKeys.FROM_QUERY,
      pathname + window.location.search,
    );
  },

  marketoCookie: (): string | undefined => {
    if (marketoCookie) {
      return marketoCookie;
    }

    const cookies = document.cookie.split("; ");

    const prefix = `${marketoCookieName}=`;

    for (const cookieStr of cookies) {
      if (cookieStr.startsWith(prefix)) {
        marketoCookie = cookieStr.substring(prefix.length);

        return marketoCookie;
      }
    }
  },
};
