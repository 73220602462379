import { useAuthenticationStore } from "@shared/stores/authentication";
import { type NavigationGuard } from "vue-router";
import { usersService } from "@shared/services/user";
import {
  isRestClientError,
  storeErrorsMapping,
} from "@jfrog-ba/myjfrog-common";
import { useAlertMessagesStore } from "@shared/stores/alertMessages";
import { useLoginMessagesStore } from "@shared/stores/loginMessages";
import { RouteNames } from "@shared/types/localTypes/router";

export const otpRequired: NavigationGuard = (to, from, next) => {
  if (!useAuthenticationStore().getOtpAuthenticationData) {
    return next({ name: RouteNames.Root });
  }
  return next();
};

export const registrationTokenRequired: NavigationGuard = async (
  to,
  from,
  next,
) => {
  const token = to.query.token as string;

  if (!token) {
    await notActiveLinkMessage();
    return next({ name: RouteNames.RegisterNow });
  }

  try {
    await usersService.checkRegistrationToken(token);
  } catch (e) {
    if (isRestClientError(e) && e.httpBody) {
      if (
        e.httpBody.result ===
        storeErrorsMapping.registration.userAlreadyRegistered
      ) {
        await useLoginMessagesStore().setMessages({
          title: "Your Registration is Complete",
          description: "You May Log in",
        });
        return next({ name: RouteNames.Login });
      }
    }
    await notActiveLinkMessage();
    return next({ name: RouteNames.RegisterNow });
  }
  return next();
};

export const resetPasswordKeyRequired: NavigationGuard = async (
  to,
  from,
  next,
) => {
  const key = to.query.key as string;
  if (!key) {
    await useAlertMessagesStore().setMessages({
      title: "",
      description: "Your reset password link is no longer active.",
    });
    return next({ name: RouteNames.Login });
  }

  return next();
};

const notActiveLinkMessage = async () => {
  await useAlertMessagesStore().setMessages({
    title: "",
    description:
      "Your registration link is no longer active, revalidate your email. Please revalidate your email",
  });
};
