export enum LocalStorageKeys {
  SESSION_DATA = "sessionData",
  FROM_QUERY = "__MJF_FROM_QUERY",
  APPLICATION_START_DATETIME = "__MJF_APPLICATION_START_DATETIME",
  LAST_SUBSCRIPTION_CHANGE_VIA_SELECTOR_COMPONENT = "__MJF_LAST_SUBSCRIPTION_CHANGE_VIA_SELECTOR_COMPONENT",
  MJF_LIGHT_UPGRADE_IN_SUCCESS = "__MJF_LIGHT_UPGRADE_IN_SUCCESS",
  THEME = "__MJF_THEME",
  GEN_VERSION = "__MJF_GEN_VERSION",
  MENU_OPEN = "__MJF_MENU_OPEN",
  LAST_SUCCESSFUL_LOGIN = "__MJF__LAST_SUCCESSFUL_LOGIN",
  LAST_SUCCESSFUL_LOGOUT = "__MJF__LAST_SUCCESSFUL_LOGOUT",
  LAST_LOGIN_EMAIL = "LAST_LOGIN_EMAIL",
  CAN_RELOAD_LOCK = "_MJF_CAN_RELOAD_LOCK",
}
