import { NotificationHistory } from "@shared/types/localTypes/notifications-center.ts";
import { DateTime } from "luxon";

const calculateDeliveryTimestamp = (notification: NotificationHistory) => {
  switch (notification.templateName) {
    case "DAILY_THRESHOLD":
      return 19;
    case "MONTHLY_THRESHOLD":
      return 29;
    case "CNAME_EXPIRATION":
      return 24;
    default:
      return 30;
  }
};

export default async () => {
  const history = JSON.parse(
    JSON.stringify(
      await import("../json/notificationsHistorySubscriptionSentGet.json"),
    ),
  );

  return {
    ...history,
    items: history.items.map((notification: NotificationHistory) => ({
      ...notification,
      deliveryTimestamp: DateTime.now()
        .minus({ months: 1 })
        .startOf("month")
        .plus({
          days: calculateDeliveryTimestamp(notification),
        })
        .toMillis(),
    })),
  };
};
