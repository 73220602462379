import { defineStore } from "pinia";

export type Theme = "light" | "dark";

interface ThemeStoreState {
  _activeTheme: Theme;
}

export const useThemeStore = defineStore({
  id: "theme",
  state: (): ThemeStoreState => ({
    _activeTheme: "light",
  }),
  getters: {
    activeTheme: (state) => state._activeTheme,
    isDark: (state) => state._activeTheme === "dark",
    isLight: (state) => state._activeTheme === "light",
  },
  actions: {
    setActiveTheme(theme: ThemeStoreState["_activeTheme"]) {
      this._activeTheme = theme;
    },
  },
});
