import {
  BomType,
  type SubscriptionDTO,
  type SubscriptionMeta,
} from "@jfrog-ba/myjfrog-common";
import { subscriptionBaseName } from "@shared/services/metas/subscriptionBaseName";

export const selfHostedMetasHardCoded: (
  subscription: SubscriptionDTO,
) => SubscriptionMeta | null = (subscription) => {
  const bom = subscription.bom;
  switch (bom.type) {
    case BomType.PRO: {
      return {
        paymentTypeName: "BOM_BASED",
        baseName: subscriptionBaseName.PRO,
      };
    }
    case BomType.PRO_X: {
      return {
        paymentTypeName: "BOM_BASED",
        baseName: subscriptionBaseName.PRO,
        hasXray: true,
      };
    }
    case BomType.ENT: {
      return {
        paymentTypeName: "BOM_BASED",
        baseName: subscriptionBaseName.ENTERPRISE,
        isEnterprise: true,
      };
    }
    case BomType.ENT_X: {
      return {
        paymentTypeName: "BOM_BASED",
        baseName: subscriptionBaseName.ENTERPRISE,
        isEnterpriseX: true,
        hasXray: true,
      };
    }
    case BomType.ENT_PLUS: {
      return {
        paymentTypeName: "BOM_BASED",
        baseName: subscriptionBaseName.ENTERPRISE_PLUS,
        isEnterprisePlus: true,
        hasXray: true,
      };
    }
    case BomType.ENT_PLUS_HYBRID: {
      return {
        paymentTypeName: "BOM_BASED",
        baseName: subscriptionBaseName.ENTERPRISE_PLUS_HYBRID,
        isEnterprisePlus: true,
        isEnterprisePlusHybrid: true,
        hasXray: true,
      };
    }
    default:
      return null;
  }
};
