import { LocalStorageKeys } from "@shared/types/localTypes/storage.ts";

export const useSessionLogic = () => {
  const isSessionActive = () => {
    const lastLoginMillis =
      window.localStorage.getItem(LocalStorageKeys.LAST_SUCCESSFUL_LOGIN) ||
      "0";
    const lastLogoutMillis =
      window.localStorage.getItem(LocalStorageKeys.LAST_SUCCESSFUL_LOGOUT) ||
      "0";

    return parseInt(lastLoginMillis) > parseInt(lastLogoutMillis);
  };

  const lockReload = () => {
    window.sessionStorage.setItem(LocalStorageKeys.CAN_RELOAD_LOCK, "false");
  };
  const unlockReloadWithDelay = () => {
    setTimeout(() => {
      window.sessionStorage.setItem(LocalStorageKeys.CAN_RELOAD_LOCK, "true");
    }, 5000);
  };
  return {
    isSessionActive,
    lockReload,
    unlockReloadWithDelay,
  };
};
