export const constants = {
  PASSWORD_REGEX: new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&+=~*]).{8,32}$",
  ),
  IP_REGEX: new RegExp(
    "^((\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])\\.){3}(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])(\\/(\\d|[1-2]\\d|3[0-2]))?$",
  ),
  RFC_1918_REGEX: new RegExp(
    "(^192\\.168\\.(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])(/(\\d|[1-2]\\d|3[0-2]))$)|(^172\\.(1[6-9]|2\\d|3[0-1])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])(/(\\d|[1-2]\\d|3[0-2]))$)|(^10\\.(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])(/(\\d|[1-2]\\d|3[0-2]))$)",
  ),
  IP_ZEROS: new RegExp("^0\\.0\\.0\\.0(\\/\\d{1,2})?$"),
  AWS_PRIVATE_LINK_REGEX: new RegExp("^vpce-[a-z0-9]+$"),
  JPD_SERVER_NAME_REGEX: new RegExp("^[a-z]+[a-z0-9]*$"),
  DNS_ROUTING_SINGLE_URL_REGEX: new RegExp("^[a-zA-Z]+[a-zA-Z0-9]*$"),
  EMAIL_REGEX: new RegExp(
    "^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,}){1}$)",
  ),
  JFROG_EMAIL_REGEX: new RegExp(
    "^[_A-Za-z0-9-]+(([.+])[_A-Za-z0-9-]+)*(@jfrog.com)$",
  ),
  FULL_NAME_REGEX: new RegExp("^[a-zA-Z0-9.\\-' ]*$"),
  GET_A_QUOTE_REGEX: new RegExp(
    "^[ 0-9a-zA-Z<>!@/,.\\-_=()+*&^%$#'\"`\\\\|{}[\\]~;:\\n]*$",
  ),
  SINGLE_CERTIFICATE_REGEX: new RegExp(
    "^(-----[a-zA-Z ]+-----(\\n|\\r|\\r\\n)([0-9a-zA-Z\\+\\/=]{64}(\\n|\\r|\\r\\n))*([0-9a-zA-Z\\+\\/=]{1,63}(\\n|\\r|\\r\\n))?-----[a-zA-Z ]+-----)$",
  ),
  MULTIPLE_CERTIFICATES_REGEX: new RegExp(
    "^((-----[a-zA-Z ]+-----(\\n|\\r|\\r\\n)([0-9a-zA-Z\\+\\/=]{64}(\\n|\\r|\\r\\n))*([0-9a-zA-Z\\+\\/=]{1,63}(\\n|\\r|\\r\\n))?-----[a-zA-Z ]+-----\\s*)+)$",
  ),
  LOG_STREAMER_TAGS: new RegExp("(.+):(.+)"),
  DOCKER_REPOSITORY_NAME_OVERRIDE_REGEX: new RegExp("^[a-z0-9-]+$"),
  SSL_DOMAINS_UNDER_CERTIFICATE_THRESHOLD: 100,
};

export const byte = 1;
export const KB = 1024;
export const MB = KB * 1024;
export const GB = MB * 1024;
export const TB = GB * 1024;
export const PB = TB * 1024;
export const EB = PB * 1024;

export const simpleNumber = 1;
export const K = 1000;
export const M = K * 1000;
export const B = M * 1000;

export const daysCounterTrial = {
  daysLeftWarning: 8,
  daysLeftDanger: 4,
};
