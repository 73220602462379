import {
  GEO_LOCATIONS_URL,
  LOG_STREAMER_URL,
  JPD_READINESS_URL,
  PLUGIN_STATUS_URL,
  JPD_SSO_ENABLED_URL,
} from "@shared/services/tour/urls/settings-urls.ts";
import geoLocation from "@shared/services/tour/mockedApis/geoLocation.ts";
import logStreamer from "@shared/services/tour/mockedApis/logStreamer.ts";
import jpdReadiness from "@shared/services/tour/mockedApis/jpdReadiness.ts";
import pluginStatus from "@shared/services/tour/mockedApis/pluginStatus.ts";
import jpdSSOEnabled from "@shared/services/tour/mockedApis/jpdSSOEnabled.ts";

export default {
  [GEO_LOCATIONS_URL]: () => geoLocation(),
  [LOG_STREAMER_URL]: () => logStreamer(),
  [JPD_READINESS_URL]: () => jpdReadiness(),
  [PLUGIN_STATUS_URL]: () => pluginStatus(),
  [JPD_SSO_ENABLED_URL]: () => jpdSSOEnabled(),
};
