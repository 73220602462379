import { LocalStorageKeys } from "@shared/types/localTypes/storage";
import { onMounted } from "vue";
import { useNavStore } from "@shared/stores/navigation";

export default () => {
  const navStore = useNavStore();

  onMounted(() => {
    const menuStored = window.localStorage.getItem(LocalStorageKeys.MENU_OPEN);

    navStore.setMenuOpen(menuStored === "true");
  });
};
