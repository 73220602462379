import { ElNotification } from "element-plus";
import { useToastNotificationsStore } from "@shared/stores/toastNotifications";
import {
  NotificationOptions,
  ToastNotificationCtrl,
} from "@shared/types/localTypes/toastNotifications.ts";
import logger from "@shared/utils/logger.ts";
import { useSessionLogic } from "@shared/use/useSessionLogic.ts";

const generateNotificationKey = () => {
  return `${Date.now()}${Math.random()}`;
};

export const useNotifications: ToastNotificationCtrl = () => {
  const toastNotificationsStore = useToastNotificationsStore();
  const sessionLogic = useSessionLogic();

  const success = async (message: string, options?: NotificationOptions) => {
    const key = generateNotificationKey();
    const title = options?.title || "Success";
    const type = "success";
    if (
      toastNotificationsStore.isNotificationVisible({ type, title, message })
    ) {
      return;
    }
    ElNotification({
      message: message,
      title: title,
      type: type,
      customClass: "success",
      duration: options?.duration,
      position: "bottom-left",
      dangerouslyUseHTMLString: true,
      key,
      onClose: () => {
        toastNotificationsStore.removeVisibleNotification(key);
      },
      ...(options?.zIndex && { zIndex: options.zIndex }),
    });
    toastNotificationsStore.addVisibleNotification({
      key,
      type,
      title,
      message,
    });
  };

  const warning = async (message: string, options?: NotificationOptions) => {
    const key = generateNotificationKey();
    const title = options?.title || "Warning";
    const type = "warning";
    if (
      toastNotificationsStore.isNotificationVisible({ type, title, message })
    ) {
      return;
    }
    ElNotification({
      message: message,
      title: title,
      type: type,
      customClass: "warning",
      duration: options?.duration,
      position: "bottom-left",
      dangerouslyUseHTMLString: true,
      ...(options?.zIndex && { zIndex: options.zIndex }),
      key,
      onClose: () => {
        toastNotificationsStore.removeVisibleNotification(key);
      },
    });
    toastNotificationsStore.addVisibleNotification({
      key,
      type,
      title,
      message,
    });
  };

  const error = async (message: string, options?: NotificationOptions) => {
    const key = generateNotificationKey();
    const title = options?.title || "Error";
    const type = "error";

    if (toastNotificationsStore.errorLocked && !options?.forceDisplay) {
      return;
    }
    if (!sessionLogic.isSessionActive()) {
      logger.error("notification error fallback : " + message);
      return;
    }
    if (
      toastNotificationsStore.isNotificationVisible({ type, title, message })
    ) {
      return;
    }
    ElNotification({
      message: message,
      title: title,
      type: type,
      customClass: "error",
      duration: options?.duration || -1,
      position: "bottom-left",
      dangerouslyUseHTMLString: true,
      ...(options?.zIndex && { zIndex: options.zIndex }),
      key,
      onClose: () => {
        toastNotificationsStore.removeVisibleNotification(key);
      },
    });
    toastNotificationsStore.addVisibleNotification({
      key,
      type,
      title,
      message,
    });
  };

  const closeAll = async () => {
    ElNotification.closeAll();
  };

  return { success, warning, error, closeAll };
};
