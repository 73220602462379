import { DateTime } from "luxon";

const json = import("../json/getNotificationsBySubscriptionNumbers.json");

export default async () => {
  const awaitedJson = await json;
  const parsedJson = JSON.parse(
    JSON.stringify(awaitedJson),
  ) as typeof awaitedJson;

  parsedJson.items.map((item) => ({
    ...item,
    notifications: item.notifications.map((notification) => ({
      ...notification,
      creationDate: (notification.creationDate = DateTime.now()
        .minus({
          months: 6,
        })
        .toMillis()),
    })),
  }));

  return parsedJson;
};
