import {
  calculateValue,
  isUsagePage,
} from "@shared/services/tour/mockedApis/usageExposer/helper.ts";
import { GetAggregatedTrafficByPackageTypeV2Response } from "@shared/services/usageExposer/getAggregatedTrafficPerPackages.ts";

const jsonDashboard = import(
  "../../json/usageExposer/dashboard/usageExposerGetAggregatedTrafficByPackageType.json"
);

const jsonUsage = import(
  "../../json/usageExposer/usagePage/usageExposerGetAggregatedTrafficByPackageType.json"
);

const updateUsage = <T extends GetAggregatedTrafficByPackageTypeV2Response>(
  response: T,
  updateCallback: (value: number) => number,
  moveUsage: (
    currentNumber: number,
    statistics: GetAggregatedTrafficByPackageTypeV2Response["traffic"]["top"][0],
  ) => number = (currentNumber) => currentNumber,
): T => {
  response.traffic.top.forEach((statistic) => {
    statistic.totalDataTransferBytes = moveUsage(
      updateCallback(statistic.totalDataTransferBytes),
      statistic,
    );
    statistic.totalDownloadBytes = moveUsage(
      updateCallback(statistic.totalDataTransferBytes),
      statistic,
    );
    statistic.totalUploadBytes = moveUsage(
      updateCallback(statistic.totalDataTransferBytes),
      statistic,
    );

    statistic.serviceAggregatedTrafficList.forEach((service) => {
      service.totalDataTransferBytes = moveUsage(
        updateCallback(statistic.totalDataTransferBytes),
        statistic,
      );
      service.totalUploadBytes = moveUsage(
        updateCallback(statistic.totalDataTransferBytes),
        statistic,
      );
      service.totalDownloadBytes = moveUsage(
        updateCallback(statistic.totalDataTransferBytes),
        statistic,
      );
    });
  });

  const remaining = response.traffic.remaining;

  if (remaining) {
    remaining.totalDataTransferBytes = updateCallback(
      remaining.totalDataTransferBytes,
    );
    remaining.totalDownloadBytes = updateCallback(remaining.totalDownloadBytes);
    remaining.totalUploadBytes = updateCallback(remaining.totalUploadBytes);

    remaining.serviceAggregatedTrafficList.forEach((service) => {
      service.totalDataTransferBytes = updateCallback(
        service.totalDataTransferBytes,
      );
      service.totalUploadBytes = updateCallback(service.totalUploadBytes);
      service.totalDownloadBytes = updateCallback(service.totalDownloadBytes);
    });
  }

  return response;
};

export default async (data?: object) => {
  if (isUsagePage(data)) {
    return updateUsage(
      JSON.parse(JSON.stringify(await jsonUsage)),
      calculateValue,
      (currentNumber, statistics) => {
        const numberToMove = 21000011000;

        if (statistics.packageType === "docker") {
          return currentNumber - numberToMove;
        }
        return currentNumber + numberToMove / 8;
      },
    );
  }

  return updateUsage(
    JSON.parse(JSON.stringify(await jsonDashboard)),
    calculateValue,
  );
};
