// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useLocalStorage as _useLocalStorage } from "@/use/useLocalStorage";
import { LocalStorageCtrl } from "@shared/types/localTypes/localStorage.ts";
import { SessionData } from "@shared/types/localtypes.ts";

export const genericLocalStorage: LocalStorageCtrl<unknown> =
  _useLocalStorage();
export const sessionDataLocalStorage: LocalStorageCtrl<SessionData> =
  _useLocalStorage();

export const stringLocalStorage: LocalStorageCtrl<string> = _useLocalStorage();
