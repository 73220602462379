import {
  type SaasPaymentType,
  type SubscriptionMeta,
} from "@jfrog-ba/myjfrog-common";
import { subscriptionBaseName } from "@shared/services/metas/subscriptionBaseName";

export const saasMetasHardCoded: {
  [key in SaasPaymentType]: SubscriptionMeta;
} = {
  FREE: {
    paymentTypeName: "FREE",
    baseName: subscriptionBaseName.FREE,
    isFree: true,
  },
  ENTERPRISE_PLUS_HYBRID: {
    isBilled: true,
    paymentTypeName: "ENTERPRISE_PLUS_HYBRID",
    baseName: subscriptionBaseName.ENTERPRISE_PLUS_HYBRID,
    isEnterprisePlus: true,
    isEnterprisePlusHybrid: true,
    isMonthlyPayment: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  PRO_TEAM_MONTHLY: {
    isBilled: true,
    paymentTypeName: "PRO_TEAM_MONTHLY",
    baseName: subscriptionBaseName.PRO,
    isProTeam: true,
    isMonthlyPayment: true,
    hasXray: true,
  },
  PRO_TEAM_PREPAID: {
    isBilled: true,
    paymentTypeName: "PRO_TEAM_PREPAID",
    baseName: subscriptionBaseName.PRO,
    isProTeam: true,
    isPrepaidPayment: true,
    hasXray: true,
  },
  ENTERPRISE_MONTHLY: {
    isBilled: true,
    paymentTypeName: "ENTERPRISE_MONTHLY",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isMonthlyPayment: true,
    hasXray: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  ENTERPRISE_PREPAID: {
    isBilled: true,
    paymentTypeName: "ENTERPRISE_PREPAID",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isPrepaidPayment: true,
    hasXray: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  ENTERPRISE_X_MONTHLY: {
    isBilled: true,
    paymentTypeName: "ENTERPRISE_X_MONTHLY",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isMonthlyPayment: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  ENTERPRISE_X_PREPAID: {
    isBilled: true,
    paymentTypeName: "ENTERPRISE_X_PREPAID",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isPrepaidPayment: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  MONTHLY: {
    isBilled: true,
    paymentTypeName: "MONTHLY",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isMonthlyPayment: true,
    isCloudPro: true,
  },
  JCR: {
    paymentTypeName: "JCR",
    baseName: subscriptionBaseName.JCR,
    isMonthlyPayment: true,
    isJCR: true,
  },
  MONTHLY_PROX: {
    isBilled: true,
    paymentTypeName: "MONTHLY_PROX",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isMonthlyPayment: true,
    isCloudProX: true,
    hasXray: true,
  },
  PRE_PAID: {
    isBilled: true,
    paymentTypeName: "PRE_PAID",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isPrepaidPayment: true,
    isCloudPro: true,
  },
  PRE_PAID_PROX: {
    isBilled: true,
    paymentTypeName: "PRE_PAID_PROX",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isPrepaidPayment: true,
    isCloudProX: true,
    hasXray: true,
  },
  DEDICATED: {
    isBilled: true,
    paymentTypeName: "DEDICATED",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isEnterprise: true,
    isPrepaidPayment: true,
  },
  DEDICATED_X: {
    isBilled: true,
    paymentTypeName: "DEDICATED_X",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isEnterprise: true,
    isEnterpriseX: true,
    isPrepaidPayment: true,
    hasXray: true,
  },
  INTERNAL: {
    paymentTypeName: "INTERNAL",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isInternal: true,
    isCloudPro: true,
  },
  INTERNAL_PROX: {
    paymentTypeName: "INTERNAL_PROX",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isInternal: true,
    isCloudProX: true,
    hasXray: true,
  },
  OSS_PROJECT: {
    paymentTypeName: "OSS",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isOSS: true,
    isCloudPro: true,
  },
  TRIAL_OSS_PROJECT: {
    paymentTypeName: "TRIAL_OSS",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isOSS: true,
    isTrial: true,
    isCloudPro: true,
  },
  TRIAL: {
    paymentTypeName: "TRIAL",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isTrial: true,
    isCloudPro: true,
  },
  AWS: {
    paymentTypeName: "AWS",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isAWSMP: true,
    isMP: true,
    isCloudPro: true,
  },
  AWS_PRO_ENT: {
    paymentTypeName: "AWS_PRO_ENT",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isEnterpriseTeam: true,
    isAWSMP: true,
    isMP: true,
    isEnterprise: true,
    hasXray: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AWS_PROX: {
    paymentTypeName: "AWS_PROX",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isAWSMP: true,
    isMP: true,
    isCloudProX: true,
    hasXray: true,
  },
  AWS_PROX_ENTX: {
    paymentTypeName: "AWS_PROX_ENTX",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isEnterpriseTeam: true,
    isAWSMP: true,
    isMP: true,
    isEnterpriseX: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AWS_PRO_TEAM: {
    paymentTypeName: "AWS_PRO_TEAM",
    baseName: subscriptionBaseName.PRO,
    isProTeam: true,
    isAWSMP: true,
    isMP: true,
    hasXray: true,
  },
  TRIAL_AWS_PRO_TEAM: {
    paymentTypeName: "TRIAL_AWS_PRO_TEAM",
    baseName: subscriptionBaseName.PRO,
    isProTeam: true,
    isAWSMP: true,
    isMP: true,
    isTrial: true,
    hasXray: true,
  },
  AWS_JCR: {
    paymentTypeName: "AWS_JCR",
    baseName: subscriptionBaseName.JCR,
    isAWSMP: true,
    isMP: true,
    isJCR: true,
  },
  GCP_PROX: {
    paymentTypeName: "GCP_PROX",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isGCPMP: true,
    isMP: true,
    isCloudProX: true,
    hasXray: true,
  },
  GCP_PROX_ENTX: {
    paymentTypeName: "GCP_PROX_ENTX",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isEnterpriseTeam: true,
    isGCPMP: true,
    isMP: true,
    isEnterpriseX: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  GCP_PRO_TEAM: {
    paymentTypeName: "GCP_PRO_TEAM",
    baseName: subscriptionBaseName.PRO,
    isProTeam: true,
    isGCPMP: true,
    isMP: true,
    hasXray: true,
  },
  TRIAL_GCP_PRO_TEAM: {
    paymentTypeName: "TRIAL_GCP_PRO_TEAM",
    baseName: subscriptionBaseName.PRO,
    isProTeam: true,
    isGCPMP: true,
    isMP: true,
    isTrial: true,
    hasXray: true,
  },
  GCP_ENTERPRISE: {
    paymentTypeName: "GCP_ENTERPRISE",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isGCPMP: true,
    isMP: true,
    isEnterprise: true,
    hasXray: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  GCP_ENTERPRISE_X: {
    paymentTypeName: "GCP_ENTERPRISE_X",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isGCPMP: true,
    isMP: true,
    isEnterpriseX: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  GCP_ENTERPRISE_PLUS: {
    paymentTypeName: "GCP_E+",
    baseName: subscriptionBaseName.ENTERPRISE_PLUS,
    isGCPMP: true,
    isMP: true,
    isEnterprisePlus: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AZURE_PROX: {
    paymentTypeName: "AZURE_PROX",
    baseName: subscriptionBaseName.CLOUD_PRO,
    isAzureMP: true,
    isMP: true,
    isCloudProX: true,
    hasXray: true,
  },
  AZURE_PROX_ENTX: {
    paymentTypeName: "AZURE_PROX_ENTX",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isEnterpriseTeam: true,
    isAzureMP: true,
    isMP: true,
    isEnterpriseX: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AZURE_PRO_TEAM: {
    paymentTypeName: "AZURE_PRO_TEAM",
    baseName: subscriptionBaseName.PRO,
    isProTeam: true,
    isAzureMP: true,
    isMP: true,
    hasXray: true,
  },
  AZURE_ENTERPRISE: {
    paymentTypeName: "AZURE_ENTERPRISE",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isAzureMP: true,
    isMP: true,
    hasXray: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AZURE_ENTERPRISE_X: {
    paymentTypeName: "AZURE_ENTERPRISE_X",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isAzureMP: true,
    isMP: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AZURE_ENTERPRISE_PLUS: {
    paymentTypeName: "AZURE_E+",
    baseName: subscriptionBaseName.ENTERPRISE_PLUS,
    isAzureMP: true,
    isMP: true,
    isEnterprisePlus: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  ENTERPRISE_PLUS: {
    isBilled: true,
    paymentTypeName: "E+",
    baseName: subscriptionBaseName.ENTERPRISE_PLUS,
    isEnterprisePlus: true,
    isPrepaidPayment: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  TRIAL_ENTERPRISE_PLUS: {
    paymentTypeName: "TRIAL_E+",
    baseName: subscriptionBaseName.ENTERPRISE_PLUS,
    isEnterprisePlus: true,
    isTrial: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  TRIAL_ENTERPRISE: {
    paymentTypeName: "TRIAL_ENTERPRISE",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isEnterpriseTeam: true,
    isTrial: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  TRIAL_AWS_ENTERPRISE_X: {
    paymentTypeName: "TRIAL_AWS_ENT_X",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isAWSMP: true,
    isMP: true,
    isEnterpriseX: true,
    isTrial: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AWS_ENTERPRISE_PLUS: {
    paymentTypeName: "AWS_E+",
    baseName: subscriptionBaseName.ENTERPRISE_PLUS,
    isAWSMP: true,
    isMP: true,
    isEnterprisePlus: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AWS_ENTERPRISE_X: {
    paymentTypeName: "AWS_ENT_X",
    baseName: subscriptionBaseName.CLOUD_ENTERPRISE,
    isAWSMP: true,
    isMP: true,
    isEnterpriseX: true,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
    isMultiJpdsSupportedForSubscription: true,
  },
  AWS_ENTERPRISE: {
    paymentTypeName: "AWS_ENTERPRISE",
    baseName: subscriptionBaseName.ENTERPRISE,
    isEnterpriseTeam: true,
    isAWSMP: true,
    isMP: true,
    hasXray: true,
    isMultiJpdsSupportedForSubscription: true,
  },
};
