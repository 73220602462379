import { type SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import {
  renderDisplayName,
  trialSuffixStr,
} from "@shared/services/metas/subscriptionMetas";
import { selfHostedMetasHardCoded } from "@shared/services/metas/selfHostedMetasHardCoded";
import dateUtils from "@shared/utils/date-utils";

export const buildSelfhostedMetas = (
  subscription: SubscriptionDTO,
): SubscriptionDTO["meta"] | null => {
  const metas = selfHostedMetasHardCoded(subscription);
  if (!metas) {
    return null;
  }

  const isTrial = subscription.bom.trial;

  return {
    ...metas,
    isSelfHosted: true,
    isTrial,
    isExpired: dateUtils.isTimestampExpired(+subscription.expirationDate),
    displayName: renderDisplayName(
      metas.paymentTypeName,
      computeSelfHostedDisplayName(subscription, metas),
    ),
  };
};

const computeSelfHostedDisplayName = (
  subscription: SubscriptionDTO,
  meta: SubscriptionDTO["meta"],
) => {
  const baseName = meta.baseName;
  const xSuffix = meta.hasXray && !meta.isEnterprisePlus ? "X" : "";
  const trialSuffix = subscription.bom.trial ? trialSuffixStr : "";

  return [baseName, xSuffix, trialSuffix].filter((e) => !!e).join(" ");
};
