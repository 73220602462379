import dashboard from "@shared/services/tour/dashboard.ts";
import billing from "@shared/services/tour/billing.ts";
import security from "@shared/services/tour/security.ts";
import settings from "@shared/services/tour/settings.ts";
import supportContacts from "@shared/services/tour/support-contacts.ts";
import user from "@shared/services/tour/user.ts";
import notificationsCenter from "@shared/services/tour/notifications-center.ts";
import usageExposer from "@shared/services/tour/usageExposer.ts";

export const MY_JFROG_APIS = {
  ...billing,
  ...dashboard,
  ...usageExposer,
  ...security,
  ...settings,
  ...supportContacts,
  ...user,
  ...notificationsCenter,
};
