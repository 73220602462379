import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { type RequestCurationTrialRequest } from "@jfrog-ba/myjfrog-common";
import { cookiesService } from "@shared/services/cookies";
import { subscriptionsService } from "@shared/services/subscriptions";
import { messagesService } from "@shared/services/messages";
import logger from "@shared/utils/logger.ts";
import { useNotifications } from "@shared/module-transformers/useNotifications.ts";

export const requestCurationTrial = async () => {
  try {
    const currentSubscription = useSubscriptionsStore().getCurrentSubscription;
    if (!currentSubscription) {
      return;
    }
    const serverName =
      (currentSubscription.meta.isSaas &&
        subscriptionsService.extractMainSaasServer(currentSubscription)
          .serverName) ||
      null;
    const request: RequestCurationTrialRequest = {
      marketoCookie: cookiesService.marketoCookie(),
      serverName,
      accountNumber: currentSubscription.accountNumber,
      isSh: currentSubscription.meta.isSelfHosted,
    };

    await subscriptionsService.requestCurationTrial(request);
    useNotifications().success(messagesService.curation_trial_request_success);
  } catch (e) {
    logger.error(e);
    useNotifications().error(messagesService.app_something_went_wrong);
  }
};
