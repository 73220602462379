import apiProxy from "@shared/utils/api-proxy";
import { Device } from "@capacitor/device";
import {
  RegisterMobileTokenRequest,
  UnregisterMobileTokenRequest,
} from "@jfrog-ba/myjfrog-common";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import { usePushNotificationsPermissionStore } from "@shared/stores/pushNotificationsPermissionStore.ts";

export const notificationTokenService = {
  requestPushNotificationPermission: async () => {
    const pushNotificationsPermissionStore =
      usePushNotificationsPermissionStore();

    if (pushNotificationsPermissionStore.permissionIsChecked) {
      return;
    }

    await collectPushNotificationPermissions();
    await registerPushNotificationToken();
    pushNotificationsPermissionStore.setPermissionIsChecked(true);
  },
  registerNotificationToken: async (request: RegisterMobileTokenRequest) => {
    await apiProxy.post(`notification-token/register`, request);
  },
  unregisterNotificationToken: async (
    request: UnregisterMobileTokenRequest,
  ) => {
    await apiProxy.post(`notification-token/unregister`, request);
    await FirebaseMessaging.deleteToken();
  },
};

const collectPushNotificationPermissions = async () => {
  const pushNotificationsPermissionStore =
    usePushNotificationsPermissionStore();

  if (!pushNotificationsPermissionStore.canAskPermission) {
    return;
  }

  const resultPermissions = await FirebaseMessaging.requestPermissions();
  const permissionState = resultPermissions.receive;
  pushNotificationsPermissionStore.setPermissionState(permissionState);
};

const registerPushNotificationToken = async () => {
  const pushNotificationsPermissionStore =
    usePushNotificationsPermissionStore();

  const permissionState = pushNotificationsPermissionStore.permissionState;

  if (permissionState !== "granted") {
    return;
  }

  const deviceInfo = await Device.getInfo();
  if (deviceInfo.isVirtual) {
    return;
  }

  const isWeb = Capacitor.getPlatform() === "web";
  if (isWeb) {
    return;
  }

  const { token } = await FirebaseMessaging.getToken({});
  pushNotificationsPermissionStore.setToken(token);

  await notificationTokenService.registerNotificationToken({
    token,
    platform: Capacitor.getPlatform(),
  });
};
