import { defineStore } from "pinia";
import { SupportTicket } from "@shared/types/localTypes/supportTickets.ts";

interface SupportTicketsState {
  _supportTickets: SupportTicket[];
  _isLoading: boolean;
}
export const useSupportTicketsStore = defineStore({
  id: "supportTickets",
  state: (): SupportTicketsState => ({
    _supportTickets: [],
    _isLoading: true,
  }),
  getters: {
    supportTickets: (state) => state._supportTickets,
    isLoading: (state) => state._isLoading,
  },
  actions: {
    setSupportTickets(supportTickets: SupportTicketsState["_supportTickets"]) {
      this._supportTickets = supportTickets;
    },
    clearSupportTickets() {
      this._supportTickets = [];
      this._isLoading = true;
    },
    setIsLoading(isLoading: boolean) {
      this._isLoading = isLoading;
    },
  },
});
