import {
  NOTIFICATIONS_HISTORY_SUBSCRIPTION_SENT_GET,
  USERPREF_GET_NOTIFICATIONS_BY_SUBSCRIPTION_NUMBERS,
} from "@shared/services/tour/urls/dashboard-urls.ts";
import notificationsHistorySubscriptionSentGet from "@shared/services/tour/mockedApis/notificationsHistorySubscriptionSentGet.ts";
import getNotificationsBySubscriptionNumbers from "@shared/services/tour/mockedApis/getNotificationsBySubscriptionNumbers.ts";

export default {
  [NOTIFICATIONS_HISTORY_SUBSCRIPTION_SENT_GET]: () =>
    notificationsHistorySubscriptionSentGet(),
  [USERPREF_GET_NOTIFICATIONS_BY_SUBSCRIPTION_NUMBERS]: () =>
    getNotificationsBySubscriptionNumbers(),
};
