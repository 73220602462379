const json = import("../json/subscriptionsGetServersMetaData.json");

type ServerNames =
  | "demo"
  | "demonetherlands"
  | "demohongkong"
  | "demoedgefr"
  | "demoedgeaust";

export default async (data?: object) => {
  const technicalServerNames = (
    data as {
      technicalServerNames: ServerNames[];
    }
  ).technicalServerNames;

  const response = (await json).default;
  let currentResponse;
  technicalServerNames.forEach((serverName) => {
    currentResponse = {
      [serverName]: {} as (typeof response)[ServerNames],
    };

    currentResponse[serverName] = response[serverName];
  });

  return JSON.parse(JSON.stringify(currentResponse));
};
