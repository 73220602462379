/* eslint-disable @typescript-eslint/no-explicit-any */
import { remoteErrorLogger } from "@shared/services/remoteErrorLogger";

export interface Logger {
  silly: (...args: unknown[]) => void;
  debug: (...args: unknown[]) => void;
  verbose: (...args: unknown[]) => void;
  info: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
  newWithPrefix: (prefix: string) => Logger;
}

interface LevelInfo {
  displayName: string;
  colorCode: string;
}

enum Level {
  SILLY,
  DEBUG,
  VERBOSE,
  INFO,
  WARN,
  ERROR,
}

const LevelsInfos: { [key: number]: LevelInfo } = {
  [Level.SILLY]: { displayName: "SILLY", colorCode: "purple" },
  [Level.DEBUG]: { displayName: "DEBUG", colorCode: "green" },
  [Level.VERBOSE]: { displayName: "VERBO", colorCode: "magenta" },
  [Level.INFO]: { displayName: "INFO ", colorCode: "blue" },
  [Level.WARN]: { displayName: "WARN ", colorCode: "#ff8d00" },
  [Level.ERROR]: { displayName: "ERROR", colorCode: "red" },
};

const sanitizeArgs = (...args: any[]): any[] => {
  return args.map((arg) => {
    if (arg.config) arg.config = "*****";
    if (arg.request) arg.request = "*****";
    if (arg.response) arg.response = "*****";
    return arg;
  });
};

const formatDate = (date: Date): string => {
  const dateStr = date.toISOString().slice(0, -1);
  return dateStr.substring(0, 10) + " " + dateStr.substring(11);
};

const date = (): string => formatDate(new Date());

const loggerWithDateAndLevel = (
  level: Level,
): ((...messages: unknown[]) => void) => {
  return (...messages): void => {
    try {
      const levelInfo = LevelsInfos[level];
      let logMessage = `${date()} %c[${levelInfo.displayName}]%c`;
      if (messages.length && typeof messages[0] === "string") {
        logMessage += ` ${messages[0]}`;
        messages.shift();
      }

      const argsArray: any[] = [
        logMessage,
        `color:${levelInfo.colorCode};`,
        "",
      ];
      if (messages.length) {
        argsArray.push(sanitizeArgs(...messages));
      }
      console.log.apply(null, argsArray);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(...messages);
    }
  };
};

const loggerCreator = (prefixes = ""): Logger => ({
  silly: (...args: unknown[]): void =>
    loggerWithDateAndLevel(Level.SILLY)(
      ...(prefixes ? [prefixes] : []),
      ...args,
    ),
  debug: (...args: unknown[]): void =>
    loggerWithDateAndLevel(Level.DEBUG)(
      ...(prefixes ? [prefixes] : []),
      ...args,
    ),
  verbose: (...args: unknown[]): void =>
    loggerWithDateAndLevel(Level.VERBOSE)(
      ...(prefixes ? [prefixes] : []),
      ...args,
    ),
  info: (...args: unknown[]): void =>
    loggerWithDateAndLevel(Level.INFO)(
      ...(prefixes ? [prefixes] : []),
      ...args,
    ),
  warn: (...args: unknown[]): void =>
    loggerWithDateAndLevel(Level.WARN)(
      ...(prefixes ? [prefixes] : []),
      ...args,
    ),
  error: (...args: unknown[]): void => {
    loggerWithDateAndLevel(Level.ERROR)(
      ...(prefixes ? [prefixes] : []),
      ...args,
    );

    const message: string = args.map((arg) => arg as string).join();
    remoteErrorLogger.logError(message);
  },

  newWithPrefix: (prefix: string): Logger =>
    loggerCreator(`${prefixes}[${prefix}]`),
});

export default loggerCreator();
