import { type NavigationGuard } from "vue-router";
import { useAlertMessagesStore } from "@shared/stores/alertMessages";
import { RouteNames } from "@shared/types/localTypes/router";
import appWindow from "@shared/utils/app-window";
import {
  ensureStartingSlashExistence,
  ensureTrailingSlashExistence,
} from "@shared/utils/tools";
import { maintenanceStatus } from "@shared/services/maintenanceStatus.ts";
import logger from "@shared/utils/logger.ts";

export const loginBeforeUpgrade: NavigationGuard = async (to, from, next) => {
  await useAlertMessagesStore().setMessages({
    title: "",
    description:
      "Please log in below in order to complete your upgrade request.",
    type: "info",
  });
  return next({ name: RouteNames.Login });
};

export const toLandingAppWithFullPath: NavigationGuard = async (to) => {
  logger.warn(`Handling  Landing redirection for path : '${to.fullPath}'`);
  location.href =
    ensureTrailingSlashExistence(appWindow.LANDING_APP_URL, false) +
    ensureStartingSlashExistence(to.fullPath, true);
};

export const checkIfMaintenancePageAccessible: NavigationGuard = async (
  to,
  from,
  next,
) => {
  if (await maintenanceStatus.checkIsMyJfUnderMaintenance()) {
    return next();
  }
  return next({
    name: RouteNames.Login,
  });
};
