import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { watch } from "vue";
import { cnameService } from "@shared/services/cnameService.ts";
import { useDnsRoutingStore } from "@shared/stores/dnsRouting.ts";
import { useDnsRoutingHelper } from "@shared/use/dnsRoutingHelper.ts";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { useTokenGeneration } from "@shared/use/useTokenGeneration.ts";
import { featureFlagService } from "@shared/services/featureFlag.ts";
import { useCnameStore } from "@shared/stores/cname.ts";
import { useFeaturesStore } from "@shared/stores/features.ts";
import { useTokenGenerationStore } from "@shared/stores/tokenGeneration.ts";

export const useFeatureFlagHelper = () => {
  const subscriptionStore = useSubscriptionsStore();
  const dnsRoutingStore = useDnsRoutingStore();
  const cnameStore = useCnameStore();
  const featuresStore = useFeaturesStore();
  const tokenGenerationStore = useTokenGenerationStore();

  const dnsRoutingHelper = useDnsRoutingHelper();

  const loadDnsRoutingPolicies = async (
    currentSubscription: SubscriptionDTO,
  ) => {
    dnsRoutingStore.setIsLoadingPolicies(true);
    await dnsRoutingHelper.fetchRoutingPolicies(currentSubscription);
    dnsRoutingStore.setIsLoadingPolicies(false);
  };

  watch(
    () => subscriptionStore.getCurrentSubscription,
    (currentSubscription) => {
      if (!currentSubscription) {
        return;
      }
      featureFlagService.loadFeatureFlags({
        subscriptionNumber: currentSubscription.accountNumber,
      });

      cnameService.getSslList(currentSubscription.accountNumber);

      loadDnsRoutingPolicies(currentSubscription);

      useTokenGeneration().getApiToken(currentSubscription);
    },
    {
      immediate: true,
    },
  );

  watch(
    () => cnameStore.getSslList,
    (sslList) => {
      featuresStore.setIsCnameEnabled(!!sslList.length);
    },
  );

  watch(
    () => dnsRoutingStore.getPoliciesResponse,
    (policiesResponse) => {
      featuresStore.setIsDnsManualFailoverPolicyEnabled(
        policiesResponse.policies.some(
          (policy) => policy.policy_type === "manual_failover",
        ),
      );
    },
  );

  watch(
    () => tokenGenerationStore.getApiKey,
    (apiKey) => {
      featuresStore.setIsSaasTokenEnabled(!!apiKey.length);
    },
  );
};
