import { LocalStorageKeys } from "@shared/types/localTypes/storage";
import { type Theme, useThemeStore } from "@shared/stores/theme";

type SwitchThemeParams = Theme;
export default () => {
  const themeStore = useThemeStore();
  const switchTheme = (theme: SwitchThemeParams) => {
    window.localStorage.setItem(LocalStorageKeys.THEME, theme);
    themeStore.setActiveTheme(theme);

    const allThemes: Theme[] = ["light", "dark"];
    for (const theme of allThemes) {
      document.documentElement.classList.remove(theme);
    }

    document.documentElement.classList.add(theme);
    document.documentElement.style.colorScheme = theme;
  };

  return {
    switchTheme,
  };
};
