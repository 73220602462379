import { defineStore } from "pinia";

export type ActionPageName =
  | "NOTIFICATION_CENTER_CREATE_NOTIFICATION"
  | "NOTIFICATION_CENTER_EDIT_NOTIFICATION";

export type ActionPage = {
  name: ActionPageName;
  props?: Record<string, unknown>;
};
export interface ActionPagesStoreState {
  _currentActionPage: ActionPage | null;
  _changingRouteIsAllowed: boolean;
}

export const useActionPagesStore = defineStore({
  id: "action-pages",
  state: () =>
    ({
      _currentActionPage: null,
      _changingRouteIsAllowed: true,
    }) as ActionPagesStoreState,
  getters: {
    currentActionPage: (state) => state._currentActionPage,
    changingRouteIsAllowed: (state) => state._changingRouteIsAllowed,
  },
  actions: {
    setCurrentActionPage(actionPage: ActionPage) {
      this._currentActionPage = actionPage;
      this._changingRouteIsAllowed = false;
    },
    clearCurrentActionPage() {
      this._currentActionPage = null;
      this._changingRouteIsAllowed = true;
    },
    setChangingRouteIsAllowed(changingRouteIsAllowed: boolean) {
      this._changingRouteIsAllowed = changingRouteIsAllowed;
    },
  },
});
