import {
  LocalStorageCtrl,
  LocalStorageCtrlClear,
  LocalStorageCtrlGet,
  LocalStorageCtrlRemove,
  LocalStorageCtrlSet,
} from "@shared/types/localTypes/localStorage.ts";
import { useCookies } from "vue3-cookies";
import { cookiesService } from "@shared/services/cookies.ts";
import { LocalStorageKeys } from "@shared/types/localTypes/storage.ts";

// NOTE: [28/11/2023] [kevinz] -> Attention : this file is used in @shared as a module-transformers.
//  Don't remove it if you don't know exactly what you do!

export const useLocalStorage = <T>(): LocalStorageCtrl<T> => {
  const cookies = useCookies().cookies;
  const set: LocalStorageCtrlSet<T> = async (key, data) => {
    const secured = !import.meta.env.DEV;
    const isString = typeof data === "string";

    cookies.set(
      key,
      isString ? data : JSON.stringify(data),
      cookiesService.getExpiration(),
      "/",
      undefined,
      secured,
    );
  };
  const get: LocalStorageCtrlGet<T> = async (key) => {
    return cookies.get(key) as T;
  };

  const remove: LocalStorageCtrlRemove = async (key) => {
    cookies.remove(key);
  };
  const clear: LocalStorageCtrlClear = async (keysToKeep) => {
    cookies.keys().forEach((key) => {
      if (keysToKeep?.includes(key as LocalStorageKeys)) {
        return;
      }
      if (Object.values(LocalStorageKeys).includes(key as LocalStorageKeys)) {
        cookies.remove(key);
      }
    });
  };
  return { get, set, remove, clear };
};
