import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { useAuthStore } from "@shared/stores/auth";
import { rolesService } from "@shared/services/roles";
import { useVersionStore } from "@shared/stores/version";
import { type AppcuesEvents } from "@shared/types/global";
import { useEngine } from "@shared/use/useEngine.ts";
import { appcuesClientImpl } from "@shared/module-transformers/appcuesClientImpl.ts";
import { useGenVersionStore } from "@shared/stores/genVersion.ts";

export const appcuesService = {
  setSubscriptionProperties(): void {
    if (!appcuesClientIsAvailable()) {
      return;
    }
    const authStore = useAuthStore();
    if (!authStore.getUser) {
      return;
    }
    const subscriptionStore = useSubscriptionsStore();
    const versionStore = useVersionStore();
    const genVersionStore = useGenVersionStore();
    const role = rolesService.extractUserRoleForSubscription(
      subscriptionStore.getCurrentSubscriptionNumber,
    );

    appcuesClientImpl.identify(authStore.getUser.heapIdentity, {
      myjfrog_isInternalJfrogUser:
        authStore.getUser.email.includes("@jfrog.com"),
      myjfrog_subscriptionId:
        subscriptionStore.getCurrentSubscription?.accountNumber,
      myjfrog_subscriptionType:
        subscriptionStore.getCurrentSubscription?.paymentType,
      myjfrog_isTrialSubscription:
        subscriptionStore.getCurrentSubscription?.trial,
      myjfrog_hasBusinessSecurity:
        subscriptionStore.getCurrentSubscription?.hasBusinessSecurity,
      myjfrog_paymentType:
        subscriptionStore.getCurrentSubscription?.meta.paymentTypeName,
      myjfrog_isSaas: subscriptionStore.getCurrentSubscription?.meta.isSaas,
      myjfrog_isSelfHosted:
        subscriptionStore.getCurrentSubscription?.meta.isSelfHosted,
      myjfrog_role: role,
      myjfrog_ng: genVersionStore.isNewGen,
      myjfrog_engine: useEngine().engine,
      myjfrog_version: versionStore.version,
    });
  },
  setUserIdentityProperties(): void {
    if (!appcuesClientIsAvailable()) {
      return;
    }
    const authStore = useAuthStore();
    if (!authStore.getUser) {
      return;
    }

    appcuesClientImpl.identify(authStore.getUser.heapIdentity, {
      myjfrog_isInternalJfrogUser:
        authStore.getUser.email.includes("@jfrog.com"),
      myjfrog_engine: useEngine().engine,
    });
  },

  sendEvent(eventName: AppcuesEvents, payload?: Record<string, unknown>): void {
    if (!appcuesClientIsAvailable()) {
      return;
    }
    payload = payload || {};
    payload["myjfrog_engine"] = useEngine().engine;
    appcuesClientImpl.track(eventName, payload);
  },
};

const appcuesClientIsAvailable = () => {
  return (
    !!appcuesClientImpl &&
    !!appcuesClientImpl.identify &&
    !!appcuesClientImpl.track
  );
};
