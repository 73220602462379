import {
  ArchivedInAppNotificationsRequest,
  GetEventChannelResponse,
} from "@jfrog-ba/myjfrog-common";
import apiProxy from "@shared/utils/api-proxy";

export const inAppNotificationsService = {
  getEventChannel: async () => {
    const response: GetEventChannelResponse | null = await apiProxy.get(
      `in-app-notifications/event-channel`,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  loadNotifications: async (): Promise<void> => {
    // NOTE: [kevinz] -> No inApp notifications for now. Will be implemented in BA-13619 with new Alert Notification Panel
  },
  archive: async (request: ArchivedInAppNotificationsRequest) => {
    await apiProxy.put(`in-app-notifications/archive`, request);
  },
};
