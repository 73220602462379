import { onMounted } from "vue";
import { useVersionStore } from "@shared/stores/version.ts";
import { useEngine } from "@shared/use/useEngine.ts";
import { AppEngine } from "@shared/types/localtypes.ts";

export default () => {
  const versionStore = useVersionStore();

  onMounted(() => {
    const isMobile = useEngine().engine === AppEngine.MOBILE;
    const webVersion = import.meta.env.VUE_APP_VERSION;
    const mobileVersion = "1.3.5";

    const version = isMobile ? mobileVersion : webVersion;
    versionStore.setVersion(version);
  });
};
