import { usersService } from "@shared/services/user.ts";
import { useAuthStore } from "@shared/stores/auth.ts";
import { RouteNames } from "@shared/types/localTypes/router.ts";
import { useRouter } from "vue-router";

const router = useRouter();
export const getEmail = async (): Promise<string> => {
  await usersService.getOrFetchUser();
  const authStore = useAuthStore();

  if (!authStore.getUser) {
    await router.push({
      name: RouteNames.Root,
    });
    return "";
  }

  return authStore.getUser.email;
};
