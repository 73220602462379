import { defineStore } from "pinia";

export type TokenGenerationState = {
  apiKey: string;
  apiKeyExpiry: number | null;
  isTokenLoading: boolean;
};
export const useTokenGenerationStore = defineStore("tokenGeneration", {
  state: (): TokenGenerationState => ({
    apiKey: "",
    apiKeyExpiry: null,
    isTokenLoading: false,
  }),
  getters: {
    getApiKey: (tokenGenerationState: TokenGenerationState) => {
      return tokenGenerationState.apiKey;
    },
    getApiKeyExpiry: (tokenGenerationState: TokenGenerationState) => {
      return tokenGenerationState.apiKeyExpiry;
    },
    getIsTokenLoading: (tokenGenerationState: TokenGenerationState) => {
      return tokenGenerationState.isTokenLoading;
    },
  },
  actions: {
    setApiKey(apiKey: TokenGenerationState["apiKey"]) {
      this.apiKey = apiKey;
    },
    setApiKeyExpiry(apiKeyExpiry: TokenGenerationState["apiKeyExpiry"]) {
      this.apiKeyExpiry = apiKeyExpiry;
    },
    setSsTokenLoading(isTokenLoading: TokenGenerationState["isTokenLoading"]) {
      this.isTokenLoading = isTokenLoading;
    },
  },
});
