import type { RouteRecordRaw } from "vue-router";
import type { MjfRouteMeta } from "@shared/types/localTypes/router";
import { RouteNames } from "@shared/types/localTypes/router";
import {
  otpRequired,
  registrationTokenRequired,
  resetPasswordKeyRequired,
} from "@/router/guard/login";
import { ssoLogin, ssoLoginFailed } from "@/router/guard/sso";

export default {
  path: "/auth",
  name: RouteNames.Auth,
  redirect: { name: RouteNames.Login },
  meta: { isGuest: true } as MjfRouteMeta,
  component: () => import("@/views/login/LoginBase.vue"),
  children: [
    {
      path: "/login",
      name: RouteNames.Login,
      component: () => import("@/views/login/LoginView.vue"),
    },
    {
      path: "/forgot-password",
      name: RouteNames.ForgotPassword,
      component: () => import("@/views/login/ForgotPassword.vue"),
    },
    {
      path: "/reset-password",
      name: RouteNames.ResetPassword,
      component: () => import("@/views/login/ResetPassword.vue"),
      meta: { autoLogout: true } as MjfRouteMeta,
      beforeEnter: resetPasswordKeyRequired,
    },
    {
      path: "/register-now",
      name: RouteNames.RegisterNow,
      component: () => import("@/views/login/RegisterNow.vue"),
    },
    {
      path: "/registration",
      name: RouteNames.Registration,
      meta: { autoLogout: true, transitionName: "slide-page-to-left" },
      component: () => import("@/views/login/RegistrationChoice.vue"),
      beforeEnter: registrationTokenRequired,
    },
    {
      path: "/registration/password",
      name: RouteNames.RegistrationPassword,
      meta: { transitionName: "slide-page-to-left" },
      component: () => import("@/views/login/RegistrationFlow.vue"),
      beforeEnter: registrationTokenRequired,
    },
    {
      path: "/google-authenticator",
      name: RouteNames.GoogleAuthenticator,
      component: () => import("@/views/login/LoginAuthenticator.vue"),
      meta: { transitionName: "slide-page-to-left" },
      beforeEnter: otpRequired,
    },
    {
      path: "/sso-login",
      name: RouteNames.SSOLogin,
      beforeEnter: ssoLogin,
      meta: { autoLogout: true } as MjfRouteMeta,
    },
    {
      path: "/sso-login-failed",
      name: RouteNames.SSOLoginFailed,
      beforeEnter: ssoLoginFailed,
      meta: { autoLogout: true } as MjfRouteMeta,
    },
  ],
} as RouteRecordRaw;
