import { onMounted } from "vue";
import { LocalStorageKeys } from "@shared/types/localTypes/storage";
import { type GenVersion } from "@shared/stores/genVersion.ts";
import useGenVersionSwitcher from "@/use/useGenVersionSwitcher.ts";

export default () => {
  const genVersionSwitcher = useGenVersionSwitcher();
  onMounted(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const genVersionStored = window.localStorage.getItem(
      LocalStorageKeys.GEN_VERSION,
    ) as GenVersion | null;

    let forcedGenVersion: GenVersion | null = null;
    const url = window.location.href;
    const hasQueryParameters = url.includes("?");

    if (hasQueryParameters) {
      const urlSearchParams = new URLSearchParams(url.split("?")[1]);
      if (urlSearchParams.has("beta")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        forcedGenVersion =
          urlSearchParams.get("beta") === "1" ? "new-gen" : "current-gen";
      }
    }

    // NOTE: [28/11/2023] [kevinz] -> We want to keep the logic for next beta if there is, so we simply
    //  ignore the forced/stored gen-version for now and we load only the "new-gen"
    // genVersionSwitcher.setVersion(forcedGenVersion || genVersionStored || "current-gen");
    genVersionSwitcher.setGenVersion("new-gen");
  });
};
