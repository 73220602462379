import { GetRepositoriesTrafficForPeriodV2Response } from "@shared/services/usageExposer/getRepositoriesTrafficForPeriod.ts";
import { calculateValue } from "@shared/services/tour/mockedApis/usageExposer/helper.ts";

const json = import(
  "../../json/usageExposer/dashboard/usageExposerGetRepositoriesTrafficForPeriod.json"
);

const updateUsage = <T extends GetRepositoriesTrafficForPeriodV2Response>(
  usageExposerStatisticsV2Response: T,
  updateCallback: (value: number) => number,
): T => {
  usageExposerStatisticsV2Response.repositories.forEach((server) => {
    server.trafficBytes = updateCallback(server.trafficBytes);
  });

  return usageExposerStatisticsV2Response;
};

export default async () =>
  updateUsage(JSON.parse(JSON.stringify(await json)), calculateValue);
