import { type EventLoggerRequest } from "@jfrog-ba/myjfrog-common";
import apiProxy from "@shared/utils/api-proxy";
import logger from "@shared/utils/logger.ts";

export const eventLogger = {
  createNewEvent: async (request: EventLoggerRequest) => {
    try {
      await apiProxy.post(`event-logger/event`, request);
    } catch (e) {
      logger.error(
        "ERROR logging event with request:" + JSON.stringify(request),
        e,
      );
    }
  },
};
