import { app } from "./main-app";
import { VueFire, VueFireAuth } from "vuefire";
import { initializeApp } from "firebase/app";
import window from "@shared/utils/app-window.ts";

const firebaseConfig = {
  apiKey: window.FIREBASE_API_KEY,
  authDomain: window.FIREBASE_AUTH_DOMAIN,
  projectId: window.FIREBASE_PROJECT_ID,
  storageBucket: window.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window.FIREBASE_MESSAGING_SENDER_ID,
  appId: window.FIREBASE_APP_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()],
});
