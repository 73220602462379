import { defineStore } from "pinia";
import type {
  WhiteListIPSResponse,
  ReadServerIPMetaResponse,
  GeoIpCountries,
} from "@jfrog-ba/myjfrog-common";
import { JpuDTO } from "@jfrog-ba/myjfrog-common";

interface ServerMetaDataStoreState {
  serverMetaData: ReadServerIPMetaResponse;
  isLoadingServerMetaData: boolean;
}

export const useServerMetaDataStore = defineStore("serverMetaData", {
  state: (): ServerMetaDataStoreState => ({
    serverMetaData: {},
    isLoadingServerMetaData: false,
  }),
  getters: {
    getServerMetaData: (serverMetaDataStoreState: ServerMetaDataStoreState) => {
      return serverMetaDataStoreState.serverMetaData;
    },
  },
  actions: {
    setServerMetaData(
      serverMetaData: ServerMetaDataStoreState["serverMetaData"],
    ) {
      this.serverMetaData = serverMetaData;
    },
    setIsLoadingServerMetaDataRequest(isLoadingServerMetaData: boolean) {
      this.isLoadingServerMetaData = isLoadingServerMetaData;
    },
    setWhitelistOfSpecificServer(
      technicalServerName: JpuDTO["technicalServerName"],
      newWhitelist: WhiteListIPSResponse,
    ) {
      if (!this.serverMetaData[technicalServerName]) {
        return;
      }
      this.serverMetaData[technicalServerName].whitelistIPMeta = newWhitelist;
    },
    setGeoRestrictionsOfSpecificServer(
      technicalServerName: JpuDTO["technicalServerName"],
      newGeoRestrictions: GeoIpCountries,
    ) {
      if (!this.serverMetaData[technicalServerName]) {
        return;
      }
      this.serverMetaData[technicalServerName].geoIPMeta = newGeoRestrictions;
    },
  },
});
