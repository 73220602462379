import { AppcuesMethods } from "@shared/types/global.ts";
import window from "@shared/utils/app-window.ts";

export const useAppcues: () => AppcuesMethods = () => {
  const identify: AppcuesMethods["identify"] = (userId, options) => {
    if (!window.Appcues) {
      return;
    }
    window.Appcues.identify(userId, options);
  };

  const track: AppcuesMethods["track"] = (eventName, payload) => {
    if (!window.Appcues) {
      return;
    }
    window.Appcues.track(eventName, payload);
  };

  return { identify, track };
};
