import { SslCertificateObject } from "@jfrog-ba/myjfrog-common";
import { defineStore } from "pinia";

interface CnameState {
  sslList: SslCertificateObject[];
}

export const useCnameStore = defineStore("cname", {
  state: (): CnameState => ({
    sslList: [],
  }),
  getters: {
    getSslList: (cnameState: CnameState) => {
      return cnameState.sslList;
    },
  },
  actions: {
    setSslList(sslList: CnameState["sslList"]) {
      this.sslList = sslList;
    },
    pushToSslList(sslItem: SslCertificateObject) {
      this.sslList.push(sslItem);
    },
    updateSslItemByIndex(
      sslItem: SslCertificateObject,
      indexOfSslToUpdate: number,
    ) {
      this.sslList[indexOfSslToUpdate] = sslItem;
    },
  },
});
