import { type NavigationGuard } from "vue-router";
import { subscriptionsService } from "@shared/services/subscriptions";
import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { RouteNames } from "@shared/types/localTypes/router";
import { jpdUtils } from "@shared/utils/jpd-utils";
import { userGrantedToReachRoute } from "@/router/guard/security";
import { useBetaChecker } from "@/use/useBetaChecker";
import { useUpgradeStore } from "@shared/stores/upgrade";

export const checkForMultiSubscription: NavigationGuard = async (
  to,
  from,
  next,
) => {
  await subscriptionsService.loadInitialData();
  const subscriptions = useSubscriptionsStore().getSubscriptions;
  if (!subscriptions || subscriptions.length > 1) {
    return next();
  }
  return next({
    name: RouteNames.Dashboard,
    params: { subscriptionId: subscriptions[0].accountNumber },
  });
};

export const canAccessSubscription: NavigationGuard = async (
  to,
  from,
  next,
) => {
  await subscriptionsService.loadInitialData();
  const subscriptions = useSubscriptionsStore().getSubscriptions;
  if (!subscriptions) {
    throw new Error("unable to get subscriptions");
  }
  const targetSubscriptionId = to.params.subscriptionId as string;
  const subscriptionIdExists = subscriptions.some(
    (s) => s.accountNumber === parseInt(targetSubscriptionId),
  );
  if (!subscriptionIdExists) {
    return next({ name: RouteNames.Root });
  }
  if (!userGrantedToReachRoute(to)) {
    return next({
      name: RouteNames.Dashboard,
      params: {
        subscriptionId: to.params.subscriptionId,
      },
    });
  }
  return next();
};

export const setCurrentSubscription: NavigationGuard = async (
  to,
  from,
  next,
) => {
  const targetSubscriptionId = to.params.subscriptionId as string;
  await useSubscriptionsStore().setCurrentSubscription(targetSubscriptionId);
  return next();
};

export const disableBetaIfNeeded: NavigationGuard = async (to, from, next) => {
  await useBetaChecker().disableBetaIfNeeded();
  return next();
};

export const redirectToSecurityView: NavigationGuard = async (
  to,
  from,
  next,
) => {
  const subscription = await useSubscriptionsStore().getCurrentSubscription;
  if (!subscription) {
    throw new Error("Subscriptions should be loaded.");
  }
  if (useSubscriptionsStore().isMultiJpdsAccessible) {
    return next();
  }

  return next({
    name: RouteNames.SecurityJpd,
    params: {
      serverName: jpdUtils.getSingleAccessibleJpdName(),
      subscriptionId: subscription.accountNumber,
    },
  });
};

export const redirectNonAccessibleJpd: NavigationGuard = async (
  to,
  from,
  next,
) => {
  const subscription = await useSubscriptionsStore().getCurrentSubscription;
  const requestedServerName = to.params.serverName;
  const jpdItem = subscription?.jpus.find(
    (jpdItem) => jpdItem.serverName === requestedServerName,
  );

  if (
    !jpdItem ||
    jpdUtils.isJpdNotAccessible(jpdItem) ||
    jpdItem.status === "DELETED"
  ) {
    return next({
      name: RouteNames.Dashboard,
      params: {
        subscriptionId: subscription?.accountNumber,
      },
    });
  }
  return next();
};

export const beforeEnterQuickUpgrade: NavigationGuard = async (
  to,
  from,
  next,
) => {
  await useUpgradeStore().setIsQuickUpgrade(true);
  return next();
};
