import { useInAppNotificationsStore } from "@shared/stores/inAppNotificationsStore.ts";
import { inAppNotificationsService } from "@shared/services/inAppNotifications.ts";
import logger from "@shared/utils/logger";
import { computed } from "vue";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { InAppNotification } from "@jfrog-ba/myjfrog-common";

export default () => {
  const inAppNotificationsStore = useInAppNotificationsStore();
  const subscriptionsStore = useSubscriptionsStore();
  const startInAppNotificationListening = async (loggedInEmail: string) => {
    try {
      const eventChannel = await inAppNotificationsService.getEventChannel();

      if (eventChannel.email !== loggedInEmail) {
        throw new Error(
          `received eventChannel '${eventChannel.channel}' is not matching the requested email '${loggedInEmail}'`,
        );
      }

      inAppNotificationsStore.setEventChannel(eventChannel.channel);
      // here we should listen to websocket ? I think this method should just load the event-channel and the listening should be more global, like for push notification
    } catch (e) {
      // no need to display notification toast here
      logger.error(e);
    }
  };
  const stopInAppNotificationListening = async () => {
    try {
      const eventChannel = inAppNotificationsStore.eventChannel;
      if (!eventChannel) {
        return;
      }
      // here we should stop to listen to websocket
    } catch (e) {
      // no need to display notification toast here
      logger.error(e);
    }
  };

  const archiveNotifications = async (
    notifications: InAppNotification[],
    animationPromise?: () => Promise<void>,
  ) => {
    try {
      inAppNotificationsService.archive({
        uuids: notifications.map((notification) => notification.uuid),
      });
      if (animationPromise) {
        await animationPromise();
      }
      inAppNotificationsStore.removeInAppNotifications(notifications);
    } catch (e) {
      // no need to display notification toast here
      logger.error(e);
    }
  };

  const currentSubscriptionNotifications = computed<InAppNotification[]>(() => {
    const currentSubscription = subscriptionsStore.getCurrentSubscription;

    if (!currentSubscription) {
      return [];
    }

    const relevantNotifications =
      inAppNotificationsStore.inAppNotificationsPerSubscription?.find(
        (item) =>
          Number(item.subscriptionNumber) === currentSubscription.accountNumber,
      );

    return relevantNotifications?.inAppNotifications || [];
  });

  const hasNotifications = computed(
    () => !!currentSubscriptionNotifications.value.length,
  );

  return {
    startInAppNotificationListening,
    stopInAppNotificationListening,
    currentSubscriptionNotifications,
    hasNotifications,
    archiveNotifications,
  };
};
