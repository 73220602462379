import { useAuthStore } from "@shared/stores/auth";
import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { type UserRole } from "@jfrog-ba/myjfrog-common";
import { capitalizeWords } from "@shared/utils/tools";
import { subscriptionsService } from "@shared/services/subscriptions";

export const rolesService = {
  extractUserRoleForSubscription(
    subscriptionNumber: string,
  ): UserRole | undefined {
    const currentUserMail = useAuthStore().getUser?.email || null;
    return rolesService.extractUserRoleForSubscriptionAndEmail(
      currentUserMail,
      subscriptionNumber,
    );
  },
  extractUserRoleForSubscriptionAndEmail(
    email: string | null,
    subscriptionNumber: string,
  ): UserRole | undefined {
    return subscriptionsService
      .findCachedSubscription(subscriptionNumber)
      ?.owners?.find((owner) => owner.email === email)?.role;
  },
  getDisplayName(role?: UserRole): string {
    if (
      role === "MYJFROG_PLATFORM_ADMIN" ||
      role === "MYJFROG_ADMIN_READONLY"
    ) {
      return "Read-Only";
    }
    return (
      (role &&
        capitalizeWords(
          role.split("_").filter((word) => word.toLowerCase() !== "myjfrog"),
        )) ||
      "Unknown"
    );
  },

  isUserInRoleForCurrentSubscription(roles: UserRole[]) {
    return rolesService.isUserInRole(
      useSubscriptionsStore().getCurrentSubscriptionNumber,
      roles,
    );
  },

  isUserInRole(subscriptionNumber: string, roles: UserRole[]) {
    const userRole =
      rolesService.extractUserRoleForSubscription(subscriptionNumber);
    if (!userRole) {
      return false;
    }
    return roles.includes(userRole);
  },
};
