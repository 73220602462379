import apiProxy from "@shared/utils/api-proxy";
import { FeatureFlagsResponse } from "@shared/types/localTypes/feature-flags.ts";
import { useFeaturesStore } from "@shared/stores/features.ts";

export const featureFlagService = {
  loadFeatureFlags: async (args: { subscriptionNumber: number }) => {
    const { subscriptionNumber } = args;

    const response: FeatureFlagsResponse | null = await apiProxy.get(
      `feature-flags/subscription/${subscriptionNumber}/load`,
    );

    if (!response) {
      throw new Error("Could not load feature flags");
    }

    const featuresStore = useFeaturesStore();
    featuresStore.setIsCnameEnabled(response.ssl_certificates);
    featuresStore.setIsSaasTokenEnabled(response.saas_token);
    featuresStore.setIsDnsManualFailoverPolicyEnabled(
      response.dns_manual_failover,
    );
  },
};
