import {
  type JpuDTO,
  type JPUStatus,
  type SaasPaymentType,
  type SubscriptionDTO,
} from "@jfrog-ba/myjfrog-common";
import {
  renderDisplayName,
  trialSuffixStr,
} from "@shared/services/metas/subscriptionMetas";
import { saasMetasHardCoded } from "@shared/services/metas/saasMetasHardCoded";
import { subscriptionBaseName } from "@shared/services/metas/subscriptionBaseName";
import dateUtils from "@shared/utils/date-utils";
import { DateTime } from "luxon";

export const buildSaasMetas = (
  subscription: SubscriptionDTO,
): SubscriptionDTO["meta"] | null => {
  const metas = saasMetasHardCoded[subscription.paymentType as SaasPaymentType];
  if (!metas) {
    return null;
  }
  const jpus = subscription.jpus;
  const hasActiveTrialXraySaasProduct = trialSaasProductFilter(
    jpus,
    "xray",
    activeStatuses,
    true,
  );
  const hasActiveCommercialXraySaasProduct = commercialSaasProductFilter(
    jpus,
    "xray",
    activeStatuses,
  );
  const hasXray =
    metas.hasXray ||
    hasActiveCommercialXraySaasProduct ||
    hasActiveTrialXraySaasProduct;

  if (metas.isMP && !metas.isTrial) {
    metas.isMonthlyPayment = isMonthlyMp(subscription);
    metas.isPrepaidPayment = !metas.isMonthlyPayment;
  }

  return {
    ...metas,
    isSaas: true,
    isTrialXray: hasActiveTrialXraySaasProduct,
    isEligibleToAddXrayTrial: jpus.some((jpu) => jpu.eligibleToAddXrayTrial),
    isTrialPipelines: trialSaasProductFilter(
      jpus,
      "pipelines",
      activeStatuses,
      true,
    ),
    isXrayBlocked: trialSaasProductFilter(jpus, "xray", blockStatuses),
    isPipelinesBlocked: trialSaasProductFilter(
      jpus,
      "pipelines",
      blockStatuses,
    ),
    isXrayAvailableInRegion: subscription.xrayAvailableInRegion,
    isEligibleToChangePlan: subscription.eligibleToChangePlan,
    isEnterprisePlusTrial: metas.isEnterprisePlus && metas.isTrial,
    isCloudPro: metas.isCloudPro && !hasActiveTrialXraySaasProduct,
    isCloudProX:
      metas.isCloudProX || (metas.isCloudPro && hasActiveTrialXraySaasProduct),
    isEnterprise: metas.isEnterprise && !hasActiveTrialXraySaasProduct,
    isEnterpriseX:
      metas.isEnterpriseX ||
      (metas.isEnterprise && hasActiveTrialXraySaasProduct),
    hasXray,
    hasPipelines: hasSaasProductPipelines(jpus),
    isPipelinesAvailableInRegion: subscription.pipelinesAvailableInRegion,
    hasCdnEnabled: jpus.some((jpu) => jpu.cdnEnabled),
    isEligibleToEnableCdn: isEligibleToEnableCdn(metas, jpus),
    displayName: renderDisplayName(
      metas.paymentTypeName,
      computeSaasDisplayName(subscription, metas, hasXray),
    ),
    isExpired:
      metas.isTrial && dateUtils.isTimestampExpired(+subscription.endOfTrial),
  };
};

const activeStatuses: JPUStatus[] = ["ACTIVE", "NEW", "FAILED"];
const blockStatuses: JPUStatus[] = ["BLOCKED"];

const isEligibleToEnableCdn = (
  meta: SubscriptionDTO["meta"],
  jpus: JpuDTO[],
) => {
  const compatibleSubscriptionType = !meta.isJCR && !meta.isOSS;
  const compatibleTopology = jpus.some((jpu) => jpu.eligibleToEnableCdn); // at least one server is eligible
  return compatibleSubscriptionType && compatibleTopology;
};

const hasSaasProductPipelines = (jpus: JpuDTO[]) =>
  jpus.some((jpu) =>
    jpu.products.some((product) => product.productName === "pipelines"),
  );

const trialSaasProductFilter = (
  jpus: JpuDTO[],
  productName: string,
  statuses: string[],
  trial = false,
) => {
  return jpus.some((jpu) =>
    jpu.products.some(
      (product) =>
        product.productName === productName &&
        product.trial === trial &&
        statuses.includes(product.status),
    ),
  );
};

const commercialSaasProductFilter = (
  jpus: JpuDTO[],
  productName: string,
  statuses: string[],
) => {
  return jpus.some((jpu) =>
    jpu.products.some(
      (product) =>
        product.productName === productName &&
        statuses.includes(product.status),
    ),
  );
};

const computeSaasDisplayName = (
  subscription: SubscriptionDTO,
  meta: SubscriptionDTO["meta"],
  hasXray: boolean,
) => {
  const baseName = meta.baseName;
  const isOldCloudProWithXray =
    (meta.isCloudProX || meta.isCloudPro) && hasXray;
  const isEntWithJas =
    baseName === subscriptionBaseName.ENTERPRISE &&
    subscription.hasBusinessSecurity;
  const isEntWithXrayPremium =
    baseName === subscriptionBaseName.ENTERPRISE && meta.hasXrayPremium;
  const xSuffix =
    isOldCloudProWithXray || isEntWithJas || isEntWithXrayPremium ? "X" : "";

  const trialSuffix = meta.isTrial ? trialSuffixStr : "";

  return [baseName, xSuffix, trialSuffix].filter((e) => !!e).join(" ");
};

const isMonthlyMp = (subscription: SubscriptionDTO) => {
  /*
   * MP subscription is monthly in 2 cases:
   * -> MP subscription is PublicOffer
   * -> expirationDate is in long long time (year 9999)
   * -> expirationDate - startDate <= 31 days
   */

  if (subscription.hasMpPrivateOffer) {
    return false;
  }

  const expirationDate = DateTime.fromMillis(
    parseInt(subscription.expirationDate),
  );

  const diffToExpirationInYears = expirationDate
    .diff(DateTime.now(), "years")
    .toObject().years;

  if (diffToExpirationInYears && diffToExpirationInYears > 100) {
    return true;
  }

  const startDate = DateTime.fromMillis(parseInt(subscription.startDate));
  const subscriptionValidityPeriodInDays = expirationDate
    .diff(startDate, "days")
    .toObject().days;

  return !!(
    subscriptionValidityPeriodInDays && subscriptionValidityPeriodInDays < 32
  );
};
