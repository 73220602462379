export const subscriptionBaseName = {
  JCR: "JFrog Container Registry",
  CLOUD_PRO: "Pro",
  CLOUD_ENTERPRISE: "Enterprise",
  FREE: "Free",
  PRO: "Pro",
  ENTERPRISE: "Enterprise",
  ENTERPRISE_PLUS: "Enterprise+",
  ENTERPRISE_PLUS_HYBRID: "Enterprise+ Distribution Edges",
};
