import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { useAuthStore } from "@shared/stores/auth";
import { rolesService } from "@shared/services/roles";
import { useEngine } from "@shared/use/useEngine.ts";
import { getHeapClient } from "@shared/module-transformers/getHeapClient.ts";
import { HeapMethods } from "@shared/types/global.ts";
import { UserDTO } from "@jfrog-ba/myjfrog-common";
import { LogInfo } from "@shared/types/localTypes/auth.ts";
import { PropertyValue } from "@heap/heap-javascript-bridge-support/dist/heap/types";

type HeapService = {
  clearAll: () => void;
  setSubscriptionProperties: () => void;
  setUserIdentityProperties: () => void;
  track: HeapMethods["track"];
  trackLoginSuccess: (user: UserDTO) => void;
  trackLoginFailed: (arg: {
    loginInfo: LogInfo;
    reason: string;
    details?: Record<string, PropertyValue>;
  }) => void;
};

export const heapService: HeapService = {
  clearAll: () => {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    heap.resetIdentity();
    heap.clearEventProperties();
  },
  setSubscriptionProperties(): void {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    const subscriptionStore = useSubscriptionsStore();
    const authStore = useAuthStore();
    const role = rolesService.extractUserRoleForSubscription(
      subscriptionStore.getCurrentSubscriptionNumber,
    );
    heap.clearEventProperties();
    const currentSubscription = subscriptionStore.getCurrentSubscription;
    if (!currentSubscription) {
      return;
    }

    const heapDetails = {
      subscriptionId: currentSubscription.accountNumber,
      subscriptionType: currentSubscription.paymentType,
      subscriptionState: currentSubscription.state,
      isTrialSubscription: currentSubscription.trial,
      hasBusinessSecurity: currentSubscription.hasBusinessSecurity,
      paymentType: currentSubscription.meta.paymentTypeName,
      isSaas: currentSubscription.meta.isSaas || "undefined",
      isSelfHosted: currentSubscription.meta.isSelfHosted || "undefined",
      role: role || "undefined",
      shBomType: currentSubscription.bom?.type || "undefined",
      myjfrogEngine: useEngine().engine,
      loginType: authStore.loginInfo?.type || "undefined",
      loginProvider: authStore.loginInfo?.provider || "undefined",
      platformIdentification:
        authStore.getUser?.platformIdentification || "N/A",
    };

    heap.addEventProperties(heapDetails);
  },

  setUserIdentityProperties(): void {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    const authStore = useAuthStore();
    if (!authStore.getUser) {
      return;
    }
    heap.resetIdentity();
    heap.identify(authStore.getUser.heapIdentity);
    const isInternalJfrogUser = authStore.getUser.email
      .toLowerCase()
      .includes("@jfrog.com");

    const myjfrogEngine = useEngine().engine;

    const heapDetails = {
      isInternalJfrogUser,
      myjfrogEngine,
      loginType: authStore.loginInfo?.type || "undefined",
      loginProvider: authStore.loginInfo?.provider || "undefined",
    };
    heap.addUserProperties(heapDetails);
  },
  track: (event, properties, timestamp, sourceInfo) => {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    heap.track(event, properties, timestamp, sourceInfo);
  },
  trackLoginSuccess: (user: UserDTO) => {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    const authStore = useAuthStore();
    heap.track("LOGIN_SUCCESS", {
      heapIdentity: user.heapIdentity,
      isInternalJfrogUser: user.email.includes("@jfrog.com"),
      loginType: authStore.loginInfo?.type || "undefined",
      loginProvider: authStore.loginInfo?.provider || "undefined",
      myjfrogEngine: useEngine().engine,
    });
  },

  trackLoginFailed: ({ loginInfo, reason, details }) => {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    heap.track("LOGIN_FAILED", {
      loginType: loginInfo.type,
      loginProvider: loginInfo.provider,
      reason,
      myjfrogEngine: useEngine().engine,
      ...details,
    });
  },
};
