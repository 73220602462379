import apiProxy from "@shared/utils/api-proxy";
import { type ErrorLogDetailsRequest } from "@jfrog-ba/myjfrog-common";
import { useAuthStore } from "@shared/stores/auth.ts";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";

export const remoteErrorLogger = {
  logError: async (message: string) => {
    const userEmail = useAuthStore().getUser?.email;
    const subscriptionNumber =
      useSubscriptionsStore().getCurrentSubscriptionNumberInt;
    const request: ErrorLogDetailsRequest = {
      message,
      userEmail,
      subscriptionNumber,
    };
    try {
      await apiProxy.post(`error-logger`, request);
    } catch (e) {
      return;
    }
  },
};
