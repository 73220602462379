import { computed } from "vue";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { entitlementsService } from "@shared/services/entitlements.ts";
import { EntitlementName } from "@shared/types/localTypes/entitlements.ts";
export default () => {
  const subscriptionMeta = computed(
    () => useSubscriptionsStore().getCurrentSubscription?.meta,
  );

  const subscriptionPaymentType = computed(
    () => useSubscriptionsStore().getCurrentSubscription?.paymentType,
  );

  const requestDowngradeSubscriptionMeta = computed(() => {
    //Available to all
    return false;
  });

  const removeSecurityPackMeta = computed(() => {
    //Available to EnterpriseX Monthly
    // return subscriptionPaymentType.value === "ENTERPRISE_X_MONTHLY";
    // currently not available
    return false;
  });

  const cancelSubscriptionMeta = computed(() => {
    //Not Available to MP
    if (subscriptionMeta.value?.isMP) {
      return false;
    }

    return (
      //Available to Monthly - Pro Team, Enterprise and EnterpriseX
      subscriptionPaymentType.value === "PRO_TEAM_MONTHLY" ||
      subscriptionPaymentType.value === "ENTERPRISE_MONTHLY" ||
      subscriptionPaymentType.value === "ENTERPRISE_X_MONTHLY" ||
      //Available to Free
      subscriptionMeta.value?.isFree ||
      //Available to Internal - Internal and Internal ProX
      subscriptionMeta.value?.isInternal ||
      //Available to JCR
      subscriptionMeta.value?.isJCR ||
      false
    );
  });

  const artifactoryConfigurationTransferMeta = computed(() => {
    //Available to all subscriptions
    return true;
  });

  const publicRootCertificateMeta = computed(() => {
    //Available Only for Enterprise Plus - all payment types
    return subscriptionMeta.value?.isEnterprisePlus;
  });

  const cloudChangesMeta = computed(() => {
    return !!(
      subscriptionMeta.value?.isEnterprise ||
      subscriptionMeta.value?.isEnterpriseTeam ||
      //Available to ProTeam - Monthly and Annual
      subscriptionMeta.value?.isProTeam ||
      //Available to EnterpriseX - Monthly and Annual
      subscriptionMeta.value?.isEnterpriseX ||
      //Available to JCR
      subscriptionMeta.value?.isJCR
    );
  });

  const isEntitledCname = computed(() => {
    const subscriptionStore = useSubscriptionsStore();
    const currentSubscription = subscriptionStore.getCurrentSubscription;
    if (!currentSubscription) {
      return false;
    }
    const cnameEntitlement = entitlementsService.getEntitlement(
      currentSubscription,
      EntitlementName.CNAME,
    );

    return cnameEntitlement?.value === "true";
  });

  const isEntitledDnsRoutingManualFailoverPolicy = computed(() => {
    const subscriptionStore = useSubscriptionsStore();
    const currentSubscription = subscriptionStore.getCurrentSubscription;
    if (!currentSubscription) {
      return false;
    }
    const manualFailoverEntitlement = entitlementsService.getEntitlement(
      currentSubscription,
      EntitlementName.MANUAL_FAILOVER_POLICY,
    );

    return manualFailoverEntitlement?.value === "true";
  });

  const isEntitledDnsRoutingGeoLocationPolicy = computed(() => {
    const subscriptionStore = useSubscriptionsStore();
    const currentSubscription = subscriptionStore.getCurrentSubscription;
    if (!currentSubscription) {
      return false;
    }
    const geoLocationEntitlement = entitlementsService.getEntitlement(
      currentSubscription,
      EntitlementName.GEOLOCATION_POLICY,
    );

    return geoLocationEntitlement?.value === "true";
  });

  const isCnameTabShown = computed(
    () =>
      isEntitledCname.value || useSubscriptionsStore().isFreeOrProSubscription,
  );

  const isSubscriptionNotActive = computed(
    () => useSubscriptionsStore().getCurrentSubscription?.state !== "ACTIVE",
  );

  return {
    requestDowngradeSubscriptionMeta,
    removeSecurityPackMeta,
    cancelSubscriptionMeta,
    artifactoryConfigurationTransferMeta,
    publicRootCertificateMeta,
    cloudChangesMeta,
    isCnameTabShown,
    isEntitledCname,
    isSubscriptionNotActive,
    isEntitledDnsRoutingGeoLocationPolicy,
    isEntitledDnsRoutingManualFailoverPolicy,
  };
};
