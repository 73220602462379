import { remoteErrorLogger } from "@shared/services/remoteErrorLogger.ts";

export const useErrorLogsInitializer = () => {
  window.onerror = async (message, source, lineno, colno, error) => {
    await remoteErrorLogger.logError(
      `message: ${message.toString()} stack: ${error ? error.stack : ""}`,
    );
  };

  window.addEventListener("unhandledrejection", async (event) => {
    await remoteErrorLogger.logError(
      `Promise: ${JSON.stringify(event.promise)} Reason: ${JSON.stringify(
        event.reason,
      )}`,
    );
  });
};
