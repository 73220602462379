import type { RouteRecordRaw } from "vue-router";
import type { MjfRouteMeta } from "@shared/types/localTypes/router";
import { RouteNames } from "@shared/types/localTypes/router";
import {
  checkIfMaintenancePageAccessible,
  loginBeforeUpgrade,
  toLandingAppWithFullPath,
} from "@/router/guard/redirect";

export default [
  {
    path: "/redirect",
    name: RouteNames.Redirect,
    meta: { isGuest: true } as MjfRouteMeta,
    children: [
      {
        path: "loginBeforeUpgrade",
        name: RouteNames.LoginBeforeUpgrade,
        beforeEnter: loginBeforeUpgrade,
      },
    ],
  },
  {
    path: "/reactivate-server/:serverName",
    name: RouteNames.RedirectReactivateServer,
    beforeEnter: toLandingAppWithFullPath,
  },
  {
    path: "/maintenance",
    name: RouteNames.Maintenance,
    component: () => import("@/views/maintenance/Maintenance.vue"),
    beforeEnter: checkIfMaintenancePageAccessible,
  },
] as RouteRecordRaw[];
