import { DateTime } from "luxon";

const json = import("../json/billingAccountsJfrogaccInvoicesLimitMonth.json");

export default async () => {
  const importedJson = await json;
  const parsedJson = JSON.parse(
    JSON.stringify(importedJson),
  ) as typeof importedJson;
  parsedJson.invoiceList = new Array(12).fill(
    parsedJson.invoiceList[0],
  ) as typeof importedJson.invoiceList;

  parsedJson.invoiceList = parsedJson.invoiceList.map(
    (invoice, index: number) => ({
      ...invoice,
      invoiceTotalAmount: "" + (200 - index),
      invoiceDate: DateTime.now()
        .startOf("day")
        .minus({ months: index + 1 })
        .toMillis(),
      invoiceNumber: `INV0061191${index > 9 ? index : `0${index}`}`,
    }),
  );

  return parsedJson;
};
