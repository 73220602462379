import { defineStore } from "pinia";

export interface UpgradeStoreStates {
  isQuickUpgradeState: boolean;
  upgradeViewIsVisibleState: boolean;
  buyModalIsOpenState: boolean;
  upgradeModalIsOpenState: boolean;
  getAQuoteModalIsOpenState: boolean;
  buyFlowIsProcessingState: boolean;
  upgradeFlowIsProcessingState: boolean;
}

export const useUpgradeStore = defineStore({
  id: "upgrade",
  state: () =>
    ({
      isQuickUpgradeState: false,
      upgradeViewIsVisibleState: false,
      buyModalIsOpenState: false,
      upgradeModalIsOpenState: false,
      getAQuoteModalIsOpenState: false,
      buyFlowIsProcessingState: false,
      upgradeFlowIsProcessingState: false,
    }) as UpgradeStoreStates,
  getters: {
    isQuickUpgrade: (state) => state.isQuickUpgradeState,
    upgradeViewIsVisible: (state) => state.upgradeViewIsVisibleState,
    buyModalIsOpen: (state) => state.buyModalIsOpenState,
    upgradeModalIsOpen: (state) => state.upgradeModalIsOpenState,
    getAQuoteModalIsOpen: (state) => state.getAQuoteModalIsOpenState,
    buyFlowIsProcessing: (state) => state.buyFlowIsProcessingState,
    upgradeFlowIsProcessing: (state) => state.upgradeFlowIsProcessingState,
  },
  actions: {
    dismissUpgradeView() {
      if (this.buyFlowIsProcessing || this.upgradeFlowIsProcessing) {
        return;
      }
      this.upgradeViewIsVisibleState = false;
      this.buyModalIsOpenState = false;
      this.upgradeModalIsOpenState = false;
      this.getAQuoteModalIsOpenState = false;
      document.documentElement.classList.remove("upgrade-modal-opened");
    },
    displayUpgradeView() {
      this.isQuickUpgradeState = false;
      this.upgradeViewIsVisibleState = true;
      document.documentElement.classList.add("upgrade-modal-opened");
    },
    setBuyModalIsOpen(isOpen: UpgradeStoreStates["buyModalIsOpenState"]) {
      this.buyModalIsOpenState = isOpen;
    },
    setUpgradeModalIsOpen(
      isOpen: UpgradeStoreStates["upgradeModalIsOpenState"],
    ) {
      this.upgradeModalIsOpenState = isOpen;
    },
    setGetAQuoteModalIsOpen(
      isOpen: UpgradeStoreStates["getAQuoteModalIsOpenState"],
    ) {
      this.getAQuoteModalIsOpenState = isOpen;
    },
    setBuyFlowIsProcessing(
      isProcessing: UpgradeStoreStates["buyFlowIsProcessingState"],
    ) {
      this.buyFlowIsProcessingState = isProcessing;
    },
    setUpgradeFlowIsProcessing(
      isProcessing: UpgradeStoreStates["upgradeFlowIsProcessingState"],
    ) {
      this.upgradeFlowIsProcessingState = isProcessing;
    },
    setIsQuickUpgrade(
      isQuickUpgrade: UpgradeStoreStates["isQuickUpgradeState"],
    ) {
      this.isQuickUpgradeState = isQuickUpgrade;
    },
  },
});
