import { inject, onMounted, onUnmounted } from "vue";
import { type AnyObject } from "@shared/types/localTypes/generic.ts";
import {
  type Emitter,
  type EventNames,
} from "@shared/types/localTypes/appEvents.ts";

type EventSubscriptionConfig = {
  [key in EventNames]?: (payload: never) => void;
};

export function useEventBus() {
  const emit = (
    emitter: Emitter,
    eventName: EventNames,
    payload?: AnyObject,
  ) => {
    emitter?.emit(eventName, payload);
  };
  const subscribe = (config: EventSubscriptionConfig) => {
    onMounted(() => {
      const emitter = inject<Emitter>("emitter") as Emitter;
      Object.keys(config).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        emitter?.on(key, config[key]);
      });
    });
    onUnmounted(() => {
      const emitter = inject<Emitter>("emitter") as Emitter;
      Object.keys(config).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        emitter?.off(key, config[key]);
      });
    });
  };
  return { emit, subscribe };
}
