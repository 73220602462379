import { SubscriptionsResponse } from "@jfrog-ba/myjfrog-common/dist/subscriptions";
import { getEmail } from "@shared/services/tour/mockedApis/helpers.ts";

const json = import("../json/subscriptions.json");

export default async () => {
  const parsedJson = JSON.parse(
    JSON.stringify(await json),
  ) as SubscriptionsResponse;

  parsedJson.subscriptions[0].owners[0].email = await getEmail();

  return parsedJson;
};
