import type {
  LinkedSessionRequest,
  LinkedSessionResponse,
} from "@jfrog-ba/myjfrog-common";
import apiProxy from "@shared/utils/api-proxy";

export const sessionsService = {
  getLinkedSession: async (
    getLinkedSessionRequest: LinkedSessionRequest,
  ): Promise<LinkedSessionResponse | null> =>
    await apiProxy.post(`session/getLinkedSession`, getLinkedSessionRequest),
};
