import { useSupportTicketsStore } from "@shared/stores/supportTickets.ts";
import { useNotifications } from "@shared/module-transformers/useNotifications.ts";
import {
  SubscriptionsStoreStates,
  useSubscriptionsStore,
} from "@shared/stores/subscriptions.ts";
import { salesforce } from "@shared/services/salesforce.ts";
import { watch } from "vue";
import { rolesService } from "@shared/services/roles.ts";
import logger from "@shared/utils/logger.ts";

export const useSupportTicketsLoader = () => {
  const subscriptionsStore = useSubscriptionsStore();
  const supportTicketsStore = useSupportTicketsStore();

  const loadSupportTickets = async () => {
    try {
      supportTicketsStore.setIsLoading(true);

      const allSupportTickets = await fetchAndMergeSupportTickets();
      supportTicketsStore.setSupportTickets(allSupportTickets);

      supportTicketsStore.setIsLoading(false);
    } catch (e) {
      logger.error(e);
      supportTicketsStore.setIsLoading(false);
      useNotifications().error("Failed to fetch support tickets");
    }
  };

  const extractSubscriptionNumbersToFetch = (): number[] => {
    const allSubscriptions =
      subscriptionsStore.getSubscriptions as SubscriptionsStoreStates["subscriptions"];

    if (!allSubscriptions) {
      return [];
    }

    return allSubscriptions
      .filter((subscription) => {
        const roleForSubscription = rolesService.extractUserRoleForSubscription(
          subscription.accountNumber.toString(),
        );
        return roleForSubscription === "MYJFROG_PRIMARY_ADMIN";
      })
      .map((subscription) => subscription.accountNumber);
  };

  const fetchAndMergeSupportTickets = async () => {
    const subscriptionNumbersToFetch = extractSubscriptionNumbersToFetch();

    const responses = await Promise.all(
      subscriptionNumbersToFetch.map((subscriptionNumber) =>
        salesforce.getSupportTickets(subscriptionNumber),
      ),
    );

    return responses.flat();
  };

  watch(
    () => subscriptionsStore.getCurrentSubscription,
    (currentSubscription) => {
      if (!currentSubscription || !supportTicketsStore.isLoading) {
        return;
      }
      loadSupportTickets();
    },
    {
      immediate: true,
    },
  );
  return {};
};
