import {
  type JpuDTO,
  type ReadServerStatusResponse,
  type SubscriptionMeta,
} from "@jfrog-ba/myjfrog-common";
import { subscriptionsService } from "@shared/services/subscriptions";
import window from "@shared/utils/app-window";
import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { useNotifications } from "@shared/module-transformers/useNotifications.ts";
import { messagesService } from "@shared/services/messages.ts";
import { useServerMetaDataStore } from "@shared/stores/serverMetaData.ts";

export enum jpdStatus {
  ACTIVE = "Active",
  IN_PROGRESS = "In Progress",
  INACTIVE = "Inactive",
}

export interface jpdStatusTag {
  tagType: string;
  status: jpdStatus;
}

export const jpdUtils = {
  getAccessibleJpus: () => {
    const subscription = useSubscriptionsStore().getCurrentSubscription;
    if (!subscription) {
      return [];
    }

    return subscription.jpus.filter((jpd) => jpdUtils.isJpdAccessible(jpd));
  },
  getTechnicalNameByServerName: (serverName: string) => {
    const subscription = useSubscriptionsStore().getCurrentSubscription;
    if (!subscription) {
      return undefined;
    }

    const jpuDTO = subscription.jpus.find(
      (jpu) => jpu.serverName === serverName,
    );

    if (!jpuDTO) {
      return undefined;
    }

    return jpuDTO.technicalServerName;
  },
  getServerNameByTechnicalName: (technicalServerName: string) => {
    const subscription = useSubscriptionsStore().getCurrentSubscription;
    if (!subscription) {
      return undefined;
    }

    const jpuDTO = subscription.jpus.find(
      (jpu) => jpu.technicalServerName === technicalServerName,
    );

    if (!jpuDTO) {
      return undefined;
    }

    return jpuDTO.serverName;
  },
  getCurrentSubscriptionTechNames: (serverNames?: string[]) => {
    const subscription = useSubscriptionsStore().getCurrentSubscription;
    if (!subscription) {
      return [];
    }
    if (!serverNames) {
      return subscription.jpus.map((server) => server.technicalServerName);
    }

    return subscription.jpus
      .filter((jpu) => serverNames.includes(jpu.serverName))
      .map((server) => server.technicalServerName);
  },
  getCurrentSubscriptionServerNames: (techServerNames?: string[]) => {
    const subscription = useSubscriptionsStore().getCurrentSubscription;
    if (!subscription) {
      return [];
    }
    if (!techServerNames) {
      return subscription.jpus.map((server) => server.serverName);
    }

    return subscription.jpus
      .filter((jpu) => techServerNames.includes(jpu.technicalServerName))
      .map((server) => server.serverName);
  },
  getJpdType: (jpd: JpuDTO) => {
    if (!jpdUtils.isPlatformJpd(jpd)) return "edge";

    if (jpd.isMothership) {
      return "mothership";
    }

    return "platform";
  },
  calcJpdTag: (
    jpd: JpuDTO,
    activeServersStatus: ReadServerStatusResponse,
  ): jpdStatusTag => {
    const inProgressTag: jpdStatusTag = {
      tagType: "warning",
      status: jpdStatus.IN_PROGRESS,
    };

    if (
      jpd.status === "ACTIVE" &&
      isServerStatusInProgress(jpd.technicalServerName, activeServersStatus)
    ) {
      return inProgressTag;
    }
    if (jpd.status === "ACTIVE") {
      return { tagType: "success", status: jpdStatus.ACTIVE };
    }
    if (jpd.status === "FAILED" || jpd.status === "NEW") {
      return inProgressTag;
    }
    return { tagType: "info", status: jpdStatus.INACTIVE };
  },
  isPlatformJpd: (jpd: JpuDTO) => {
    return !jpd.products.some((product) => product.productName === "edge");
  },
  fetchServerStatus: async (
    accountNumber: string,
    jpds: JpuDTO[],
  ): Promise<ReadServerStatusResponse> => {
    const activeJpds = jpds.filter((jpd) => jpd.status === "ACTIVE");
    if (!activeJpds.length) {
      return {};
    }
    return await subscriptionsService.getServersStatusParallel(accountNumber, {
      technicalServerNames: activeJpds.map((jpd) => jpd.technicalServerName),
    });
  },
  goToPlatform: (serverName: string, target: "_blank" | "_self" = "_blank") => {
    const url = `https://${serverName}.${window.PLATFORM_DOMAIN}/`;
    window.open(url, target);
  },
  isJpdNotAccessible: (jpdItem: JpuDTO): boolean => {
    const isCurrentSubscriptionActive =
      useSubscriptionsStore().isCurrentSubscriptionActive;

    const isJpdDeleted = jpdItem.status === "DELETED";
    const isJpdBlocked = jpdItem.status === "BLOCKED";

    const isSubscriptionActiveAndJpdBlocked =
      isCurrentSubscriptionActive && isJpdBlocked;

    return isJpdDeleted || isSubscriptionActiveAndJpdBlocked;
  },
  isJpdAccessible: (jpdItem: JpuDTO): boolean => {
    return !jpdUtils.isJpdNotAccessible(jpdItem);
  },
  isIPAllowlistAccessible: () => {
    const subscription = useSubscriptionsStore().getCurrentSubscription;
    const meta = subscription?.meta;

    return (
      meta?.isEnterprise ||
      meta?.isEnterpriseX ||
      meta?.isEnterprisePlus ||
      meta?.isEnterpriseTeam
    );
  },

  getSingleAccessibleJpdName: (): string => {
    const subscription = useSubscriptionsStore().getCurrentSubscription;
    const accessibleJpdItem = subscription?.jpus.find(
      (jpdItem) => !jpdUtils.isJpdNotAccessible(jpdItem),
    );
    return accessibleJpdItem ? accessibleJpdItem.serverName : "";
  },

  filterByJpd: (
    isSelfHosted: SubscriptionMeta["isSelfHosted"],
    options: string[],
  ) => {
    return isSelfHosted
      ? (options as string[]).map((option) => {
          const label = option.split("-").pop() || option;

          return { value: option, label: `JPD-${label}` };
        })
      : options?.map((server) => {
          return { value: server, label: server };
        });
  },

  fetchMetaDataForJpdsIfEmpty: async () => {
    const subscriptionStore = useSubscriptionsStore();
    const serverMetaDataStore = useServerMetaDataStore();
    if (!subscriptionStore.getCurrentSubscription) {
      return [];
    }
    const activeJpds = subscriptionStore.getCurrentSubscription.jpus.filter(
      (jpd) => jpd.status === "ACTIVE",
    );

    serverMetaDataStore.setIsLoadingServerMetaDataRequest(true);
    const techServerNamesWithFetchedMetaDataInfo = Object.keys(
      serverMetaDataStore.getServerMetaData,
    );
    const isMetaDataStoreIncludesAllJpdsInfo = activeJpds.every((jpd) =>
      techServerNamesWithFetchedMetaDataInfo.includes(jpd.technicalServerName),
    );
    if (isMetaDataStoreIncludesAllJpdsInfo) {
      serverMetaDataStore.setIsLoadingServerMetaDataRequest(false);
      return;
    }
    try {
      const technicalServerNames = activeJpds.map(
        (jpd) => jpd.technicalServerName,
      );
      const readServerIPMetaResponse =
        await subscriptionsService.getServersParallelMetaData(
          subscriptionStore.currentSubscriptionNumber,
          {
            technicalServerNames,
          },
        );
      serverMetaDataStore.setServerMetaData(readServerIPMetaResponse);
    } catch (e) {
      useNotifications().error(messagesService.app_something_went_wrong);
    }
    serverMetaDataStore.setIsLoadingServerMetaDataRequest(false);
  },
};

const isServerStatusInProgress = (
  technicalServerName: JpuDTO["technicalServerName"],
  activeServersStatus: ReadServerStatusResponse,
): boolean => {
  return (
    technicalServerName in activeServersStatus &&
    activeServersStatus[technicalServerName].inProgress
  );
};
