import { defineStore } from "pinia";

interface VersionStoreState {
  _version: string;
}

export const useVersionStore = defineStore({
  id: "version",
  state: (): VersionStoreState => ({
    _version: "1.0.0",
  }),
  getters: {
    version: (state) => state._version,
  },
  actions: {
    setVersion(version: VersionStoreState["_version"]) {
      this._version = version;
    },
  },
});
