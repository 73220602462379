export const keepOnlyNumbers = (str: string) => {
  const numbersOnly = str.match(/\d+/g);
  return (numbersOnly && numbersOnly.join("")) || "";
};
export const truncatePriceBeforeDot = (price: string) => {
  return price.split(".")[0];
};

export const ensureStartingSlashExistence = (
  s: string,
  shouldBePresent: boolean,
): string => {
  if (shouldBePresent) return s.startsWith("/") ? s : `/${s}`;
  return s.startsWith("/") ? s.substring(1) : s;
};

export const ensureTrailingSlashExistence = (
  s: string,
  shouldBePresent: boolean,
): string => {
  if (shouldBePresent) return s.endsWith("/") ? s : `${s}/`;
  return s.endsWith("/") ? s.substring(0, s.length - 1) : s;
};
export const capitalizeSentence = (sentence: string) =>
  sentence
    .split(" ")
    .map((word) => capitalizeWord(word))
    .join(" ");

export const capitalizeWords = (words: string[]) => {
  return words.map((word) => capitalizeWord(word)).join(" ");
};

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
};

export const lowerWords = (words: string[]) =>
  words.map((word) => word.toLowerCase()).join(" ");

export const capitalizeFirstWord = (sentence: string) => {
  if (!sentence) {
    return sentence;
  }

  const words = sentence.split(" ");
  words[0] = capitalizeWord(words[0]);
  return words.join(" ");
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const generalDebounce = (() => {
  let timeout: ReturnType<typeof setTimeout>;
  return function (fnc: (...args: unknown[]) => void, delayMs: number) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
})();

export const sortAlphabeticalIgnoreCase = (
  firstObject: Record<string, unknown>,
  secondObject: Record<string, unknown>,
  fieldName: keyof Record<string, unknown>,
) => {
  const firstString = firstObject[fieldName] as string;
  const secondString = secondObject[fieldName] as string;

  return firstString.toLowerCase().localeCompare(secondString.toLowerCase());
};

export const sortNumbersInTable = (
  firstObject: Record<string, unknown>,
  secondObject: Record<string, unknown>,
  fieldName: keyof Record<string, unknown>,
) => {
  const firstNumber = firstObject[fieldName] as number;
  const secondNumber = secondObject[fieldName] as number;

  return firstNumber - secondNumber;
};
