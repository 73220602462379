import { LocalStorageKeys } from "@shared/types/localTypes/storage.ts";
import { onBeforeUnmount } from "vue";
import { useSessionLogic } from "@shared/use/useSessionLogic.ts";

export const useSessionLoginListener = () => {
  const sessionLogic = useSessionLogic();
  const handleChangeInLocalStorageInOtherTab = async (event: StorageEvent) => {
    if (
      event.key === LocalStorageKeys.LAST_SUCCESSFUL_LOGOUT ||
      event.key === LocalStorageKeys.LAST_SUCCESSFUL_LOGIN
    ) {
      const canReloadStr =
        window.sessionStorage.getItem(LocalStorageKeys.CAN_RELOAD_LOCK) ||
        "true";
      const canReload = canReloadStr === "true";

      if (canReload) {
        sessionLogic.lockReload();
        location.reload();
      }
    }
  };

  window.addEventListener("storage", handleChangeInLocalStorageInOtherTab);

  onBeforeUnmount(() =>
    window.removeEventListener("storage", handleChangeInLocalStorageInOtherTab),
  );
};
