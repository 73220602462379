import { GetDailyCuratedPackagesVerdictV2Response } from "@shared/services/usageExposer/getCurationStatistics.ts";
import dateUtils from "@shared/utils/date-utils.ts";

const json = import(
  "../../json/usageExposer/dashboard/usageExposerGetDailyCuratedPackagesVerdict.json"
);

const insertDate = (
  jsonObject: GetDailyCuratedPackagesVerdictV2Response,
  callback: (index: number) => string,
) => {
  jsonObject.dailyCurationVerdicts.map((curation, index) => {
    curation.date = callback(index);
  });
};

export default async () => {
  const currentObject = await json;
  insertDate(
    currentObject as unknown as GetDailyCuratedPackagesVerdictV2Response,
    dateUtils.returnDateFromToday,
  );

  return JSON.parse(JSON.stringify(currentObject));
};
