const json = import("../json/subscriptionsGetServersStatus.json");

export default async (data?: object) => {
  const technicalServerNames = (
    data as {
      technicalServerNames: string[];
    }
  ).technicalServerNames;

  const response = (await json).default;
  let currentResponse;
  technicalServerNames.forEach((serverName) => {
    currentResponse = {
      [serverName]: {} as (typeof response)["server_that_is_required"],
    };

    currentResponse[serverName] = response["server_that_is_required"];
  });

  return JSON.parse(JSON.stringify(currentResponse));
};
