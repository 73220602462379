import {
  SALESFORCE_CONTACTS,
  SALESFORCE_QUOTA,
} from "@shared/services/tour/urls/dashboard-urls.ts";
import salesforceContacts from "@shared/services/tour/mockedApis/salesforceContacts.ts";
import salesforceQuota from "@shared/services/tour/mockedApis/salesforceQuota.ts";

export default {
  [SALESFORCE_CONTACTS]: () => salesforceContacts(),
  [SALESFORCE_QUOTA]: () => salesforceQuota(),
};
