import { defineStore } from "pinia";
import { appcuesService } from "@shared/services/appcues";
import { AppcuesEvents } from "@shared/types/global";

export type GenVersion = "current-gen" | "new-gen";

interface GenVersionStoreState {
  _currentGenVersion: GenVersion;
}

export const useGenVersionStore = defineStore({
  id: "gen-version",
  state: (): GenVersionStoreState => ({
    _currentGenVersion: "current-gen",
  }),
  getters: {
    currentGenVersion: (state) => state._currentGenVersion,
    isNewGen: (state) => state._currentGenVersion === "new-gen",
  },
  actions: {
    setCurrentGenVersion(
      currentGenVersion: GenVersionStoreState["_currentGenVersion"],
    ) {
      this._currentGenVersion = currentGenVersion;
      appcuesService.sendEvent(AppcuesEvents.NG_SWITCH, {
        is_ng: currentGenVersion === "current-gen",
      });
    },
  },
});
