import {
  BILLING_ACCOUNTS_JFROGACC_ACCOUNT_INFO,
  BILLING_ACCOUNTS_JFROGACC_INVOICES_LIMIT_MONTH,
  BILLING_ACCOUNTS_JFROGACC_SUBSCRIPTIONS_JFROGACC,
  BILLING_ACCOUNTS_JFROGACC_SUBSCRIPTIONS_JFROGACC_RENEWAL_DATE,
} from "@shared/services/tour/urls/dashboard-urls.ts";
import billingAccountsJfrogaccAccountInfo from "@shared/services/tour/mockedApis/billingAccountsJfrogaccAccountInfo.ts";
import billingAccountsJfrogaccSubscriptionsJfrogacc from "@shared/services/tour/mockedApis/billingAccountsJfrogaccSubscriptionsJfrogacc.ts";
import billingAccountsJfrogaccSubscriptionsJfrogaccRenewalDate from "@shared/services/tour/mockedApis/billingAccountsJfrogaccSubscriptionsJfrogaccRenewalDate.ts";
import billingAccountsJfrogaccInvoicesLimitMonth from "@shared/services/tour/mockedApis/billingAccountsJfrogaccInvoicesLimitMonth.ts";

export default {
  [BILLING_ACCOUNTS_JFROGACC_ACCOUNT_INFO]: () =>
    billingAccountsJfrogaccAccountInfo(),
  [BILLING_ACCOUNTS_JFROGACC_SUBSCRIPTIONS_JFROGACC]: () =>
    billingAccountsJfrogaccSubscriptionsJfrogacc(),
  [BILLING_ACCOUNTS_JFROGACC_SUBSCRIPTIONS_JFROGACC_RENEWAL_DATE]: () =>
    billingAccountsJfrogaccSubscriptionsJfrogaccRenewalDate(),
  [BILLING_ACCOUNTS_JFROGACC_INVOICES_LIMIT_MONTH]: () =>
    billingAccountsJfrogaccInvoicesLimitMonth(),
};
