import { GetEventChannelResponse } from "@jfrog-ba/myjfrog-common/dist/in-app-notifications";
import { getEmail } from "@shared/services/tour/mockedApis/helpers.ts";

const json = import("../json/inAppNotificationsEventChannel.json");

export default async () => {
  const parsedJson = JSON.parse(
    JSON.stringify(await json),
  ) as GetEventChannelResponse;

  parsedJson.email = await getEmail();

  return parsedJson;
};
