import { DateTime } from "luxon";

export const isUsagePage = (data?: object) => {
  const dataObj = data as {
    period: string;
    startDate: string;
    endDate: string;
  };

  return (
    DateTime.now().startOf("month").minus({ day: 1 }).toFormat("yyyy-LL-dd") ===
      dataObj.endDate &&
    DateTime.now()
      .startOf("month")
      .minus({ month: 1 })
      .toFormat("yyyy-LL-dd") === dataObj.startDate
  );
};

export const calculateValue = (value: number, condition?: string) => {
  if (condition === "storage") {
    return value === 0 ? value : value / 500;
  }
  return value === 0 ? value : value / 30;
};
