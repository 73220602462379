import { defineStore } from "pinia";
import {
  NotificationsBySubscription,
  UntypedNotificationSettingsModel,
} from "@jfrog-ba/myjfrog-common";
import { NotificationHistory } from "@shared/types/localTypes/notifications-center.ts";
import { DateTime } from "luxon";

export interface NotificationsCenterStoreStates {
  _notificationsBySubscriptions: NotificationsBySubscription[];
  _notificationsAreLoading: boolean;
  _historyItems: NotificationHistory[];
  _initialHistoryStartDate: DateTime;
  _initialHistoryEndDate: DateTime;
  _historyStartDate: DateTime;
  _historyEndDate: DateTime;
  _historyIsLoading: boolean;
}

const initialHistoryStartDate = DateTime.now()
  .minus({ month: 1 })
  .startOf("day");
const initialHistoryEndDate = DateTime.now();

export const useNotificationsCenterStore = defineStore({
  id: "notifications-center",
  state: (): NotificationsCenterStoreStates => ({
    _notificationsBySubscriptions: [],
    _historyItems: [],
    _initialHistoryStartDate: initialHistoryStartDate,
    _initialHistoryEndDate: initialHistoryEndDate,
    _historyStartDate: initialHistoryStartDate,
    _historyEndDate: initialHistoryEndDate,
    _notificationsAreLoading: true,
    _historyIsLoading: true,
  }),
  getters: {
    notificationsBySubscriptions: (state) =>
      state._notificationsBySubscriptions,
    notificationsAreLoading: (state) => state._notificationsAreLoading,
    historyItems: (state) => state._historyItems,
    initialHistoryStartDate: (state) => state._initialHistoryStartDate,
    initialHistoryEndDate: (state) => state._initialHistoryEndDate,
    historyStartDate: (state) => state._historyStartDate,
    historyEndDate: (state) => state._historyEndDate,
    historyIsLoading: (state) => state._historyIsLoading,
  },
  actions: {
    setNotificationsAreLoading(isLoading: boolean) {
      this._notificationsAreLoading = isLoading;
    },
    setNotificationsBySubscriptions(
      notificationsBySubscriptions: NotificationsCenterStoreStates["_notificationsBySubscriptions"],
    ) {
      this._notificationsBySubscriptions = notificationsBySubscriptions;
    },
    updateNotificationsForSubscription(
      notificationsForSubscription: NotificationsBySubscription | null,
    ) {
      if (!notificationsForSubscription) {
        return;
      }
      const indexOfItemToUpdate = this._notificationsBySubscriptions.findIndex(
        (item) =>
          item.subscriptionNumber ===
          notificationsForSubscription.subscriptionNumber,
      );
      if (indexOfItemToUpdate === -1) {
        return;
      }
      this._notificationsBySubscriptions[indexOfItemToUpdate] =
        notificationsForSubscription;
    },
    updateNotificationItem(
      subscriptionNumber: string,
      updatedNotificationItem: UntypedNotificationSettingsModel,
    ) {
      const indexOfNotificationGroupToUpdate =
        this._notificationsBySubscriptions.findIndex(
          (item) => item.subscriptionNumber === subscriptionNumber,
        );
      if (indexOfNotificationGroupToUpdate === -1) {
        return;
      }
      this._notificationsBySubscriptions[indexOfNotificationGroupToUpdate] = {
        ...this._notificationsBySubscriptions[indexOfNotificationGroupToUpdate],
        notifications: [
          ...this._notificationsBySubscriptions[
            indexOfNotificationGroupToUpdate
          ].notifications.filter(
            (notification) =>
              notification.uuid !== updatedNotificationItem.uuid,
          ),
          updatedNotificationItem,
        ],
      };
    },
    removeNotificationItem(subscriptionNumber: string, uuidToRemove: string) {
      const indexOfNotificationGroupToUpdate =
        this._notificationsBySubscriptions.findIndex(
          (item) => item.subscriptionNumber === subscriptionNumber,
        );
      if (indexOfNotificationGroupToUpdate === -1) {
        return;
      }
      this._notificationsBySubscriptions[indexOfNotificationGroupToUpdate] = {
        ...this._notificationsBySubscriptions[indexOfNotificationGroupToUpdate],
        notifications: this._notificationsBySubscriptions[
          indexOfNotificationGroupToUpdate
        ].notifications.filter(
          (notification) => notification.uuid !== uuidToRemove,
        ),
      };
    },
    setHistoryDates(startDate: DateTime, endDate: DateTime) {
      this._historyStartDate = startDate;
      this._historyEndDate = endDate;
    },
    setHistoryItems(items: NotificationHistory[]) {
      this._historyItems = items;
    },
    setHistoryIsLoading(isLoading: boolean) {
      this._historyIsLoading = isLoading;
    },
  },
});
