import { app } from "./plugins/main-app";
import "./plugins/load-el-plus-icons";
import { pinia } from "@/plugins/pinia.ts";
import "./plugins/router";
import "./plugins/eventBus";
import "./plugins/directives";
import "./plugins/firebase";

app.use(pinia);
export default app.mount("#app");
