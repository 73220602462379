<template>
  <transition name="fade">
    <div
      v-if="globalLoaderStore.isDisplayed"
      class="global-loader--wrapper flex justify-center items-center"
      :class="themeClass"
    >
      <div class="brand-icon">
        <span class="mjf-icon jfrog icon-85" />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useGlobalLoaderStore } from "@shared/stores/globalLoader";
import { computed } from "vue";

const globalLoaderStore = useGlobalLoaderStore();
const themeClass = computed(() => `theme-${globalLoaderStore.getTheme}`);
</script>
<style lang="scss" scoped>
@use "@shared/assets/styles/abstracts/functions" as f;
.global-loader--wrapper {
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: f.color("layout-bg-color");
  .brand-icon {
    color: f.color("loader-splashscreen-icon-dark");
    animation: loader-scale-animation 1s infinite alternate;
  }
  &.theme-platform {
    background: #{linear-gradient(
        132.49deg,
        f.color("loader-splashscreen-bg-gradient-1") 14.17%,
        f.color("loader-splashscreen-bg-gradient-2") 83.36%
      )};
    .brand-icon {
      color: f.color("loader-splashscreen-icon");
    }
  }
}

@keyframes loader-scale-animation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
