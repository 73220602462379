import { defineStore } from "pinia";
import { type PermissionState } from "@capacitor/core";
export interface PushNotificationsPermissionStoreState {
  _token: string | null;
  _permissionState: PermissionState | null;
  _permissionIsChecked: boolean;
}

export const usePushNotificationsPermissionStore = defineStore({
  id: "push-notification-permissions",
  state: () =>
    ({
      _token: null,
      _permissionState: null,
      _permissionIsChecked: false,
    }) as PushNotificationsPermissionStoreState,
  getters: {
    token: (state): PushNotificationsPermissionStoreState["_token"] =>
      state._token,
    permissionIsChecked: (
      state,
    ): PushNotificationsPermissionStoreState["_permissionIsChecked"] =>
      state._permissionIsChecked,
    permissionState: (
      state,
    ): PushNotificationsPermissionStoreState["_permissionState"] =>
      state._permissionState,
    canAskPermission: (state): boolean =>
      !!state._permissionState &&
      ["prompt", "prompt-with-rationale"].includes(state._permissionState),
  },
  actions: {
    setToken(token: PushNotificationsPermissionStoreState["_token"]) {
      this._token = token;
    },
    setPermissionIsChecked(
      permissionIsChecked: PushNotificationsPermissionStoreState["_permissionIsChecked"],
    ) {
      this._permissionIsChecked = permissionIsChecked;
    },
    setPermissionState(
      permissionState: PushNotificationsPermissionStoreState["_permissionState"],
    ) {
      this._permissionState = permissionState;
    },
  },
});
