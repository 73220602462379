export const SUBSCRIPTIONS_URL = "subscriptions";
export const MAINTENANCE_STATUS_URL = `maintenance-status`;
export const USER_URL = "user";
export const USER_VALIDATE_EMAIL = "user/validateEmail";
export const SESSION_GET_LINKED_SESSION = "session/getLinkedSession";
export const SUBSCRIPTIONS_TYPES = "subscriptions/*/types";
export const USERPREF_GET_NOTIFICATIONS_BY_SUBSCRIPTION_NUMBERS =
  "userpref/get-notifications-by-subscription-numbers";
export const IN_APP_NOTIFICATIONS_UNREAD = "in-app-notifications/unread";
export const REGISTRATIONS_GET_RATE_PLAN_TIERS =
  "registrations/getRatePlanTiers";
export const IN_APP_NOTIFICATIONS_EVENT_CHANNEL =
  "in-app-notifications/event-channel";
export const ERROR_LOGGER = "error-logger";
export const FEATURE_FLAGS_SUBSCRIPTION_LOAD =
  "feature-flags/subscription/*/load";
export const JMIS_SSL = "jmis/ssl/*";
export const JMIS_ROUTING_POLICY = "jmis/routing_policy/*";
export const USER_GET_ADMIN_API_TOKEN = "user/getAdminApiToken";
export const FORECAST_PREDICTIONS = "forecast/predictions";
export const SUBSCRIPTIONS_REGIONS_LIST = "subscriptions/regionsList";
export const USAGE_EXPOSER_STATISTICS = "usage-exposer/*/statistics";
export const BILLING_ACCOUNTS_JFROGACC_ACCOUNT_INFO =
  "billing-accounts/JFROGACC-*/accountInfo";
export const BILLING_ACCOUNTS_JFROGACC_SUBSCRIPTIONS_JFROGACC =
  "billing-accounts/JFROGACC-*/subscriptions/JFROGACC--S*";
export const BILLING_ACCOUNTS_JFROGACC_SUBSCRIPTIONS_JFROGACC_RENEWAL_DATE =
  "billing-accounts/JFROGACC-*/subscriptions/JFROGACC--S*/renewalDate";
export const BILLING_ACCOUNTS_JFROGACC_INVOICES_LIMIT_MONTH =
  "billing-accounts/JFROGACC-*/invoices?limitMonth=*";
export const USAGE_EXPOSER_GET_DAILY_CURATED_PACKAGES_VERDICT =
  "usage-exposer/get-daily-curated-packages-verdict";
export const USAGE_EXPOSER_AGGREGATED_USAGE_BREAKDOWN =
  "usage-exposer/*/AggregatedUsageBreakdown";
export const USAGE_EXPOSER_GET_AGGREGATED_TRAFFIC_BY_PROJECT =
  "usage-exposer/get-aggregated-traffic-by-project";
export const USAGE_EXPOSER_GET_AGGREGATED_TRAFFIC_BY_PACKAGE_TYPE =
  "usage-exposer/get-aggregated-traffic-by-package-type";
export const HEALTH_STATUS_GET_HEALTH_STATUS = "healthStatus/get-health-status";
export const SALESFORCE_SUPPORT_REPRESENTATIVES =
  "salesforce/*/support-representatives";
export const USAGE_EXPOSER_GET_REPOSITORIES_TRAFFIC_FOR_PERIOD =
  "usage-exposer/get-repositories-traffic-for-period";
export const USAGE_EXPOSER_GET_PROJECTS_TRAFFIC_FOR_PERIOD =
  "usage-exposer/get-projects-traffic-for-period";
export const USAGE_EXPOSER_GET_PACKAGES_TRAFFIC_FOR_PERIOD =
  "usage-exposer/get-packages-traffic-for-period";
export const USAGE_EXPOSER_SH_STATISTICS = "usage-exposer/*/sh-statistics";
export const USAGE_EXPOSER_GET_SERVER_DAILY_AGGREGATED_USAGE =
  "usage-exposer/*/getServerDailyAggregatedUsage";
export const USAGE_EXPOSER_GET_AGGREGATED_SCANS =
  "usage-exposer/*/getAggregatedScans";

export const SUBSCRIPTIONS_GET_SERVERS_STATUS =
  "subscriptions/*/getServersStatus";
export const SUBSCRIPTIONS_GET_SERVERS_META_DATA =
  "subscriptions/*/getServersMetaData";

export const SALESFORCE_CONTACTS = "salesforce/*/contacts";
export const SALESFORCE_QUOTA = "salesforce/*/quota";
export const USER_LOGOUT = "user/logout";
export const USER_LOGIN = "user/login?engine=WEB&mode=tour";
export const NOTIFICATIONS_HISTORY_SUBSCRIPTION_SENT_GET =
  "notifications-history/subscription/*/sent/get";
