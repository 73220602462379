import { calculateValue } from "@shared/services/tour/mockedApis/usageExposer/helper.ts";
import { GetProjectsTrafficForPeriodV2Response } from "@shared/services/usageExposer/getProjectsTrafficForPeriod.ts";

const json = import(
  "../../json/usageExposer/dashboard/usageExposerGetProjectsTrafficForPeriod.json"
);

const updateUsage = <T extends GetProjectsTrafficForPeriodV2Response>(
  usageExposerStatisticsV2Response: T,
  updateCallback: (value: number) => number,
): T => {
  usageExposerStatisticsV2Response.projects.forEach((server) => {
    server.trafficBytes = updateCallback(server.trafficBytes);
  });

  return usageExposerStatisticsV2Response;
};

export default async () =>
  updateUsage(JSON.parse(JSON.stringify(await json)), calculateValue);
