import {
  calculateValue,
  isUsagePage,
} from "@shared/services/tour/mockedApis/usageExposer/helper.ts";
import { GetAggregatedTrafficByProjectV2Response } from "@shared/services/usageExposer/getAggregatedTrafficPerProject.ts";

const jsonDashboard = import(
  "../../json/usageExposer/dashboard/usageExposerGetAggregatedTrafficByProject.json"
);

const jsonUsage = import(
  "../../json/usageExposer/usagePage/usageExposerGetAggregatedTrafficByProject.json"
);

const updateUsage = <T extends GetAggregatedTrafficByProjectV2Response>(
  response: T,
  updateCallback: (value: number) => number,
): T => {
  response.traffic.top.forEach((statistic) => {
    statistic.totalDataTransferBytes = updateCallback(
      statistic.totalDataTransferBytes,
    );
    statistic.totalDownloadBytes = updateCallback(statistic.totalDownloadBytes);
    statistic.totalUploadBytes = updateCallback(statistic.totalUploadBytes);

    statistic.serviceAggregatedTrafficList.forEach((service) => {
      service.totalDataTransferBytes = updateCallback(
        service.totalDataTransferBytes,
      );
      service.totalUploadBytes = updateCallback(service.totalUploadBytes);
      service.totalDownloadBytes = updateCallback(service.totalDownloadBytes);
    });
  });

  const remaining = response.traffic.remaining;

  if (remaining) {
    remaining.totalDataTransferBytes = updateCallback(
      remaining.totalDataTransferBytes,
    );
    remaining.totalDownloadBytes = updateCallback(remaining.totalDownloadBytes);
    remaining.totalUploadBytes = updateCallback(remaining.totalUploadBytes);

    remaining.serviceAggregatedTrafficList.forEach((service) => {
      service.totalDataTransferBytes = updateCallback(
        service.totalDataTransferBytes,
      );
      service.totalUploadBytes = updateCallback(service.totalUploadBytes);
      service.totalDownloadBytes = updateCallback(service.totalDownloadBytes);
    });
  }

  return response;
};

export default async (data?: object) => {
  if (isUsagePage(data)) {
    return updateUsage(
      JSON.parse(JSON.stringify(await jsonUsage)),
      calculateValue,
    );
  }

  return updateUsage(
    JSON.parse(JSON.stringify(await jsonDashboard)),
    calculateValue,
  );
};
