import { defineStore } from "pinia";

interface NotificationConfigItem {
  type: "success" | "warning" | "error" | "danger";
  title: string;
  message: string;
}

interface StoredNotificationConfigItem extends NotificationConfigItem {
  key: string;
}

interface ToastNotificationsStoreState {
  visibleNotifications: StoredNotificationConfigItem[];
  _errorLocked: boolean;
}

export const useToastNotificationsStore = defineStore({
  id: "toastNotifications",
  state: (): ToastNotificationsStoreState => ({
    visibleNotifications: [],
    _errorLocked: false,
  }),
  getters: {
    getVisibleNotifications: (state) => state.visibleNotifications,
    errorLocked: (state) => state._errorLocked,
  },
  actions: {
    addVisibleNotification(item: StoredNotificationConfigItem) {
      this.visibleNotifications.push(item);
    },
    removeVisibleNotification(
      keyToRemove: StoredNotificationConfigItem["key"],
    ) {
      this.visibleNotifications = this.visibleNotifications.filter(
        (visibleNotification) => visibleNotification.key !== keyToRemove,
      );
    },
    isNotificationVisible({ type, title, message }: NotificationConfigItem) {
      return this.visibleNotifications.some(
        (visibleNotification) =>
          visibleNotification.type === type &&
          visibleNotification.title === title &&
          visibleNotification.message === message,
      );
    },
    lockError() {
      this._errorLocked = true;
    },
    unlockError() {
      this._errorLocked = false;
    },
  },
});
