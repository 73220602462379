import { ServerDailyAggregatedUsageV2Response } from "@shared/services/usageExposer/serverDailyAggregatedUsage.ts";
import { DateTime } from "luxon";
import { calculateValue } from "@shared/services/tour/mockedApis/usageExposer/helper.ts";

const json = import(
  "../../json/usageExposer/usagePage/usageExposerGetServerDailyAggregatedUsage.json"
);

const updateDate = (
  response: ServerDailyAggregatedUsageV2Response,
  callback: (index: number) => string,
) => {
  response.traffic.forEach((traffic, index) => {
    traffic.date = callback(index + 1);
  });

  response.traffic.reverse();
  return response;
};

const updateUsage = <T extends ServerDailyAggregatedUsageV2Response>(
  usageExposerStatisticsV2Response: T,
  updateCallback: (value: number) => number,
): T => {
  usageExposerStatisticsV2Response.traffic.forEach((server) => {
    server.top.forEach((statistic) => {
      statistic.totalDataTransferBytes = updateCallback(
        statistic.totalDataTransferBytes,
      );
    });
    if (server.remaining) {
      server.remaining.totalDataTransferBytes = updateCallback(
        server.remaining.totalDataTransferBytes,
      );
    }
  });

  return usageExposerStatisticsV2Response;
};

export default async () => {
  const parse = updateUsage(
    JSON.parse(JSON.stringify(await json)),
    calculateValue,
  );

  return updateDate(parse, (index) =>
    DateTime.now()
      .startOf("month")
      .minus({ day: index })
      .toFormat("yyyy-LL-dd"),
  );
};
