import { useSupportRepresentativesStore } from "@shared/stores/supportRepresentatives.ts";
import { useNotifications } from "@shared/module-transformers/useNotifications.ts";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { salesforce } from "@shared/services/salesforce.ts";
import { watch } from "vue";
import logger from "@shared/utils/logger.ts";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";

export const useSupportRepresentativesLoader = () => {
  const subscriptionsStore = useSubscriptionsStore();
  const supportRepresentativesStore = useSupportRepresentativesStore();

  const loadSupportRepresentatives = async (
    currentSubscriptionNumber: SubscriptionDTO["accountNumber"],
  ) => {
    try {
      supportRepresentativesStore.setIsLoading(true);

      const supportRepresentatives = await salesforce.getSupportRepresentatives(
        currentSubscriptionNumber,
      );
      supportRepresentativesStore.setSupportRepresentatives(
        supportRepresentatives,
      );

      supportRepresentativesStore.setIsLoading(false);
    } catch (e) {
      logger.error(e);
      supportRepresentativesStore.setIsLoading(false);
      supportRepresentativesStore.setSupportRepresentatives([]);
      useNotifications().error("Failed to fetch support representatives");
    }
  };

  watch(
    () => subscriptionsStore.getCurrentSubscriptionNumberInt,
    (currentSubscriptionNumber) => {
      if (!currentSubscriptionNumber) {
        return;
      }
      loadSupportRepresentatives(currentSubscriptionNumber);
    },
    {
      immediate: true,
    },
  );
  return {};
};
