import { defineStore } from "pinia";

export type FeaturesState = {
  _isCnameEnabled: boolean;
  _isDnsManualFailoverPolicyEnabled: boolean;
  _isSaasTokenEnabled: boolean;
};
export const useFeaturesStore = defineStore("features", {
  state: (): FeaturesState => ({
    _isCnameEnabled: false,
    _isDnsManualFailoverPolicyEnabled: false,
    _isSaasTokenEnabled: false,
  }),
  getters: {
    isCnameEnabled: (state: FeaturesState) => {
      return state._isCnameEnabled;
    },
    isDnsManualFailoverPolicyEnabled: (state: FeaturesState) => {
      return state._isDnsManualFailoverPolicyEnabled;
    },
    isSaasTokenEnabled: (state: FeaturesState) => {
      return state._isSaasTokenEnabled;
    },
  },
  actions: {
    setIsCnameEnabled(isCnameEnabled: FeaturesState["_isCnameEnabled"]) {
      this._isCnameEnabled = isCnameEnabled;
    },
    setIsDnsManualFailoverPolicyEnabled(
      isDnsManualFailoverPolicyEnabled: FeaturesState["_isDnsManualFailoverPolicyEnabled"],
    ) {
      this._isDnsManualFailoverPolicyEnabled = isDnsManualFailoverPolicyEnabled;
    },
    setIsSaasTokenEnabled(
      isSaasTokenEnabled: FeaturesState["_isSaasTokenEnabled"],
    ) {
      this._isSaasTokenEnabled = isSaasTokenEnabled;
    },
  },
});
