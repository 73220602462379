import { keepOnlyNumbers } from "@shared/utils/tools.ts";

export function useRootCssVar() {
  const globalTransitionDuration = Number(
    keepOnlyNumbers(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--global-transition-duration",
      ),
    ) || 0,
  );

  const menuWidth = getComputedStyle(document.documentElement).getPropertyValue(
    "--menu-width",
  );

  const closedMenuWidth = getComputedStyle(
    document.documentElement,
  ).getPropertyValue("--menu-closed-width");

  const topBarGlobalNotificationHeightWithUnit = getComputedStyle(
    document.documentElement,
  ).getPropertyValue("--topBar-globalNotification-height");

  const topBarHeight = getComputedStyle(
    document.documentElement,
  ).getPropertyValue("--topBar-height");

  const pricingPageStickyHeaderHeight = cssVarToNumber(
    "--pricing-stickyHeader-height",
  );

  const breakpointsMobileInt = cssVarToNumber("--breakpoints-mobile");
  const breakpointsTabletInt = cssVarToNumber("--breakpoints-tablet");
  const breakpointsLaptopInt = cssVarToNumber("--breakpoints-laptop");
  const breakpointsDesktopInt = cssVarToNumber("--breakpoints-desktop");

  const pricingPageFooterHeight = cssVarToNumber("--pricing-footer-height");

  return {
    globalTransitionDuration,
    menuWidth,
    closedMenuWidth,
    topBarGlobalNotificationHeightWithUnit,
    topBarHeight,
    pricingPageStickyHeaderHeight,
    pricingPageFooterHeight,
    breakpointsMobileInt,
    breakpointsTabletInt,
    breakpointsLaptopInt,
    breakpointsDesktopInt,
  };
}

const cssVarToNumber = (cssVar: string) =>
  Number(
    keepOnlyNumbers(
      getComputedStyle(document.documentElement).getPropertyValue(cssVar),
    ) || 0,
  );
