import {
  SUBSCRIPTIONS_GET_SERVERS_META_DATA,
  SUBSCRIPTIONS_GET_SERVERS_STATUS,
} from "@shared/services/tour/urls/dashboard-urls.ts";
import subscriptionsGetServersStatus from "@shared/services/tour/mockedApis/subscriptionsGetServersStatus.ts";
import subscriptionsGetServersMetaData from "@shared/services/tour/mockedApis/subscriptionsGetServersMetaData.ts";

export default {
  [SUBSCRIPTIONS_GET_SERVERS_STATUS]: (data?: object) =>
    subscriptionsGetServersStatus(data),
  [SUBSCRIPTIONS_GET_SERVERS_META_DATA]: (data?: object) =>
    subscriptionsGetServersMetaData(data),
};
