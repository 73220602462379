<template>
  <div class="w-full h-full">
    <GlobalLoader />
    <MobileViewNotSupported v-if="displayMobileNotSupportedDisclaimer" />
    <router-view v-else />
  </div>
</template>

<script setup lang="ts">
import GlobalLoader from "@/components/loaders/GlobalLoader.vue";
import MobileViewNotSupported from "@/components/MobileViewNotSupported.vue";
import { appService } from "@shared/services/app";
import { useScreenSize } from "@shared/use/useScreenSize";
import { computed, watch } from "vue";
import { useResponsiveStore } from "@shared/stores/responsive";
import { useAppEventListener } from "@/use/useAppEventListener";
import useThemeInitializer from "@/use/useThemeInitializer";
import useGenVersionInitializer from "@/use/useGenVersionInitializer.ts";
import useMenuInitializer from "@/use/useMenuInitializer";
import { useErrorLogsInitializer } from "@/use/useErrorLogsInitializer.ts";
import { pinia } from "@/plugins/pinia.ts";
import { useRouter } from "vue-router";
import { RouteNames } from "@shared/types/localTypes/router.ts";
import { useMaintenanceStore } from "@shared/stores/maintenance.ts";
import { useFeatureFlagHelper } from "@shared/use/useFeatureFlagHelper.ts";
import useVersionInitializer from "@shared/use/version/useVersionInitializer.ts";
import { useSupportTicketsLoader } from "@shared/use/useSupportTicketsLoader.ts";
import { useSupportRepresentativesLoader } from "@shared/use/useSupportRepresentativeLoader.ts";
import { useSessionLoginListener } from "@shared/use/useSessionLoginListener.ts";
import { useSessionLogic } from "@shared/use/useSessionLogic.ts";

const { isMobile } = useScreenSize();
const responsiveStore = useResponsiveStore(pinia);
const router = useRouter();

appService.setApplicationStartDate();
useErrorLogsInitializer();
useAppEventListener().subscribeAll();
useThemeInitializer();
useGenVersionInitializer();
useVersionInitializer();
useMenuInitializer();
useFeatureFlagHelper();
useSessionLoginListener();
useSupportTicketsLoader();
useSupportRepresentativesLoader();

useSessionLogic().unlockReloadWithDelay();

watch(
  () => useMaintenanceStore().isMyJFUnderMaintenanceState,
  (isMyJFUnderMaintenanceState) => {
    if (!isMyJFUnderMaintenanceState) return;

    let retries = 0;
    const intervalId = setInterval(() => {
      if (retries >= 20) {
        clearInterval(intervalId);
        return;
      }

      if (router.currentRoute.value.name === RouteNames.Maintenance) return;

      router.push({
        name: RouteNames.Maintenance,
      });

      retries++;
    }, 1000);
  },
  { immediate: true },
);

const displayMobileNotSupportedDisclaimer = computed(() => {
  return isMobile.value && !responsiveStore.skipMobileMaskDisclaimer;
});
</script>
<style lang="scss">
@use "./assets/styles/main" as *;
</style>
