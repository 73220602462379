import {
  ERROR_LOGGER,
  FEATURE_FLAGS_SUBSCRIPTION_LOAD,
  FORECAST_PREDICTIONS,
  HEALTH_STATUS_GET_HEALTH_STATUS,
  IN_APP_NOTIFICATIONS_EVENT_CHANNEL,
  IN_APP_NOTIFICATIONS_UNREAD,
  JMIS_ROUTING_POLICY,
  JMIS_SSL,
  MAINTENANCE_STATUS_URL,
  REGISTRATIONS_GET_RATE_PLAN_TIERS,
  SALESFORCE_SUPPORT_REPRESENTATIVES,
  SUBSCRIPTIONS_REGIONS_LIST,
  SUBSCRIPTIONS_TYPES,
  SUBSCRIPTIONS_URL,
} from "@shared/services/tour/urls/dashboard-urls.ts";

import maintenanceStatusResponse from "@shared/services/tour/mockedApis/maintenanceStatusResponse.ts";
import subscriptionsResponse from "@shared/services/tour/mockedApis/subscriptions.ts";
import types from "@shared/services/tour/mockedApis/types.ts";
import inAppNotificationsUnread from "@shared/services/tour/mockedApis/inAppNotificationsUnread.ts";
import registrationsGetRatePlanTiers from "@shared/services/tour/mockedApis/registrationsGetRatePlanTiers.ts";
import inAppNotificationsEventChannel from "@shared/services/tour/mockedApis/inAppNotificationsEventChannel.ts";
import errorLogger from "@shared/services/tour/mockedApis/errorLogger.ts";
import featureFlagsSubscriptionLoad from "@shared/services/tour/mockedApis/featureFlagsSubscriptionLoad.ts";
import jmisSsl from "@shared/services/tour/mockedApis/jmisSsl.ts";
import jmisRoutingPolicy from "@shared/services/tour/mockedApis/jmisRoutingPolicy.ts";
import forecastPredictions from "@shared/services/tour/mockedApis/forecastPredictions.ts";
import subscriptionsRegionsList from "@shared/services/tour/mockedApis/subscriptionsRegionsList.ts";
import healthStatusGetHealthStatus from "@shared/services/tour/mockedApis/healthStatusGetHealthStatus.ts";
import salesforceSupportRepresentatives from "@shared/services/tour/mockedApis/salesforceSupportRepresentatives.ts";

export default {
  [MAINTENANCE_STATUS_URL]: () => maintenanceStatusResponse(),
  [SUBSCRIPTIONS_URL]: () => subscriptionsResponse(),
  [SUBSCRIPTIONS_TYPES]: () => types(),
  [IN_APP_NOTIFICATIONS_UNREAD]: () => inAppNotificationsUnread(),
  [REGISTRATIONS_GET_RATE_PLAN_TIERS]: () => registrationsGetRatePlanTiers(),
  [IN_APP_NOTIFICATIONS_EVENT_CHANNEL]: () => inAppNotificationsEventChannel(),
  [ERROR_LOGGER]: () => errorLogger(),
  [FEATURE_FLAGS_SUBSCRIPTION_LOAD]: () => featureFlagsSubscriptionLoad(),
  [JMIS_SSL]: () => jmisSsl(),
  [JMIS_ROUTING_POLICY]: () => jmisRoutingPolicy(),
  [FORECAST_PREDICTIONS]: () => forecastPredictions(),
  [SUBSCRIPTIONS_REGIONS_LIST]: () => subscriptionsRegionsList(),
  [HEALTH_STATUS_GET_HEALTH_STATUS]: () => healthStatusGetHealthStatus(),
  [SALESFORCE_SUPPORT_REPRESENTATIVES]: () =>
    salesforceSupportRepresentatives(),
};
