import { UsageExposerStatisticsV2Response } from "@shared/services/usageExposer/usageExposerStatistics.ts";
import DateUtils from "@shared/utils/date-utils.ts";
import { DateTime } from "luxon";
import dateUtils from "@shared/utils/date-utils.ts";
import {
  calculateValue,
  isUsagePage,
} from "@shared/services/tour/mockedApis/usageExposer/helper.ts";

const json6Months = import(
  "../../json/usageExposer/dashboard/usageExposerStatistics6Months.json"
);
const json1MonthsDashboard = import(
  "../../json/usageExposer/dashboard/usageExposerStatistics1Month.json"
);

const json1MonthsUsage = import(
  "../../json/usageExposer/usagePage/usageExposerStatistics1Month.json"
);

const updateDate = (
  usageExposerStatisticsV2Response: UsageExposerStatisticsV2Response,
  callback: (index: number) => string,
) => {
  usageExposerStatisticsV2Response.serverStatistics.map((server) => {
    server.statistics.map((statistic, index) => {
      statistic.date = callback(index);
    });
  });
  return usageExposerStatisticsV2Response;
};

const updateUsage = <T extends UsageExposerStatisticsV2Response>(
  usageExposerStatisticsV2Response: T,
  updateCallback: (value: number, condition?: string) => number,
): T => {
  usageExposerStatisticsV2Response.serverStatistics.forEach((server) => {
    server.statistics.forEach((statistic) => {
      statistic.totalXrayTrafficBytes = updateCallback(
        statistic.totalXrayTrafficBytes,
      );
      statistic.totalArtifactoryTrafficBytes = updateCallback(
        statistic.totalArtifactoryTrafficBytes,
      );
      statistic.totalStorage = updateCallback(
        statistic.totalStorage,
        "storage",
      );
      statistic.totalTrafficBytes = updateCallback(statistic.totalTrafficBytes);
    });
  });

  return usageExposerStatisticsV2Response;
};

const updateScans = <T extends UsageExposerStatisticsV2Response>(
  usageExposerStatisticsV2Response: T,
  scanArr: number[] = [
    50, 40, 80, 50, 40, 30, 10, 15, 40, 60, 70, 80, 90, 100, 10, 30, 0, 40, 80,
    50, 40, 30, 10, 15, 40, 60, 70, 80, 40, 0, 50,
  ],
): T => {
  usageExposerStatisticsV2Response.serverStatistics.forEach((server, index) => {
    if (index === 0) {
      server.statistics.forEach((statistic, index) => {
        statistic.totalScans = scanArr[index];
      });
    }
  });

  return usageExposerStatisticsV2Response;
};

export default async (data?: object) => {
  const json6MonthsData = updateUsage(
    JSON.parse(
      JSON.stringify(await json6Months),
    ) as UsageExposerStatisticsV2Response,
    calculateValue,
  );

  const json1MonthsDashboardData = updateUsage(
    JSON.parse(
      JSON.stringify(await json1MonthsDashboard),
    ) as UsageExposerStatisticsV2Response,
    calculateValue,
  );

  const json1MonthsUsageData = updateUsage(
    JSON.parse(
      JSON.stringify(await json1MonthsUsage),
    ) as UsageExposerStatisticsV2Response,
    calculateValue,
  );

  const dataObj = data as {
    period: string;
  };

  if (dataObj && dataObj.period === "daily") {
    if (isUsagePage(data)) {
      return updateScans(
        updateDate(
          json1MonthsUsageData as UsageExposerStatisticsV2Response,
          (index) =>
            dateUtils
              .now(
                DateTime.now()
                  .startOf("month")
                  .minus({ day: index + 1 }),
              )
              .toFormat("yyyy-LL-dd"),
        ),
        [
          2516, 2803, 3212, 2892, 750, 488, 2736, 3149, 3216, 2594, 2474, 960,
          376, 2631, 3357, 3314, 2873, 2931, 1244, 457, 2178, 3338, 3267, 2868,
          636, 344, 2834, 3080, 3241, 2642,
        ],
      );
    }

    return updateScans(
      updateDate(
        json1MonthsDashboardData as UsageExposerStatisticsV2Response,
        DateUtils.returnDateFromToday,
      ),
      [
        2516, 2803, 3212, 2892, 750, 488, 2736, 3149, 3216, 2594, 2474, 960,
        376, 2631, 3357, 3314, 2873, 2931, 1244, 457, 2178, 3338, 3267, 2868,
        636, 344, 2834, 3080, 3241, 2642,
      ],
    );
  }

  return updateDate(
    json6MonthsData as UsageExposerStatisticsV2Response,
    DateUtils.firstOfPreviousMonth,
  );
};
