import type { GraphDatePeriod } from "@shared/types/localtypes";
import type { SubscriptionGraphItemProps } from "@shared/types/localTypes/graphs";
import type { ChartDataset } from "chart.js";
import { DateTime } from "luxon";
import dateUtils from "./date-utils";
import myJFrogUtils from "./myJFrogUtils";
import { RouteNames } from "@shared/types/localTypes/router";
import type { Router } from "vue-router";
import { Period } from "@shared/services/usageExposer/common-types.ts";
import { UsageExposerStatisticsV2Response } from "@shared/services/usageExposer/usageExposerStatistics.ts";
import { UsageExposerSelfHostedStatisticsV2Response } from "@shared/services/usageExposer/usageExposerSelfHostedStatistics.ts";

export type StatisticsNames = Exclude<
  keyof UsageExposerStatisticsV2Response["serverStatistics"][0]["statistics"][0],
  "date"
>;

const graphUtils = {
  // create a new object with { key as date and value }.
  parser(data: number, key: string, container: { [key: string]: number }) {
    const value = data;

    if (!container[key]) {
      container[key] = 0;
    }

    container[key] += value;
  },

  accumulateTotalScans(
    accumulator: number,
    currentValue: UsageExposerStatisticsV2Response["serverStatistics"][0]["statistics"][0],
    statisticsValues: StatisticsNames[],
  ) {
    let value = accumulator;
    if (statisticsValues.length === 0) {
      return value;
    }
    statisticsValues.forEach((val) => {
      const currentValueElement = currentValue[val];
      if (!currentValueElement) {
        return;
      }
      value += currentValueElement;
    });
    return value;
  },

  accumulateTotalScansSH(
    accumulator: number,
    currentValue: UsageExposerSelfHostedStatisticsV2Response["jpdStatistics"][0]["statistics"][0],
  ) {
    const currentValueElement = currentValue["totalScans"];
    if (!currentValueElement) {
      return accumulator;
    }
    return accumulator + currentValueElement;
  },

  // function that takes statistics names and sums for all servers the data for that statistic name.
  sumCallback(statisticsValues: StatisticsNames[]) {
    return (
      acc: number,
      serverStatistics: UsageExposerStatisticsV2Response["serverStatistics"][0],
    ) =>
      acc +
      serverStatistics.statistics.reduce((accumulator, currentValue) => {
        let value = accumulator;
        if (statisticsValues.length === 0) {
          return value;
        }
        statisticsValues.forEach((val) => {
          const currentValueElement = currentValue[val];
          if (!currentValueElement) {
            return;
          }
          value += currentValueElement;
        });
        return value;
      }, 0);
  },

  calculateGraphDateRangePeriod(
    startTime: number | null,
    endTime: number | null,
  ): GraphDatePeriod {
    const start = startTime ? startTime : new Date().getTime();
    const end = endTime ? endTime : new Date().getTime();
    const startDate = DateTime.fromMillis(start);
    const endDate = DateTime.fromMillis(end);
    const diff = dateUtils.dayDiff(startDate, endDate);

    const isMoreThan2Months = diff.days && diff.days > 61;

    return {
      period: isMoreThan2Months ? Period.MONTHLY : Period.DAILY,
      start: start,
      end: end,
    };
  },

  graphYaxisCustomCallback: (
    tick: number | string,
    divideBy1000 = false,
  ): string => {
    if (parseFloat(tick.toString()) <= 0) {
      return tick.toString();
    }

    if (parseFloat(tick.toString()) < 1)
      return parseFloat(
        myJFrogUtils.formatNumberWithCommas(parseFloat(tick.toString()), 4),
      ).toString();

    return myJFrogUtils.shortenValueWithSymbol(parseFloat(tick.toString()), 1, {
      isSimpleNumbers: divideBy1000,
    });
  },

  refreshTotalData: (
    graphData: SubscriptionGraphItemProps["graphData"] | undefined,
    legendFilter: number[],
  ) => {
    if (!graphData) {
      return [];
    }

    return graphData.datasets
      .filter(
        (dataset: ChartDataset, index: number) =>
          dataset.label && legendFilter.includes(index),
      )
      .map((dataset: ChartDataset) => dataset.label as string);
  },

  getDateArrByLength: (length: number) => {
    const utcNow = DateTime.now().toUTC();
    const datesFull: number[] = [];

    for (let i = 0; i < length; i++) {
      datesFull.push(utcNow.minus({ months: i }).startOf("month").toMillis());
    }

    return datesFull;
  },

  handleGraphClicked: (
    dataIndex: number,
    dates: string[] | undefined,
    router: Router,
    query?: { [key: string]: string | number },
  ) => {
    if (!dates?.length) {
      return;
    }

    if (dates && dates[dataIndex]) {
      const start = DateTime.fromMillis(parseInt(dates[dataIndex]));
      const end = DateTime.fromMillis(parseInt(dates[dataIndex]))
        .toUTC()
        .endOf("month");

      router.push({
        name: RouteNames.Usage,
        query: {
          startDate: start.toMillis(),
          endDate: end.toMillis(),
          ...query,
        },
      });
    }
  },
};

export default graphUtils;
