import { useEventBus } from "@shared/use/useEventBus.ts";
import logger from "@shared/utils/logger";
import { type Router, useRouter } from "vue-router";
import { useNotifications } from "@/use/useNotifications";

export function useAppEventListener() {
  const router = useRouter();
  const subscribeAll = () => {
    useEventBus().subscribe({
      shouldConvertNotificationSpanRouterLink: () =>
        convertNotificationSpanRouterLink(router),
    });
  };
  return { subscribeAll };
}

const convertNotificationSpanRouterLink = (router: Router) => {
  setTimeout(() => {
    const mjfRouterLink = document.getElementsByTagName(
      "mjf-router-link",
    )[0] as HTMLSpanElement;
    if (!mjfRouterLink) {
      logger.warn("No 'mjf-router-link' elements found. Nothing to convert");
      return;
    }
    const to = mjfRouterLink.getAttribute("to");
    if (!to) {
      logger.warn(
        "The 'mjf-router-link' element doesn't contain a 'to' attribute for conversion",
      );
      return;
    }

    mjfRouterLink.removeEventListener("click", () =>
      onNotificationSpanRouterLinkClick(router, to),
    );
    mjfRouterLink.addEventListener("click", () =>
      onNotificationSpanRouterLinkClick(router, to),
    );
  }, 20);
};

const onNotificationSpanRouterLinkClick = (
  router: Router,
  routeName: string,
) => {
  router.push({ name: routeName });
  useNotifications().closeAll();
};
