import { defineStore } from "pinia";
import type { NeedOtpLoginResponse } from "@jfrog-ba/myjfrog-common";

export interface OtpAuthenticationData {
  otpTokenKeyLink: NeedOtpLoginResponse["otpTokenKeyLink"];
  authenticator: NeedOtpLoginResponse["authenticator"];
  fromSSO: boolean;
}

export const useAuthenticationStore = defineStore({
  id: "authentication",
  state: () => ({
    otpAuthenticationData: null as OtpAuthenticationData | null,
  }),
  getters: {
    getOtpAuthenticationData: (state) => state.otpAuthenticationData,
  },
  actions: {
    async setOtpAuthenticationData(
      otpAuthenticationData: OtpAuthenticationData,
    ) {
      this.otpAuthenticationData = otpAuthenticationData;
    },
  },
});
