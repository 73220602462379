
    const env = import.meta.env;
    if (env.DEV) {
      window.MOBILE_VERSION = env.VUE_APP_MOBILE_VERSION;
      window.API_PROXY_URL = env.VUE_APP_API_PROXY_URL;
      window.OPERATION_MODE = env.VUE_APP_OPERATION_MODE;
      window.LANDING_APP_URL = env.VUE_APP_LANDING_APP_URL;
      window.GOOGLE_CLIENT_ID = env.VUE_APP_GOOGLE_CLIENT_ID;
      window.MARKETO_MUNCHKIN_ID = env.VUE_APP_MARKETO_MUNCHKIN_ID;
      window.HEAP_ID = env.VUE_APP_HEAP_ID;
      window.PLATFORM_DOMAIN = env.VUE_APP_PLATFORM_DOMAIN;
      window.KNOWLEDGE_CENTER_URL = env.VUE_APP_KNOWLEDGE_CENTER_URL;
      window.FIREBASE_API_KEY = env.VUE_APP_FIREBASE_API_KEY;
      window.FIREBASE_AUTH_DOMAIN = env.VUE_APP_FIREBASE_AUTH_DOMAIN;
      window.FIREBASE_PROJECT_ID = env.VUE_APP_FIREBASE_PROJECT_ID;
      window.FIREBASE_STORAGE_BUCKET = env.VUE_APP_FIREBASE_STORAGE_BUCKET;
      window.FIREBASE_MESSAGING_SENDER_ID =
        env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID;
      window.FIREBASE_APP_ID = env.VUE_APP_FIREBASE_APP_ID;
    }
    if (
      !(
        // same as line 16. fail if not used
        (
          navigator.userAgent.includes("Electron") ||
          navigator.userAgent.includes("Cypress")
        )
      )
    ) {
      window.heap.load(window.HEAP_ID);
    }
  