import {
  type Entitlement,
  EntitlementName,
} from "@shared/types/localTypes/entitlements";
import {
  type EntitlementDTO,
  type SubscriptionDTO,
} from "@jfrog-ba/myjfrog-common";
import { DateTime } from "luxon";
import { subscriptionsService } from "@shared/services/subscriptions";
import { entitlementsConfig } from "@shared/statics/entitlements";

export const entitlementsService = {
  getEntitlement: (
    subscription: SubscriptionDTO,
    expectedEntitlement: EntitlementName,
  ): Entitlement | undefined => {
    const directEntitlement = findEntitlement(
      subscription.entitlements,
      expectedEntitlement,
    );
    if (directEntitlement) {
      const isTrialSaas =
        subscription.bom?.trial && subscriptionsService.isSaas(subscription);
      return {
        ...directEntitlement,
        trial: subscription.bom?.trial,
        startDate: Number(subscription.startDate),
        endDate: Number(
          isTrialSaas ? subscription.endOfTrial : subscription.expirationDate,
        ),
      };
    }

    for (const addon of subscription.addons) {
      const addonEntitlement = findEntitlement(
        addon.entitlements,
        expectedEntitlement,
      );
      if (addonEntitlement) {
        return {
          ...addonEntitlement,
          trial: addon.bom.trial,
          startDate: Number(addon.startDate),
          endDate: Number(addon.expirationDate),
        };
      }
    }

    return;
  },

  isExpired: (entitlement?: Entitlement) =>
    entitlement &&
    DateTime.fromMillis(entitlement.endDate).startOf("day") <
      DateTime.now().startOf("day"),

  getEntitlementConfig: (entitlementName: string) => {
    return entitlementsConfig.find(
      (config) =>
        config.entitlementName.toLowerCase() === entitlementName.toLowerCase(),
    );
  },
  aggregateEntitlements: (subscription: SubscriptionDTO) => {
    const aggregatedEntitlements = subscription.entitlements;
    subscription.addons.forEach((addon) =>
      addon.entitlements.forEach((addonEntitlement) => {
        if (
          !aggregatedEntitlements.some(
            (item) => item.name === addonEntitlement.name,
          )
        ) {
          aggregatedEntitlements.push(addonEntitlement);
        }
      }),
    );

    return aggregatedEntitlements;
  },
};

const findEntitlement = (
  entitlements: EntitlementDTO[],
  expectedEntitlement: EntitlementName,
) =>
  entitlements.find(
    (entitlement) => entitlement.name.toUpperCase() === expectedEntitlement,
  );
