import type { AxiosError } from "axios";
import { OfferingsResponse } from "@jfrog-ba/myjfrog-common";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAxiosError(arg: any): arg is AxiosError {
  return !!arg.isAxiosError;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isOfferingResponse(arg: any): arg is OfferingsResponse {
  return !!arg.subscriptionTypes;
}
