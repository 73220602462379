import { LocalStorageKeys } from "@shared/types/localTypes/storage";
import {
  type GenVersion,
  useGenVersionStore,
} from "@shared/stores/genVersion.ts";

export default () => {
  const genVersionStore = useGenVersionStore();
  const setGenVersion = (version: GenVersion) => {
    window.localStorage.setItem(LocalStorageKeys.GEN_VERSION, version);
    genVersionStore.setCurrentGenVersion(version);
  };

  return {
    setGenVersion,
  };
};
