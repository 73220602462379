import { DateTime } from "luxon";

const json = import("../json/userGetAdminApiToken.json");

export default async () => {
  const parsedJSON = JSON.parse(JSON.stringify(await json));

  const validDateTime = DateTime.now().plus({ days: 30 });
  parsedJSON.tokenExpiry = validDateTime.toMillis();

  return parsedJSON;
};
