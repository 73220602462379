import { type NavigationGuard } from "vue-router";
import { useAlertMessagesStore } from "@shared/stores/alertMessages";
import { RouteNames } from "@shared/types/localTypes/router";
import { appService } from "@shared/services/app";
import type {
  LinkedSessionRequest,
  MyJFrogTheme,
  NeedOtpLoginResponse,
} from "@jfrog-ba/myjfrog-common";
import type { OtpAuthenticationData } from "@shared/stores/authentication";
import { useAuthenticationStore } from "@shared/stores/authentication";
import logger from "@shared/utils/logger";
import { sessionsService } from "@shared/services/sessions";
import window from "@shared/utils/app-window.ts";
import { LocalStorageKeys } from "@shared/types/localTypes/storage.ts";
import { DateTime } from "luxon";

export const ssoLogin: NavigationGuard = async (to, from, next) => {
  const otpRequired = to.query.otpRequired !== undefined; // query param without value
  if (otpRequired) {
    return otpSSOGuard(to, from, next);
  }

  return regularSSOGuard(to, from, next);
};

const otpSSOGuard: NavigationGuard = async (to, from, next) => {
  const otpTokenKey = to.query.otpTokenKey as string;
  const authenticator = to.query
    .authenticator as NeedOtpLoginResponse["authenticator"];

  if (otpTokenKey && authenticator) {
    const otpAuthenticationData: OtpAuthenticationData = {
      otpTokenKeyLink: otpTokenKey,
      authenticator,
      fromSSO: true,
    };
    await useAuthenticationStore().setOtpAuthenticationData(
      otpAuthenticationData,
    );
    return next({ name: RouteNames.GoogleAuthenticator });
  }

  await setSsoLoginFailedMessage("Missing mandatory OTP parameters");
  return next({ name: RouteNames.Root });
};

const regularSSOGuard: NavigationGuard = async (to, from, next) => {
  const sessionId = to.query.sessionId as string;
  const userName = to.query.userName as string;
  const target = to.query.target as string;
  const subscriptionId = to.query.subscriptionId as string;
  let theme = to.query.theme as MyJFrogTheme;

  if (!sessionId || !userName) {
    await setSsoLoginFailedMessage("Missing mandatory SSO login parameters.");
    return next({ name: RouteNames.Root });
  }

  if (!theme) {
    logger.warn(`Unknown theme '${theme}'. Fallback to default one.`);
    theme = "DEFAULT";
  }

  const linkedSessionRequest: LinkedSessionRequest = {
    linkSessionId: sessionId,
  };
  try {
    const linkedSessionResponse =
      await sessionsService.getLinkedSession(linkedSessionRequest);
    if (!linkedSessionResponse) {
      await setSsoLoginFailedMessage("Unable to receive session ID");
      return next({ name: RouteNames.Root });
    }

    if (!!subscriptionId && !!target && target === "upgrade") {
      return next({
        name: RouteNames.QuickUpgrade,
        params: { subscriptionId },
      });
    }

    window.localStorage.setItem(
      LocalStorageKeys.LAST_SUCCESSFUL_LOGIN,
      DateTime.now().toMillis().toString(),
    );
    return next({ name: RouteNames.Root });
  } catch (e) {
    await setSsoLoginFailedMessage(
      "Communication error while receiving session ID.",
    );

    return next({ name: RouteNames.Root });
  }
};

export const ssoLoginFailed: NavigationGuard = async (to, from, next) => {
  await appService.logOut();
  await setSsoLoginFailedMessage("SSO Login failed");
  return next({ name: RouteNames.Root });
};

const setSsoLoginFailedMessage = async (detail: string) => {
  await useAlertMessagesStore().setMessages({
    title: "",
    description: `We're sorry but the page you requested could not be displayed. Reason : ${detail}`,
  });
};
