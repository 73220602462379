import {
  AbsoluteNotificationSettingsModel,
  DirectNotificationSettingsModel,
  NotificationsBySubscriptionsRequest,
  NotificationsBySubscriptionsResponse,
  PeriodicalNotificationSettingsModel,
  NotificationTemplateName,
} from "@jfrog-ba/myjfrog-common";
import apiProxy from "@shared/utils/api-proxy";
import { useNotificationsCenterStore } from "@shared/stores/notificationsCenter.ts";
import { EventEntity, EventType } from "@shared/types/localTypes/events.ts";
import { useEventLogger } from "@shared/use/useEventLogger.ts";
import {
  CleanNotificationRecipientsRequest,
  CreatePeriodicalNotificationRequest,
  EditAbsoluteNotificationRequest,
  EditDirectNotificationRequest,
  EditPeriodicalNotificationRequest,
  GetSentNotificationsBySubscriptionRequest,
  GetSentNotificationsBySubscriptionResponse,
} from "@shared/types/localTypes/notifications-center.ts";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { DateTime } from "luxon";

export const notificationsCenterService = {
  loadNotifications: async (
    request?: NotificationsBySubscriptionsRequest,
  ): Promise<void> => {
    const notificationsCenterStore = useNotificationsCenterStore();
    notificationsCenterStore.setNotificationsAreLoading(true);
    const response: NotificationsBySubscriptionsResponse | null =
      await apiProxy.post(
        `userpref/get-notifications-by-subscription-numbers`,
        request || { subscription_numbers: [] },
      );

    if (!response) {
      throw new Error("Could not fetch subscriptions notifications data");
    }
    notificationsCenterStore.setNotificationsBySubscriptions(response.items);
    notificationsCenterStore.setNotificationsAreLoading(false);
  },

  reloadSubscriptionNotifications: async (
    subscriptionNumber: string,
  ): Promise<void> => {
    const request: NotificationsBySubscriptionsRequest = {
      subscription_numbers: [subscriptionNumber],
    };
    const response: NotificationsBySubscriptionsResponse | null =
      await apiProxy.post(
        `userpref/get-notifications-by-subscription-numbers`,
        request,
      );

    if (!response) {
      throw new Error("Could not reload subscription's notifications data");
    }

    const updatedNotifications =
      response.items.find(
        (item) => item.subscriptionNumber === subscriptionNumber,
      ) || null;

    useNotificationsCenterStore().updateNotificationsForSubscription(
      updatedNotifications,
    );
  },
  createPeriodicalNotification: async (args: {
    request: CreatePeriodicalNotificationRequest;
  }) => {
    const { request } = args;
    const response: PeriodicalNotificationSettingsModel | null =
      await apiProxy.post(`userpref/notifications/periodical`, request);

    if (!response) {
      throw new Error("Could not create periodical notification");
    }

    useNotificationsCenterStore().updateNotificationItem(
      request.subscriptionNumber.toString(),
      response,
    );
    useEventLogger().logEvent({
      eventType: EventType.NOTIFICATION_CREATED,
      eventEntity: EventEntity.USER,
      eventData: { ...request, uuid: response.uuid },
    });
  },
  deletePeriodicalNotification: async (args: {
    subscriptionId: string;
    uuid: string;
    type: NotificationTemplateName;
  }) => {
    const { subscriptionId, uuid, type } = args;
    await apiProxy.delete(
      `userpref/subscription/${subscriptionId}/notifications/periodical/${uuid}`,
    );

    useNotificationsCenterStore().removeNotificationItem(subscriptionId, uuid);
    useEventLogger().logEvent({
      eventType: EventType.NOTIFICATION_DELETED,
      eventEntity: EventEntity.USER,
      eventData: { uuid, type },
    });
  },
  editPeriodicalNotification: async (args: {
    request: EditPeriodicalNotificationRequest;
    uuid: string;
    type: NotificationTemplateName;
  }) => {
    const { request, uuid, type } = args;
    const response: PeriodicalNotificationSettingsModel | null =
      await apiProxy.put(`userpref/notifications/periodical/${uuid}`, request);

    if (!response) {
      throw new Error("Could not update periodical notification");
    }

    useNotificationsCenterStore().updateNotificationItem(
      request.subscriptionNumber.toString(),
      response,
    );
    useEventLogger().logEvent({
      eventType: EventType.NOTIFICATION_UPDATED,
      eventEntity: EventEntity.USER,
      eventData: { ...request, uuid: response.uuid, type },
    });
  },
  editAbsoluteNotification: async (args: {
    request: EditAbsoluteNotificationRequest;
    uuid: string;
    type: NotificationTemplateName;
  }) => {
    const { request, uuid, type } = args;
    const response: AbsoluteNotificationSettingsModel | null =
      await apiProxy.put(`userpref/notifications/absolute/${uuid}`, request);

    if (!response) {
      throw new Error("Could not update absolute notification");
    }

    useNotificationsCenterStore().updateNotificationItem(
      request.subscriptionNumber.toString(),
      response,
    );
    useEventLogger().logEvent({
      eventType: EventType.NOTIFICATION_UPDATED,
      eventEntity: EventEntity.USER,
      eventData: { ...request, uuid: response.uuid, type },
    });
  },
  editDirectNotification: async (args: {
    request: EditDirectNotificationRequest;
    uuid: string;
    type: NotificationTemplateName;
  }) => {
    const { request, uuid, type } = args;
    const response: DirectNotificationSettingsModel | null = await apiProxy.put(
      `userpref/notifications/direct/${uuid}`,
      request,
    );

    if (!response) {
      throw new Error("Could not update direct notification");
    }

    useNotificationsCenterStore().updateNotificationItem(
      request.subscriptionNumber.toString(),
      response,
    );
    useEventLogger().logEvent({
      eventType: EventType.NOTIFICATION_UPDATED,
      eventEntity: EventEntity.USER,
      eventData: { ...request, uuid: response.uuid, type },
    });
  },
  cleanRecipients: async (request: CleanNotificationRecipientsRequest) => {
    await apiProxy.put(`userpref/notifications/recipients/clean`, request);
  },

  computeHistoryStartDate: () => {
    const currentSubscription = useSubscriptionsStore().getCurrentSubscription;
    const notificationsCenterStore = useNotificationsCenterStore();

    const defaultStartDate = notificationsCenterStore.initialHistoryStartDate;
    if (!currentSubscription) {
      return defaultStartDate;
    }
    const subscriptionStartDate = DateTime.fromMillis(
      Number(currentSubscription.startDate),
    ).startOf("day");

    return subscriptionStartDate > defaultStartDate
      ? subscriptionStartDate
      : defaultStartDate;
  },

  loadHistory: async (args: { subscriptionId: number }) => {
    const { subscriptionId } = args;
    const notificationsCenterStore = useNotificationsCenterStore();

    const response: GetSentNotificationsBySubscriptionResponse | null =
      await apiProxy.post(
        `notifications-history/subscription/${subscriptionId}/sent/get`,
        {
          startDate: notificationsCenterStore.historyStartDate.toMillis(),
          endDate: notificationsCenterStore.historyEndDate.toMillis(),
        } satisfies GetSentNotificationsBySubscriptionRequest,
      );

    if (!response) {
      throw new Error("Could not get notification history");
    }
    notificationsCenterStore.setHistoryItems(response.items);
  },
};
