import { defineStore } from "pinia";
import { type SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { heapService } from "@shared/services/heap";
import { jpdUtils } from "@shared/utils/jpd-utils";
import { appcuesService } from "@shared/services/appcues";

export const AllSubscriptionsSelectLabel = "All Subscriptions";

export interface SubscriptionsStoreStates {
  subscriptions: SubscriptionDTO[] | null;
  currentSubscriptionNumber: string;
}

const getSubscription = (
  state: SubscriptionsStoreStates,
): SubscriptionDTO | null => {
  if (state.subscriptions === null) {
    return null;
  }
  const subscription = state.subscriptions.find(
    (currSubscription) =>
      currSubscription.accountNumber.toString() ===
      state.currentSubscriptionNumber,
  );
  if (subscription === undefined) {
    return null;
  }
  return subscription;
};

const hasCurrentSubscription = (state: SubscriptionsStoreStates): boolean => {
  return (
    state.currentSubscriptionNumber !== "" &&
    state.currentSubscriptionNumber !== AllSubscriptionsSelectLabel
  );
};

export const useSubscriptionsStore = defineStore({
  id: "subscriptions",
  state: () => ({
    subscriptions: null as SubscriptionsStoreStates["subscriptions"],
    currentSubscriptionNumber:
      "" as SubscriptionsStoreStates["currentSubscriptionNumber"],
  }),
  getters: {
    getSubscriptions: (state) => state.subscriptions,
    getSaasSubscriptions: (state) =>
      state.subscriptions?.filter((subscription) => subscription.meta.isSaas) ||
      [],
    getSelfHostedSubscriptions: (state) =>
      state.subscriptions?.filter(
        (subscription) => subscription.meta.isSelfHosted,
      ) || [],
    getCurrentSubscription: (state) => getSubscription(state),
    isCurrentSubscriptionActive: (state) =>
      getSubscription(state)?.state === "ACTIVE",
    getCurrentSubscriptionNumber: (state) => state.currentSubscriptionNumber,
    hasCurrentSubscription: (state) => hasCurrentSubscription(state),
    isMultiJpdsAccessible: (state) => {
      const currentSubscription = getSubscription(state);
      if (!currentSubscription) {
        return false;
      }
      const accessibleJpdItems = currentSubscription.jpus.filter(
        (jpdItem) => !jpdUtils.isJpdNotAccessible(jpdItem),
      );
      return accessibleJpdItems.length > 1;
    },
    isFreeOrProSubscription: (state) => {
      const currentSubscription = getSubscription(state);
      return (
        currentSubscription?.meta.isFree ||
        currentSubscription?.meta.isProTeam ||
        currentSubscription?.meta.isCloudPro ||
        currentSubscription?.meta.isCloudProX
      );
    },
    getCurrentSubscriptionNumberInt: (state) =>
      parseInt(state.currentSubscriptionNumber),
    getPrimaryAdmin: (state) => {
      return hasCurrentSubscription(state)
        ? getSubscription(state)?.owners.find(
            (owner) => owner.role === "MYJFROG_PRIMARY_ADMIN",
          )
        : null;
    },
  },
  actions: {
    async setSubscriptions(
      subscriptions: SubscriptionsStoreStates["subscriptions"],
    ) {
      this.subscriptions = subscriptions;
    },
    setCurrentSubscription(
      subscriptionNumber: SubscriptionsStoreStates["currentSubscriptionNumber"],
    ) {
      this.currentSubscriptionNumber = subscriptionNumber.toString();
      heapService.setSubscriptionProperties();
      appcuesService.setSubscriptionProperties();
    },
    clearSubscriptions() {
      this.subscriptions = null;
      this.currentSubscriptionNumber = "";
    },
    updateExistingSubscription(newSubscription: SubscriptionDTO) {
      if (!this.subscriptions) {
        return;
      }
      const indexOfSubscriptionToUpdate = this.subscriptions.findIndex(
        (subscription) =>
          subscription.accountNumber === newSubscription.accountNumber,
      );
      if (indexOfSubscriptionToUpdate === -1) {
        return;
      }
      this.subscriptions[indexOfSubscriptionToUpdate] = newSubscription;
    },
  },
});
