import { isRestClientError } from "@jfrog-ba/myjfrog-common";
import { isAxiosError } from "@shared/types/typeGuards";
import { RouteNames } from "@shared/types/localTypes/router";

interface MessageValidationConfig {
  serverErrorsMapping?: Record<number, string>;
  defaultErrorMessage?: string;
}

export const messagesService = {
  app_something_went_wrong: `Something went wrong. Please try again`,
  valid_email_required: `Please enter a valid email address`,
  app_forbidden: `Forbidden`,
  app_unauthorized: `Unauthorized`,
  app_session_expired: `Your session has expired. Please log in again`,
  app_session_not_synced: `The action you requested could not be executed. Please log in again`,
  login_not_associated_with_myjfrog_account: `Your Apple ID is not associated with your MyJfrog account. Please try another way to log in`,
  login_user_not_found: `Invalid credentials - you might not be a registered MyJFrog user. Please try again.`,
  mobile_login_user_not_found: `You might not be a registered MyJFrog user.`,
  too_many_requests: `Too many attempts, please try again in a few minutes`,
  menu_tooltip_action_success: `Thank you, we will contact you shortly`,
  profile_update_success: `Profile successfully updated`,
  password_update_success: `Password successfully updated`,
  password_forgot_success: `<div class="text-left">Password reset instructions were sent.<br><span class="font-bold">Didn't receive an email?</span><br>Contact <a href="mailto:service@jfrog.com" class="ess-green-link underlined-link">service@jfrog.com</a></div>`,
  register_now_success: `<div class="text-left">Registrations instructions were sent.<br><span class="font-bold">Didn't receive an email?</span><br>Contact <a href="mailto:service@jfrog.com" class="ess-green-link underlined-link">service@jfrog.com</a></div>`,
  password_reset_success: `Password successfully reset`,
  reset_password_failed_link_not_valid: `The link you followed has expired, please try again`,
  registration_error: `An error occurred during the registration process. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  token_generation_error: `An error occurred generating a token. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  quick_purchase_should_login_to_upgrade: `Sign in to complete the upgrade`,
  billing_list_fetch_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  billing_fetch_credit_card_fingerprint_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  billing_fetch_details_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  billing_fetch_estimated_payment_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  billing_disclaimer_next_expected_payment: `* The above account payment estimation is calculated up until yesterday end of day.<br/>Final payment details will be calculated according to the applicable billing document set forth by the JFrog accounting team`,
  billing_disclaimer_next_expected_balance: `* The above balance is based on the last sent billing document`,
  billing_disclaimer_aws_management: `Your billing information and settings are managed by AWS Marketplace`,
  billing_disclaimer_gcp_management: `Your billing information and settings are managed by Google Cloud Platform Marketplace`,
  billing_disclaimer_azure_management: `Your billing information and settings are managed by Microsoft Azure Marketplace`,
  billing_disclaimer_not_billed_info_not_available: `You're not being billed at the moment, so no billing information available`,
  billing_disclaimer_not_billed_no_invoices: `You have no billing reports`,
  billing_disclaimer_not_billed_account_details_not_adjustable: `Account details can only be adjusted on a paid plan`,
  billing_payments_details_update_success: `Billing details successfully updated`,
  billing_payments_credit_card_details_update_success: `Credit Card details successfully updated`,
  billing_payments_debt_credit_card_details_update_success_active_account: `Your customer details have been updated successfully. You will receive an email once your credit card has been billed`,
  billing_payments_debt_credit_card_details_update_success_blocked_account: `Your customer details have been updated successfully. You will receive an email once your credit card has been billed. A separate email will be sent once your JFrog service has been reactivated`,
  billing_payments_customer_details_update_success: `Customer details successfully updated`,
  subscriptions_list_fetch_failed: `An error occurred while loading the subscription information. Please try again, or contact  <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_buy_success: `Success! Your purchase is complete.<br />You can manage your account details at the <mjf-router-link class="font-bold cursor-pointer hover:underline" to="${RouteNames.Billing}">'Billing'</mjf-router-link> section`,
  subscriptions_buy_from_expired_success: `Success! Your purchase is complete and your Artifactory server was reactivated.<br /><br />You can manage your account details at the <mjf-router-link class="font-bold cursor-pointer hover:underline" to="${RouteNames.Billing}">'Billing'</mjf-router-link> section`,
  subscriptions_buy_error_calculator_error: `An error occurred while loading the pricing calculator. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_buy_error_unknown: `An error occurred during your purchase process. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_buy_error_missing_terms_and_conditions_agreement: `Please read and agree to the "Terms and Conditions" and "Privacy Policy"`,
  subscriptions_upgrade_success: `Your account has been successfully upgraded! An email will be sent shortly with your new cloud updates`,
  subscriptions_add_pipelines_success: `Your account has been successfully upgraded! An email will be sent shortly with your new cloud updates`,
  subscriptions_add_premium_xray_success: `Your account has been successfully upgraded!`,
  subscriptions_add_pipelines_error_unknown: `An error occurred while adding CI/CD Pipelines. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_add_pipelines_error_insufficient_balance: `Unfortunately your account has insufficient balance to complete the upgrade, please contact <a href="mailto:ar.il@jfrog.com" class="pointer pointer hover:underline">ar.il@jfrog.com</a>`,
  subscriptions_upgrade_error_unknown: `An error occurred during the upgrade process. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_upgrade_error_is_prepaid: `Thank you for your interest.<br />Please <a href="https://jfrog.com/contact-us/" class="blue-link" target="_blank">Contact Us</a> to upgrade your subscription and add continuous security scanning and 24/7 R&D-level SLA support for both Artifactory and Xray Cloud`,
  subscriptions_upgrade_error_is_prepaid_NEW: `Thank you for your interest in upgrading. To make this easy for you, your account manager will contact you to finalize the process`,
  subscriptions_upgrade_error_xray_cant_upgrade: `Thanks you for your interest, your account manager will contact you`,
  subscriptions_set_notifications_success: `Notifications have been set`,
  subscriptions_set_notifications_error_unknown: `An error occurred while loading your notifications. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_set_notifications_error_confirm: `An error occurred while saving your notifications. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_change_region_success: `Your server region were successfully changed`,
  subscriptions_change_provider_and_region_success: `Your server provider and region were successfully changed`,
  subscriptions_change_error: `An error occurred while sending request. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_whitelist_ips_set_success: `Allowlist addresses have been updated successfully`,
  subscriptions_whitelist_ips_export_csv: `Downloading CSV`,
  subscriptions_whitelist_ips_set_error: `An error occurred while updating your Whitelist addresses. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_whitelist_ips_fetch_error: `An error occurred while fetching your Whitelist addresses. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_licensing_fetch_keys: `An error occurred while fetching your license keys. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_licensing_generate_signed_url: `An error occurred while generating signed URL. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_licensing_successfully_created_csv: `Downloading CSV`,
  subscriptions_server_ip_meta_data_fetch_error: `An error occurred while fetching your servers IP configurations. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_private_link_meta_data_fetch_error: `An error occurred while fetching your PrivateLink configurations. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_private_link_duplicate_error: `You have already submitted this Endpoint ID to create a PrivateLink. Enter a different Endpoint ID or edit the existing one. For assistance, contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a>`,
  subscriptions_servers_status_fetch_error: `An error occurred while fetching your servers status. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_whitelist_ips_deactivate_confirmation: `Please notice your cloud server will be available to any IP/CIDR address. Click Ok to confirm`,
  subscriptions_whitelist_disclaimer_proX_trial: `Notice: The IP/CIDR addresses you have configured will be deleted once your JFrog Cloud Pro X trial ends`,
  subscriptions_request_cancel_success: `Your cancel request has been successfully sent`,
  subscriptions_request_cancel_error: `An error occurred while sending request. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_request_downgrade_xray_premium_success: `Your downgrade process has successfully started. A confirmation email has been sent`,
  subscriptions_request_self_downgrade_success: `Your downgrade process has successfully started. A confirmation email has been sent`,
  subscriptions_request_downgrade_success: `Your downgrade request has been successfully sent`,
  subscriptions_request_downgrade_error: `An error occurred while sending request. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_request_remove_security_pack_success: `Your removing security pack process has successfully started. A confirmation email has been sent`,
  subscriptions_request_remove_security_pack_error: `An error occurred while sending request. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_narcissus_task_in_progress: `Your previous request is still being processed. Try again in a few minutes and if the problem persists, please contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a>`,
  subscriptions_request_self_cancellation_success: `Your cancellation process has successfully started. A confirmation email has been sent`,
  subscriptions_request_remove_pipelines_success: `Your request has been successfully submitted. A confirmation email has been sent`,
  subscriptions_jpd_access_fetch_error: `An error occurred while loading Jpds SSO access settings. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_jpd_access_update_error: `An error occurred while updating Jpds SSO access settings. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_sso_access_update_error: `An error occurred while updating SSO access settings. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_request_self_cancellation_error: `An error occurred while cancelling your subscription. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_Geo_Restriction_fetch_error: `An error occurred while loading Geolocation restriction settings. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_Geo_Restriction_set_success: `Geolocation restrictions were successfully received and will be enforced in a few minutes`,
  subscriptions_Geo_Restriction_set_error: `An error occurred while updating Geolocation restriction settings. Please try again, or contact <a href="mailto:support@jfrog.com" class="pointer pointer hover:underline">support@jfrog.com</a> in case the problem persists`,
  subscriptions_request_remove_pipelines_error: `An error occurred while removing pipelines from your subscription. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_request_getaquote_success: `Your request was successfully sent!<br/>We will contact you shortly.`,
  subscriptions_request_getaquote_error: `An error occurred while trying to send your request. Please contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a>`,
  subscription_usage_graph_loading_failed: `An error occurred while loading the subscription usage information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscription_usage_graph_disclaimer: `Your usage data is updated on an ongoing basis. However, your final billing will be according to your actual usage`,
  subscription_cdn_graph_disclaimer: `The Data Transfer via CDN is part of the total Data Transfer and is for informative purposes only`,
  subscription_top_usage_loading_failed: `An error occurred while loading the subscription top usage information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscription_forecast_loading_failed: `An error occurred while loading the subscription end-of-month forecast information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscription_regions_list_fetch_failed: `An error occurred while loading the regions information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscription_provider_not_supported_by_xray: `Thank you for your interest. JFrog Xray Cloud is unavailable for this provider. Xray Cloud uses Kubernetes technology which is currently not supported by this provider. Select a different provider by submitting a request using the "Actions Menu"`,
  subscription_buy_provider_not_supported_by_xray_NEW: `Thank you for your interest in purchasing. The cloud provider, region and product combination you have chosen will be coming soon. Our cloud team will contact you to give you more details`,
  subscription_upgrade_provider_not_supported_by_xray_NEW: `Thank you for your interest in upgrading. The cloud provider, region and product combination you have chosen will be coming soon. Our cloud team will contact you to give you more details`,
  subscription_is_oss_message_upgrade: `Thank you for your interest. Please <a href="https://jfrog.com/contact-us/" class="blue-link" target="_blank">Contact Us</a> to upgrade your subscription`,
  jpay_billing_jpc_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jpay_billing_details_form_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jpay_billing_details_form_submit_failed: `An error occurred while submitting the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jpay_credit_card_form_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jpay_credit_card_update_default_failed: `An error occurred while updating the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jpay_customer_details_form_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jpay_customer_details_form_submit_failed: `An error occurred while submitting the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  enable_cdn_success: `success TBD`,
  enable_cdn_error: `TBD ------- An error occurred while enabling the CDN feature. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  root_certificate_fetch_error: `An error occurred while fetching your public root certificate. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  root_certificate_fetch_success: `Your server public root certificates file downloading now`,
  mfa_enrollment_activation_error: `Activation failed. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  mfa_error_otp_not_match: `The code entered is invalid. Please verify the code`,
  mfa_enrollment_activation_success: `Multi Factor Authentication changes were successfully saved`,
  mfa_enrollment_cancellation_error: `Cancellation failed. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  mfa_enrollment_cancellation_error_already_disabled: `Multi-Factor Authentication was disabled, please refresh the page`,
  mfa_enrollment_cancellation_success: `Cancelled`,
  subscription_activation_in_progress: `This action will be available once your subscription is activated. You will receive an email notification that the activation is complete`,
  subscriptions_eplus_instances_configuration_success: `The configured instance(s) are being added to your topology.<br/> This process may take a while. We will notify you by email when the process is completed`,
  subscriptions_eplus_instances_configuration_state_management_error: `Unfortunately we have encountered an error while managing the internal state. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  subscriptions_eplus_instances_configuration_unexpected_error: `Unfortunately we have encountered an error while configuring your instances. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  registration_servername_validation_error_unexpected: `Internal validation error`,
  registration_servername_validation_error_servername_already_taken: `Server name is already in use`,
  registration_servername_validation_error_servername_reserved: `Server name is already in use`,
  registration_servername_validation_error_servername_invalid: `Bad server name policy`,
  registration_servername_validation_error_servernames_not_distinct: `Server names must be unique`,
  quick_purchase_link_send_by_mail_success: `A new link has been sent to your email`,
  billing_download_failed: `An error occurred while downloading billing Document. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jmis_no_candidate_migration_exists: `None of your JFrog Cloud instances have been set for migration.<br/> Please contact JFrog support for more information at <a href="mailto:support@jfrog.com" class="ess-green-link">support@jfrog.com</a>`,
  manage_users_not_adjustable: `User management can only be accessed on a paid plan`,
  manage_users_invitation_already_invited: `User is already invited to this Subscription`,
  manage_users_addUser_sf_error: `There is an issue with the user you are trying to add, please contact JFrog Customer Care (<a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a>)`,
  manage_users_remove_user_success: `User has been removed`,
  manage_users_remove_user_error: `An error occurred while removing the user. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  manage_users_add_user_success: `New user has been added`,
  manage_users_add_user_error: `An error occurred while adding the user. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  manage_users_edit_user_success: `User role has been updated`,
  manage_users_edit_user_and_invitation_sent_success: `User role has been updated and invitation sent`,
  manage_users_edit_user_error: `An error occurred while editing the user. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  manage_users_invitation_sent_success: `Invitation has been sent`,
  manage_users_invitation_sent_error: `An error occurred while resending the invitation. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jmis_migration_install_plugin_error: `An error occurred while sending install plugin request. Please try again in a few minutes, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists`,
  jmis_migration_uninstall_plugin_error: `An error occurred while sending uninstall plugin request. Please try again in a few minutes, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists`,
  jmis_get_ssl_list: `An error occurred while trying to fetch certificates information. Please contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists`,
  jmis_starting_polling_process:
    "This process may take a few minutes. Once completed, the SSL certificate status will update",
  jmis_finish_polling_process_added:
    "The SSL certificate has been added successfully",
  jmis_finish_polling_process_deleted: `The SSL certificate has been deleted successfully`,
  jmis_submit_failed_conflict: `Error, the JPD cannot perform the request. Please try again in a few minutes. Please contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists`,
  jmis_submit_certificate: `An error occurred while trying to update certificates information. Please contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists`,
  jmis_validate_certificate_error: `An error occurred while trying to validate certificate. Please contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists`,
  jmis_failover_task_success:
    "The status of the failover test was changed successfully",
  manage_support_contact_created: "New contact successfully created!",
  manage_support_contact_edited: "Contact successfully updated!",
  manage_support_contact_fetch_failed: `An error occurred while fetching support contacts list. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  manage_support_contact_quota_fetch_failed: `An error occurred while fetching support contacts quota. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  manage_support_contact_created_edited_failed: `An error occurred while updating support contacts list. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  manage_support_contact_created_duplicate_email: `There is an issue with the user you are trying to add. Please contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> for more details`,
  manage_support_contact_deleted: "Contact successfully deleted!",
  manage_support_contact_deleted_failed: `An error occurred deleting a support contact. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_configured_success: "Routing policy configured successfully",
  dns_routing_deleted_success: "Routing policy deleted successfully",
  dns_routing_fetch_policies_failed: `An error occurred while trying to fetch routing policies. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_fetch_jpd_readiness_failed: `An error occurred while trying to fetch JPD health status. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_fetch_geo_locations_failed: `An error occurred while trying to fetch Geo locations information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_enable_policy_failed: `An error occurred while trying to enable routing policy. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_disable_policy_failed: `An error occurred while trying to disable routing policy. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_delete_policy_failed: `An error occurred while trying to delete routing policy. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_switch_policy_failed: `An error occurred while trying to switch routing of JPDs. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_add_policy_failed: `An error occurred while trying to add new routing policy. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  dns_routing_update_policy_failed: `An error occurred while trying to update an existing routing policy. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jpay_buy_jpc_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  jpay_buy_form_loading_failed: `Unfortunately we have encountered an error. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  log_streamer_failed: `An error occurred while updating log streamer. Please contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists`,
  log_streamer_failed_validation: `Something went wrong, try again. Please contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists`,
  log_streamer_failed_validation_datadog_error_403: "API key is not valid",
  log_streamer_failed_validation_dataset_error_403: "API key is not valid",
  log_streamer_failed_validation_splunk_error_403: "HEC token is not valid",
  log_streamer_failed_validation_error_404: "Endpoint does not exist",
  jas_trial_request_success: `Your request was successfully sent! <br/> We will contact you shortly.`,
  curation_trial_request_success: `Your request was successfully sent! <br/> We will contact you shortly.`,
  empty_state_no_repositories: `You don't have repositories to show yet`,
  empty_state_no_projects: `You don't have projects to show yet`,
  empty_state_no_packages: `You don't have packages to show yet`,
  empty_state_no_scans: `Get the most of your Platform with our Advanced Security package`,
  empty_state_no_jpd: `There are no JPDs currently reporting.<br/> To view JPD information here, enable JPD reporting.`,
  discardPopup: {
    general: {
      title: "Unsaved Changes",
      message: "Are you sure you wish to discard your changes?",
      confirmButtonText: "Discard",
      cancelButtonText: "Cancel",
    },
  },
  generateToken: {
    message:
      "Regenerating the token will cause the existing token to expire, and all features using the old token will stop working",
  },
  notification_center_create_error: `An error occurred while creating the notification settings. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  notification_center_edit_error: `An error occurred while editing the notification settings. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  notification_center_delete_error: `An error occurred while deleting the notification settings. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,
  health_status_loading_failed: `An error occurred while loading the regions health status information. Please try again, or contact <a href="mailto:service@jfrog.com" class="pointer pointer hover:underline">service@jfrog.com</a> in case the problem persists`,

  checkForGlobalError: (e: unknown, defaultErrorMessage?: string) => {
    if (isRestClientError(e)) {
      if (e.httpStatus === 403) {
        return messagesService.app_forbidden;
      }

      if (e.httpStatus === 401) {
        return messagesService.app_unauthorized;
      }
    }

    if (isAxiosError(e) && e.code === "ERR_NETWORK") {
      return e.message;
    }

    return defaultErrorMessage || messagesService.app_something_went_wrong;
  },

  computeErrorMessage: (
    e: unknown,
    serverErrorCodesToIntercept: number[],
    validationConfig?: MessageValidationConfig,
  ) => {
    const errorCodeShouldBeIntercepted =
      isRestClientError(e) &&
      e.httpBody &&
      e.httpBody.result &&
      serverErrorCodesToIntercept.some(
        (codeToIntercept) => +codeToIntercept === e.httpBody?.result,
      );

    if (errorCodeShouldBeIntercepted && e.httpBody) {
      return (
        (validationConfig?.serverErrorsMapping &&
          validationConfig.serverErrorsMapping[e.httpBody.result]) ||
        messagesService.convertEmailToHtml(e.httpBody.message)
      );
    }

    return messagesService.checkForGlobalError(
      e,
      validationConfig?.defaultErrorMessage,
    );
  },
  convertEmailToHtml: (message: string) => {
    const serviceLinkHTML = `<a href="mailto:service@jfrog.com" class="danger">service@jfrog.com</a>`;

    return message
      .replace(new RegExp("support@jfrog.com", "g"), serviceLinkHTML)
      .replace(new RegExp("service@jfrog.com", "g"), serviceLinkHTML);
  },
  extractPaymentsError: (paymentsError: string) => {
    const containsJson =
      paymentsError.includes("{") || paymentsError.includes("}");
    if (containsJson) {
      return paymentsError;
    }
    const errorParts: string[] = paymentsError.split("]");
    const mainError = errorParts.length > 1 ? errorParts[1] : errorParts[0];
    return mainError.replace("Technical error occurred :", "");
  },
};
