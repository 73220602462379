import { defineStore } from "pinia";

interface BootstrapStoreStates {
  _initialDataLoaded: boolean;
}
export const useBootstrapStore = defineStore({
  id: "bootstrap",
  state: () => ({
    _initialDataLoaded: false as BootstrapStoreStates["_initialDataLoaded"],
  }),
  getters: {
    initialDataLoaded: (state) => state._initialDataLoaded,
  },
  actions: {
    setInitialDataLoaded(loaded: boolean) {
      this._initialDataLoaded = loaded;
    },
  },
});
