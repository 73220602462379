import { defineStore } from "pinia";
import { MobileNotificationProps } from "@shared/types/localTypes/props.ts";
import {
  InAppNotificationsForSubscription,
  InAppNotification,
} from "@jfrog-ba/myjfrog-common";

export interface InAppNotificationsStoreState {
  _eventChannel: string | null;
  _highlightedNotification: MobileNotificationProps | null;
  _inAppNotificationsPerSubscription: InAppNotificationsForSubscription[];
}

export const useInAppNotificationsStore = defineStore({
  id: "in-app-notifications",
  state: () =>
    ({
      _eventChannel: null,
      _highlightedNotification: null,
      _inAppNotificationsPerSubscription: [],
    }) as InAppNotificationsStoreState,
  getters: {
    eventChannel: (state): InAppNotificationsStoreState["_eventChannel"] =>
      state._eventChannel,
    highlightedNotification: (
      state,
    ): InAppNotificationsStoreState["_highlightedNotification"] =>
      state._highlightedNotification,
    inAppNotificationsPerSubscription: (
      state,
    ): InAppNotificationsStoreState["_inAppNotificationsPerSubscription"] =>
      state._inAppNotificationsPerSubscription,
  },
  actions: {
    setEventChannel(
      eventChannel: InAppNotificationsStoreState["_eventChannel"],
    ) {
      this._eventChannel = eventChannel;
    },
    setHighlightedNotification(
      highlightedNotification: InAppNotificationsStoreState["_highlightedNotification"],
    ) {
      this._highlightedNotification = highlightedNotification;
    },
    clearHighlightedNotification() {
      this._highlightedNotification = null;
    },
    setInAppNotificationsPerSubscription(
      inAppNotificationsPerSubscription: InAppNotificationsStoreState["_inAppNotificationsPerSubscription"],
    ) {
      this._inAppNotificationsPerSubscription =
        inAppNotificationsPerSubscription;
    },
    removeInAppNotifications(notificationsToRemove: InAppNotification[]) {
      const uuidsToRemove = notificationsToRemove.map((item) => item.uuid);

      this._inAppNotificationsPerSubscription.forEach(
        (notificationsPerSubscription) => {
          notificationsPerSubscription.inAppNotifications =
            notificationsPerSubscription.inAppNotifications.filter(
              (item) => !uuidsToRemove.includes(item.uuid),
            );
        },
      );
    },
  },
});
