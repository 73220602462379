import { defineStore } from "pinia";

interface ResponsiveStoreState {
  skipMobileMaskDisclaimerState: boolean;
}

export const useResponsiveStore = defineStore({
  id: "responsive",
  state: (): ResponsiveStoreState => ({
    skipMobileMaskDisclaimerState: false,
  }),
  getters: {
    skipMobileMaskDisclaimer: (state) => state.skipMobileMaskDisclaimerState,
  },
  actions: {
    setSkipMobileMaskDisclaimer(
      skipMobileMaskDisclaimer: ResponsiveStoreState["skipMobileMaskDisclaimerState"],
    ) {
      this.skipMobileMaskDisclaimerState = skipMobileMaskDisclaimer;
    },
  },
});
