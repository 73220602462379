import type { DateFilters } from "@shared/types/localTypes/usage";
import * as yup from "yup";
import dateUtils from "./date-utils";
import {
  byte,
  EB,
  GB,
  KB,
  MB,
  simpleNumber,
  PB,
  TB,
  K,
  M,
  B,
} from "@shared/constants/constants";

export enum UsageUnitSymbol {
  NONE = "",
  K = "K",
  M = "M",
  G = "G",
  T = "T",
  P = "P",
  E = "E",
}

export enum NumberUsageUnitSymbol {
  NONE = "",
  K = "K",
  M = "M",
  B = "B",
}

const usageSymbolLookup: { value: number; symbol: UsageUnitSymbol }[] = [
  { value: byte, symbol: UsageUnitSymbol.NONE },
  { value: KB, symbol: UsageUnitSymbol.K },
  { value: MB, symbol: UsageUnitSymbol.M },
  { value: GB, symbol: UsageUnitSymbol.G },
  { value: TB, symbol: UsageUnitSymbol.T },
  { value: PB, symbol: UsageUnitSymbol.P },
  { value: EB, symbol: UsageUnitSymbol.E },
];

const numberSymbolLookup: { value: number; symbol: NumberUsageUnitSymbol }[] = [
  { value: simpleNumber, symbol: NumberUsageUnitSymbol.NONE },
  { value: K, symbol: NumberUsageUnitSymbol.K },
  { value: M, symbol: NumberUsageUnitSymbol.M },
  { value: B, symbol: NumberUsageUnitSymbol.B },
];

const myJFrogUtils = {
  sleep: async (seconds: number) => {
    await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  },
  byteToGigaByte: (num: number) => {
    const gigaBytes = +(num / Math.pow(1024, 3)).toFixed(2);
    return isFinite(gigaBytes) ? gigaBytes : 0;
  },
  buildNumYupField: (
    label: string,
    fieldErrorName: string,
    when?: string[] | string,
    is?: (...args: number[]) => boolean,
  ): yup.AnySchema => {
    const numYup = yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(0)
      .max(
        10 ** 14,
        `The ${fieldErrorName} field may not be greater than 15 characters`,
      )
      .transform((value) => (!value ? null : value))
      .label(label);

    if (when) {
      return numYup.when(when, {
        is,
        then: () =>
          yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(`Please fill one of the fields`),
      });
    }

    return numYup.required();
  },
  isDateValid: (date: Date) => {
    return Object.prototype.toString.call(date) === "[object Date]";
  },
  randomNum: (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min) + min);
  },
  uniqueName: (): string => Math.random().toString(36).slice(2),
  findSymbolLookupForUsageNumber: (num: number) => {
    return usageSymbolLookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
  },
  findSymbolLookupForSimpleNumber: (num: number) => {
    return numberSymbolLookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
  },
  shortenValueWithSymbol: (
    num: number,
    digits: number,
    config?: {
      hideSymbol?: boolean;
      addBytesToSymbolLabel?: boolean;
      addSpaceBeforeUnit?: boolean;
      forceSymbol?: UsageUnitSymbol | NumberUsageUnitSymbol;
      isSimpleNumbers?: boolean;
    },
  ) => {
    const valueNumber = config?.isSimpleNumbers
      ? myJFrogUtils.findSymbolLookupForSimpleNumber(num)
      : myJFrogUtils.findSymbolLookupForUsageNumber(num);

    const forcedSymbol = config?.isSimpleNumbers
      ? numberSymbolLookup.find((item) => item.symbol === config?.forceSymbol)
      : usageSymbolLookup.find((item) => item.symbol === config?.forceSymbol);

    const symbolLookupItem = config?.forceSymbol ? forcedSymbol : valueNumber;

    const symbol = config?.hideSymbol ? "" : symbolLookupItem?.symbol || "";

    const configurableSpaceBeforeUnit =
      (config?.addSpaceBeforeUnit && " ") || "";
    const configurableByteSymbol = (config?.addBytesToSymbolLabel && "B") || "";

    return symbolLookupItem
      ? parseFloat((num / symbolLookupItem.value).toFixed(digits)) +
          `${configurableSpaceBeforeUnit}${symbol}${configurableByteSymbol}`
      : `0${configurableSpaceBeforeUnit}${configurableByteSymbol}`;
  },
  formatNumberWithCommas(number: number, nbDecimals = 0) {
    return number.toLocaleString(navigator.language, {
      minimumFractionDigits: nbDecimals,
      maximumFractionDigits: nbDecimals,
    });
  },
  formatNumberToEnUs(number: number, nbDecimals: number) {
    if (Number.isInteger(number)) {
      return number.toLocaleString("en-US");
    }
    return number.toLocaleString("en-US", {
      minimumFractionDigits: nbDecimals,
      maximumFractionDigits: nbDecimals,
    });
  },

  exportCsvContent(data: string, fileName: string, dateFilters: DateFilters) {
    const csvContent = "data:text/csv;charset=utf-8," + data;
    const startDateStr = dateUtils.toSqlFormat(
      dateFilters.dateFrom ? dateFilters.dateFrom : new Date(),
    );
    const endDateStr = dateUtils.toSqlFormat(
      dateFilters.dateTo ? dateFilters.dateTo : new Date(),
    );
    const filename = `JFrog_${startDateStr}_${endDateStr}_${fileName}.csv`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  },
  calculatePercentage: (value: number, total: number): number => {
    if (total === 0) {
      return 0;
    }
    let percentage = parseInt(((value / total) * 100).toFixed(0));
    if (percentage === 100) {
      if (value < total) {
        percentage = 99;
      }
      if (value > total) {
        percentage = 101;
      }
    }
    return percentage;
  },
  calculateOverPercentage: (value: number, total: number) => {
    const percent = myJFrogUtils.calculatePercentage(value, total);
    const absolutePercentage = Math.abs(percent);
    if (absolutePercentage === 100 || percent === 0) {
      return 1;
    }
    return Math.abs(percent - 100).toLocaleString();
  },
};

export default myJFrogUtils;
