import { defineStore } from "pinia";
import { type UserDTO } from "@jfrog-ba/myjfrog-common";
import { heapService } from "@shared/services/heap";
import { appcuesService } from "@shared/services/appcues";
import { LogInfo } from "@shared/types/localTypes/auth.ts";

interface AuthStoreStates {
  user: UserDTO | null;
  _loginInfo: LogInfo | null;
}
export const useAuthStore = defineStore({
  id: "auth",
  state: () =>
    ({
      user: null,
      _loginInfo: null,
    }) as AuthStoreStates,
  getters: {
    getUser: (state) => state.user,
    loginInfo: (state) => state._loginInfo,
  },
  actions: {
    setUser(user: AuthStoreStates["user"]) {
      this.user = user;
      heapService.setUserIdentityProperties();
      appcuesService.setUserIdentityProperties();
      return;
    },
    async clear() {
      this.user = null;
      this._loginInfo = null;
    },
    setLoginInfo(loginInfo: LogInfo) {
      this._loginInfo = loginInfo;
    },
    setMfaActivated(mfaActivated: boolean) {
      if (this.user) {
        this.user.mfaActivated = mfaActivated;
      }
    },
  },
});
